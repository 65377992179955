import React, { useState, useRef, useEffect } from "react";
import { MdDeleteForever } from "react-icons/md";
import Spinner from "../../Spinner/Spinner";
import { authorizedPost } from "../../../Config/networkWithToken";
import configuration from "../../../Config/configuration";
import {
  config,
  DOBASEURL,
  updateValueInLocalStorage,
} from "../../../utils/helper";
import { logDOM } from "@testing-library/react";

const Photo = ({
  currentStep,
  steps,
  setCurrentStep,
  userDetails,
  userRole,
  setUserDetails,
}) => {
  const [images, setImages] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [isButtonLoad, setIsButtonLoad] = useState(false);
  const fileInputRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [previousData, setPreviousData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userRole === "admin") {
      if (userDetails) {
        console.log(userDetails);

        const photos = userDetails?.profile?.photos;
        console.log(photos);

        if (photos) {
          setImages(photos?.images || []);
          setIsChecked(photos?.terms || false);
          setPreviousData(photos.photos);
        }
      }
    } else {
      const UserData = localStorage.getItem("userData");
      if (UserData) {
        const parsedData = JSON.parse(UserData);
        const photos = parsedData?.profile?.photos;
        console.log(parsedData);
        console.log(photos);

        if (photos) {
          setImages(photos?.images || []);
          setIsChecked(photos?.terms || false);
          setPreviousData(photos.photos);
        }
      }
    }
  }, [userDetails]);

  // useEffect(() => {
  //   const UserData = localStorage.getItem("userData");
  //   if (UserData) {
  //     const parsedData = JSON.parse(UserData);
  //     const photos = parsedData?.profile?.photos;
  //     console.log(parsedData);
  //     console.log(photos);

  //     if (photos) {
  //       setImages(photos?.images || []);
  //       setIsChecked(photos?.terms || false);
  //       setPreviousData(photos.photos);
  //     }
  //   }
  // }, []);

  const validate = (images) => {
    const errors = {};
    if (images.length === 0) {
      errors.images = "Please upload at least one image.";
    }
    return errors;
  };

  const selectFiles = () => {
    fileInputRef.current.click();
  };

  const onFileSelect = async (event) => {
    console.log("working");
    const file = event.target.files[0];
    console.log(file);

    if (validateFile(file)) {
      // convertToBase64(file);
      uploadPicture(file);
    }
  };

  const onDrop = async (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (validateFile(file)) {
      // convertToBase64(file);
      uploadPicture(file);
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDragLeave = (event) => {
    event.preventDefault();
  };

  // Validate file type and size
  const validateFile = (file) => {
    const fileSizeLimit = 5 * 1024 * 1024;
    const validFileTypes = ["image/png", "image/jpg", "image/jpeg"];

    if (file?.size > fileSizeLimit) {
      setErrors({ images: "File size should not exceed 5MB." });
      return false;
    }

    if (!validFileTypes?.includes?.(file?.type)) {
      setErrors({ images: "Only PNG, JPG, and JPEG files are allowed." });
      return false;
    }

    setErrors({});
    return true;
  };

  // const convertToBase64 = (file) => {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const base64String = reader.result;
  //     setImages((prevImages) => [...prevImages, base64String]);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleDelete = (deleteIndex) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== deleteIndex));
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleNext = async (direction) => {
    if (direction === "next" && currentStep < steps.length) {
      const currentData = { images, terms: isChecked };

      if (JSON.stringify(previousData) !== JSON.stringify(currentData)) {
        setIsButtonLoad(true);

        const data = { photos: currentData };

        try {
          let res = [];
          if (userRole === "admin") {
            res = await authorizedPost(
              `${configuration.apis.saveProfile}?editUserId=${userDetails?._id}`,
              data,
              config,
            );
          } else {
            res = await authorizedPost(
              configuration.apis.saveProfile,
              data,
              config,
            );
          }
          if (res.data.status) {
            const updatedProfileData = res?.data?.data;
            const profileCompletionPercentage =
              res?.data?.profileCompletionPercentage;
            if (userRole === "admin") {
              setUserDetails({
                ...userDetails,

                profile: {
                  ...userDetails.profile,

                  photos: { ...updatedProfileData?.profile?.photos },
                },
              });
            } else {
              if (profileCompletionPercentage) {
                updateValueInLocalStorage(
                  "userData",
                  "profileCompletionPercentage",
                  profileCompletionPercentage,
                );
              }
              setPreviousData(currentData);
              updateValueInLocalStorage("userData", "photos", data.photos);

              if (updatedProfileData) {
                Object.keys(updatedProfileData).forEach((field) => {
                  updateValueInLocalStorage(
                    "userData",
                    field,
                    updatedProfileData[field],
                  );
                });
              }
            }
          } else {
            console.log("error", res?.data);
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsButtonLoad(false);
        }
      }

      setCurrentStep(currentStep + 1);
    } else if (direction === "prev" && currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate(images);

    if (Object.keys(newErrors).length === 0) {
      handleNext("next");
    } else {
      setErrors(newErrors);
    }
  };

  const uploadPicture = async (e) => {
    e.preventDefault();
    const file = e?.target?.files?.[0] || e?.dataTransfer?.files?.[0];

    if (validateFile(file)) {
      try {
        setLoading(true);
        if (file) {
          const formData = new FormData();
          formData.append("file", file);

          const res = await authorizedPost(
            `${configuration.apis.uploadFiles}?fileType=profile`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          );

          if (res?.data?.status) {
            setImages((prevImages) => [...prevImages, res?.data?.fileName]);
          } else {
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("image file error");
    }
  };

  return (
    <div className="font-helvetica flex min-h-screen w-full">
      <div className="mx-auto mb-14 mt-10 flex w-[95%] flex-col gap-1 rounded-lg bg-bgWhite pb-3 ">
        <form onSubmit={handleSubmit}>
          <div
            className="mx-auto mt-10 w-[95%] flex-col items-center border-2 border-dashed border-blue-400 p-3"
            role="button"
            onClick={selectFiles}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={(e) => uploadPicture(e)}
          >
            <p className="p-2 text-center text-3xl">
              Drop files here or click to upload.
            </p>
            <p className="p-2 text-center text-red-400">
              *(only vaisnava dress and individual photos only)
            </p>
            <p className="p-2 text-center text-xs">
              (File size should not exceed 5MB. Click Upload to upload the
              selected files.)
            </p>
            <input
              name="file"
              type="file"
              accept="image/*"
              className="hidden"
              multiple
              ref={fileInputRef}
              onChange={(e) => uploadPicture(e)}
            />
          </div>

          {errors.images && (
            <div className="mt-2 flex items-center justify-center">
              <p className="text-red-400">{errors.images}</p>
            </div>
          )}
          <div className="flex flex-col items-center justify-center md:flex-row">
            {loading ? (
              <p className="font-helvetica text-center text-[18px]">
                Loading.....
              </p>
            ) : (
              <>
                {images &&
                  images.map((image, index) => (
                    <div
                      key={index}
                      className="relative flex flex-col justify-evenly md:flex-row"
                    >
                      <img
                        alt=""
                        className="h-[250px] w-[300px] object-cover p-3"
                        src={DOBASEURL + image}
                        onContextMenu={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                      />
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleDelete(index);
                        }}
                        className="absolute right-0 top-0 m-3 text-red-500 hover:text-red-700"
                      >
                        <MdDeleteForever />
                      </button>
                    </div>
                  ))}
              </>
            )}
          </div>

          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <p style={{ marginLeft: "8px" }}>Photos visible to all matches</p>
            </div>
            <p style={{ marginLeft: "18px" }}>
              By default, Photos will be visible to the profile only after the
              candidate's consent.
            </p>
          </div>
          <div className="container mb-2 flex justify-around">
            <button
              onClick={() => handleNext("prev")}
              type="button"
              disabled={loading}
              className="cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white transition duration-200 ease-in-out "
            >
              Previous
            </button>
            <button
              type="submit"
              className="relative cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white transition duration-200 ease-in-out"
              disabled={isButtonLoad || loading}
            >
              {isButtonLoad ? <Spinner /> : "Next"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Photo;
