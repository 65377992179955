import React, { useState, useEffect } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { HiEye, HiEyeOff } from "react-icons/hi";
import Images from "../../utils/images";
import ForgetPassword from "../../Components/ForgetPassword/ForgetPassword";
import Strings from "../../utils/Strings";
import MessageBar, {
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import Spinner from "../../Components/Spinner/Spinner";

// API call
import { get, post } from "../../Config/network";
import configuration from "../../Config/configuration";
import { RiAdminFill } from "react-icons/ri";

const validate = (values) => {
  const errors = {};
  if (!values.primaryEmail) {
    errors.primaryEmail = "*Required";
  } else if (
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(values.primaryEmail)
  ) {
    errors.primaryEmail = "*Invalid Email";
  }
  if (!values.password) {
    errors.password = "*Required";
  } else if (values.password.length > 20) {
    errors.password = "*Password must be less than 20 characters";
  } else if (values.password.length < 6) {
    errors.password = "*Password must be at least 6 characters";
  }

  return errors;
};

const Login = () => {
  const [forgetPasswordPopup, setForgetPasswordPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [path, setPath] = useState("");
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
  const [isPasswordExpired, setIsPasswordExpired] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    console.log(window.location.pathname);
    setPath(window.location.pathname);
  }, [window.location.pathname]);

  // Submit the form with API call

  const handleSubmit = async (values) => {
    setIsProcessing(true);
    const data = {
      primaryEmail: formik.values.primaryEmail,
      password: formik.values.password,
    };

    const adminData = {
      email: formik.values.primaryEmail,
      password: formik.values.password,
    };

    try {
      let res, response;

      if (path === "/admin") {
        res = await post(configuration.apis.adminLogin, adminData, config);
      } else {
        res = await post(configuration.apis.login, data, config);
      }

      response = res?.data;

      if (response?.status) {
        if (response?.isPassword === false) {
          // If password is incorrect or reset open forget password modal
          setForgetPasswordPopup(true);
          setIsPasswordExpired(true);
          return;
        }

        const userData = path === "/admin" ? response.data : response.data[0];
        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("count", response.count);
        successNotify(response?.message);

        if (path === "/admin") {
          setTimeout(() => navigate("/matrimony"), 1000);
        } else {
          const isProfileCompleted = userData?.profileCompleted;
          if (isProfileCompleted) {
            setTimeout(() => navigate(`/profile/${userData?._id}`), 1000);
          } else {
            setTimeout(() => navigate("/editprofile"), 1000);
          }
        }
      } else {
        errorNotify(response?.message || "Invalid login credentials.");
      }
    } catch (err) {
      // Handle network or unexpected errors

      let errorMessage =
        "An unexpected error occurred. Please try again later.";
      if (err.response) {
        errorMessage =
          err.response.data.message || "Server error. Please try again.";
      } else if (err.request) {
        errorMessage = "Network error. Please check your internet connection.";
      }

      errorNotify(errorMessage);
    } finally {
      setIsProcessing(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      primaryEmail: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      if (!isProcessing) {
        handleSubmit(values);
      }
    },
  });

  const handleForgetPassword = async () => {
    setForgetPasswordPopup(true);
  };

  return (
    <div
      className={`font-jost login-right-container flex min-h-screen w-full items-center justify-center pt-16 `}
    >
      <div className="mx-auto mb-5 flex w-[95%] flex-col items-center  justify-center shadow-md lg:h-[400px] lg:w-[70%] lg:flex-row">
        <div className="login-left-container relative h-[250px] w-full rounded-tl-lg  rounded-tr-lg bg-loginbg lg:h-[500px] lg:w-[35%] lg:rounded-bl-lg lg:rounded-tl-lg lg:p-6">
          <div className="playFair flex flex-col space-y-2 p-4">
            <h3 className="text-center  text-3xl  text-textGreen lg:text-left lg:text-2xl">
              {Strings.NOW}
            </h3>
            <h2 className="text-center  text-4xl  text-textGreen lg:text-left lg:text-6xl">
              {Strings.LOGIN_TEXT}
            </h2>
            <h3 className="text-center text-2xl  text-textGreen lg:text-left lg:text-2xl">
              {Strings.EASY_FAST}
            </h3>
          </div>
          <div className=" hidden items-center justify-center lg:flex">
            <img
              src={Images.MARRIAGEHEART}
              alt=""
              className="left-0  h-[170px] w-[170px] object-contain"
            />
          </div>

          <div className="imgBg absolute bottom-0 left-0 right-0"></div>
        </div>

        <div className=" flex h-auto w-full flex-col rounded-bl-lg rounded-br-lg  bg-bgWhite lg:h-[500px] lg:w-[65%] lg:rounded-br-lg lg:rounded-tr-lg lg:px-12">
          <div className="mt-6 flex flex-col space-y-4 p-4">
            <h3 className="playFair text-left text-2xl  text-textBlack">
              {Strings.LOGIN_CONTEN}
            </h3>
            <p className=" pb-6 text-left text-xs font-medium tracking-wider  text-textGreen">
              {Strings.Do_Want}
              <span className=" text-xs font-medium text-sky-500 hover:underline">
                <Link to="/register"> {Strings.SIGN_UP}</Link>
              </span>
            </p>
            <hr />
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="flex w-full flex-col gap-4 px-2 lg:mt-8"
          >
            <div className="flex flex-col gap-1">
              <label
                htmlFor="email"
                className="font-helvetica text-sm  text-textBlack"
              >
                {Strings.EMAIL}
              </label>
              <input
                type="email"
                placeholder="Enter your Email"
                className="text-md rounded-lg border border-gray-200  p-2.5 text-textBlack"
                name="primaryEmail"
                autoComplete="off"
                onChange={formik.handleChange}
                value={formik.values.primaryEmail}
                onBlur={formik.handleBlur}
              />
              {formik.errors.primaryEmail ? (
                <span className="text-xs text-textRed">
                  {formik.errors.primaryEmail}
                </span>
              ) : null}
            </div>

            <div className="flex flex-col gap-1">
              <label
                htmlFor="password"
                className="font-helvetica text-sm  text-textBlack"
              >
                {Strings.PASSWORD}
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  autoComplete="off"
                  placeholder="Enter your password"
                  name="password"
                  className="text-md w-full rounded-lg border border-gray-200 p-2.5 pr-10 text-textBlack focus:outline-none"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                />
                <div
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-textBlack"
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <HiEyeOff className="text-textBlack" />
                  ) : (
                    <HiEye className="text-textBlack" />
                  )}
                </div>
              </div>
              {formik.errors.password ? (
                <span className="text-xs text-textRed">
                  {formik.errors.password}
                </span>
              ) : null}
            </div>

            <div className="flex justify-between">
              <div className="flex flex-row gap-3 p-2">
                <div className="flex items-center gap-2 text-xs text-sky-500 hover:underline">
                  <RiAdminFill />
                  {path === "/admin" ? (
                    <Link to={"/login"}>User Login</Link>
                  ) : (
                    <Link to={"/admin"}>Admin Login</Link>
                  )}
                </div>
              </div>
              <div>
                <p
                  htmlFor="text"
                  className="cursor-pointer text-sm text-red-500 hover:to-blue-600 hover:underline"
                  onClick={handleForgetPassword}
                >
                  {Strings.FORGET_PASSWORD}
                </p>
              </div>
            </div>

            <button
              type="submit"
              className="font-helvetica mx-auto mt-2 flex w-full cursor-pointer items-center justify-center rounded-lg bg-textPink p-2 text-sm text-bgWhite "
              disabled={isProcessing}
            >
              {isProcessing
                ? isProcessing && (
                    <div className="flex items-center justify-center">
                      <Spinner />
                    </div>
                  )
                : "Login"}
            </button>
          </form>
        </div>
        {forgetPasswordPopup && (
          <ForgetPassword
            setForgetPasswordPopup={setForgetPasswordPopup}
            primaryEmail={formik.values.primaryEmail}
            isPasswordExpired={isPasswordExpired}
          />
        )}
        <MessageBar />
      </div>
    </div>
  );
};

export default Login;
