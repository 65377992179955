import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { FaLocationDot } from "react-icons/fa6";
import Loader from "../../Components/Loader/Loader";
import Footer from "../../Components/Footer/Footer";
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";


const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "*Required";
  }
  if (!values.email) {
    errors.email = "*Required";
  } else if (!/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(values.email)) {
    errors.email = "*Invalide Email";
  }
  if (!values.phone) {
    errors.phone = "*Required";
  } else if (!/^\d{10}$/i.test(values.phone)) {
    errors.phone = "*Invalid phone number (10 digits required)";
  }
  if (!values.message) {
    errors.message = "*Required";
  }
  return errors;
};

const Contact = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
    return () => clearTimeout(timer);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      console.log(formik.values);
    },
  });

  return  (
 
<div className="flex flex-col min-h-screen bg-gray-50">
  <section id="contact" className="flex-grow mb-10">
    <div className="font-jost text-3xl pt-20">
      <p className="text-center text-xl sm:text-2xl lg:text-3xl ">Contact Us</p>
      <div className="mx-auto w-16 border-b-2 border-yellow-400 text-center mt-2"></div>
    </div>
<div className="py-6">
<div className="mx-auto w-full sm:w-[90%] md:w-[80%] lg:w-[70%] flex flex-col lg:flex-row gap-10 items-center justify-center font-jost py-8 bg-white rounded-2xl shadow-md ">
      {/* Left Side: Address Details */}
      <div className="flex flex-col gap-5 w-full lg:w-1/2 p-6 border-b border-gray-300 lg:border-r lg:border-b-0">
        <div className="flex gap-3 py-2">
          <FaLocationDot className="text-yellow-400" size={"35"} />
          <div>
            <p className="text-xl font-jost">Address</p>
            <p className="text-lg">
              Office of His Holiness Jayapataka Swami
              <br />
              ISKCON Sri Mayapur, Room No : 106, Chakra Building,
              <br /> Sridham Mayapur - 741313.
              <br /> Nadia District, West Bengal.
            </p>
          </div>
        </div>
        <div className="flex gap-3 py-2">
          <IoIosMail className="text-yellow-400" size={"35"} />
          <div>
            <p className="text-xl font-jost">General Enquiries</p>
            <p className="text-lg">vaisnavamarriage@gmail.com</p>
          </div>
        </div>
        <div className="flex gap-3 py-2">
          <FaPhoneAlt className="text-yellow-400" size={"30"} />
          <div>
            <p className="text-xl font-jost">Call Us</p>
            <p className="text-lg">
              +91 9647089832
              <br />
              +91 7477782975
              <br />
              +91 8653000991
            </p>
          </div>
        </div>
      </div>

      {/* Right Side: Contact Form */}
      <div className="w-full lg:w-1/2 p-6">
        <form className="flex flex-col gap-4">
          <p className="rounded-md bg-yellow-400 px-4 py-2  text-xl font-jost">Contact Form</p>
          <input
            type="email"
            className="contact-input w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter your Email"
          />
          <input
            type="text"
            className="contact-input w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter your Name"
          />
          {/* <input
            type="text"
            className="contact-input w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter your Contact Number"
          /> */}
          <PhoneInput
            country={"in"}
            inputClass="w-full md:w-[100%]"
          />
          <textarea
            className="contact-textArea h-[70px] w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter your Message"
          ></textarea>
          <button
            type="button"
            className="w-[150px] rounded-md bg-yellow-400 px-4 py-2 text-[15px] font-medium uppercase hover:bg-yellow-300"
          >
            Send
          </button>
        </form>
      </div>
    </div>
</div>

  </section>
  <Footer className="mt-auto" />
</div>


  
  );
  
};

export default Contact;
