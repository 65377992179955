import React, { useEffect, useState } from "react";
import Step from "../../Components/Stepper/Step";
import BasicInformation from "../../Components/Stepper/Steps/BasicInformation";
import Profession from "../../Components/Stepper/Steps/Profession";
import Religion from "../../Components/Stepper/Steps/Religion";
import Photo from "../../Components/Stepper/Steps/Photo";
import Expectation from "../../Components/Stepper/Steps/Expectation";
import Complete from "../../Components/Stepper/Steps/Complete";
import UserQuestionnaire from "../../Components/Stepper/Steps/QuestionnariePage";
import { useLocation } from "react-router-dom";
import { config, errorNotify } from "../../utils/helper";
import configuration from "../../Config/configuration";
import { authorizedGet } from "../../Config/networkWithToken";

const EditProfile = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [isClicked, setIsClicked] = useState();

  const steps = [
    "Basic information",
    "Profession",
    "Religion & Family",
    "Photo",
    "Expectations",
    "Questionnaire",
    "Complete",
  ];

  const percentageMapping = [
    { percentage: 0, step: 1 },
    { percentage: 12.5, step: 2 },
    { percentage: 25.0, step: 3 },
    { percentage: 62.5, step: 4 },
    { percentage: 75.0, step: 5 },
    { percentage: 87.5, step: 6 },
    { percentage: 100.0, step: 1 },
  ];

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const profileCompletionPercentage =
      userData?.profileCompletionPercentage || 0;
    const step =
      percentageMapping.find(
        (item) => profileCompletionPercentage <= item.percentage,
      )?.step || 1;
    const role = userData?.role;
    console.log(role);
    if (role) {
      setUserRole(role);
    }

    setCurrentStep(step);
    if (role === "admin") {
      fetchSingleUser();
    }
  }, [location.state]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const fetchSingleUser = async () => {
    try {
      setLoading(true);
      const res = await authorizedGet(
        `${configuration.apis.singleUser}?userId=${location?.state?.userId}`,
        config,
      );
      const response = res?.data?.data;
      if (response) {
        setUserDetails(response);
      }
    } catch (err) {
      // Handle network or unexpected errors
      let errorMessage =
        "An unexpected error occurred. Please try again later.";

      if (err.response) {
        errorMessage =
          err.response.data.message || "Server error. Please try again.";
      } else if (err.request) {
        errorMessage = "Network error ";
      }

      errorNotify(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="font-jost mt-4 min-h-screen w-full flex-col items-center justify-center bg-bgColor pb-2 pt-20">
        <div className="mx-auto mb-14 flex w-[95%] flex-col gap-1 rounded-lg bg-bgWhite pb-3 lg:w-[98%]">
          <div className="container">
            <Step steps={steps} currentStep={currentStep} />
          </div>
          <div>
            {currentStep === 1 && (
              <BasicInformation
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                userDetails={userDetails}
                userRole={userRole}
                setUserDetails={setUserDetails}
              />
            )}
            {currentStep === 2 && (
              <Profession
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                userDetails={userDetails}
                userRole={userRole}
                setUserDetails={setUserDetails}
              />
            )}
            {currentStep === 3 && (
              <Religion
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                userDetails={userDetails}
                userRole={userRole}
                setUserDetails={setUserDetails}
              />
            )}
            {currentStep === 4 && (
              <Photo
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                userDetails={userDetails}
                userRole={userRole}
                setUserDetails={setUserDetails}
              />
            )}
            {currentStep === 5 && (
              <Expectation
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                userDetails={userDetails}
                userRole={userRole}
                setUserDetails={setUserDetails}
              />
            )}
            {currentStep === 6 && (
              <UserQuestionnaire
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                userDetails={userDetails}
                userRole={userRole}
                setUserDetails={setUserDetails}
              />
            )}
            {currentStep === 7 && (
              <Complete
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                steps={steps}
                userDetails={userDetails}
                userRole={userRole}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
