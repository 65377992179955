import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { authorizedGet, authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import MessageBar, {
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
} from "../../utils/helper";
import Spinner from "../Spinner/Spinner";

const Notification = ({ setShowNotification, setNotificationCount }) => {
  const [notifications, setNotification] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = async () => {
    try {
      setLoading(true);
      const role = getValueFromLocalStorage(localStorageKey.USERDATA, "role");
      console.log(role)
      let userId = null;
      let adminId = null;

      if (role === "admin") {
        adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
      } else {
        userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
      }

      const queryParam = userId ? `userId=${userId}` : `adminId=${adminId}`;

      const res = await authorizedGet(
        `${configuration.apis.getNotification}?${queryParam}`,
        config,
      );

      setLoading(false);
      const response = res.data;
      if (response?.status) {
        setNotification(response?.data);
        setNotificationCount(response?.data?.length);
      }
    } catch (err) {
      console.log(err);
      errorNotify("Unexpected Error");
      setLoading(false);
    }
  };

  const markasread = async () => {
    try {
      setLoading(true);
      const role = getValueFromLocalStorage(localStorageKey.USERDATA, "role");
      let userId;
      if (role === "admin") {
        userId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
      } else {
        userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
      }

      const res = await authorizedPost(
        `${configuration.apis.readNotification}?userId=${userId}`,
        config,
      );
      setLoading(false);
      const response = res.data;
      console.log(response);
      if (response?.status) {
        setNotification(null);
        setNotificationCount(0);
      }
    } catch (err) {
      console.log(err);
      errorNotify("Unexpected Error");
      setLoading(false);
    }
  };

  return (
    <div className="popup-container fixed left-0 top-0 z-20 flex w-full justify-end p-4 font-jost ">
      <div className="popup-content relative top-10 w-full max-w-[340px] bg-white  rounded-lg shadow-lg">
        <div className="flex items-center justify-between p-2 bg-textYellow rounded-t-md">
          <h2 className=" text-lg font-semibold  text-gray-700">Notification</h2>
          <button
            className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border bg-white/[0.5]"
            onClick={() => setShowNotification(false)}
          >
            <IoClose />
          </button>
        </div>

        {loading ? (
          <div className="flex h-[170px] w-full items-center justify-center text-sm">
            <Spinner />
          </div>
        ) : (
          <>
            {notifications && notifications.length > 0 ? (
              <div className="flex flex-col max-h-[170px] overflow-y-auto divide-y">
                <p
                  onClick={markasread}
                  className="cursor-pointer p-2 text-xs text-gray-500 underline hover:text-gray-700"
                >
                  read all
                </p>
                <div className="landingPage max-h-[170px] divide-y overflow-y-auto rounded-b-md bg-white text-gray-500">
                  {notifications.map((notification, index) => (
                    <div
                      key={index}
                      className="notification flex cursor-pointer items-center justify-between p-2 hover:bg-yellow-100 hover:text-gray-700"
                    >
                      <div className="pb-1.5">
                        <p className="text-xs">{notification.message}</p>
                        <p className="text-xs">
                          {notification.createdAt.slice(0, 10)}
                        </p>
                      </div>
                      {/* <button className="hover:text-blue-500 hover:underline">
                      view
                    </button> */}
                    </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="flex h-[170px] w-full items-center justify-center text-sm">
                No Notification Found
              </div>
            )}
          </>
        )}
      </div>
      <MessageBar />
    </div>
  );
};

export default Notification;
