import React from 'react'
import { useNavigate } from 'react-router-dom'

function SessionExpire() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/login');
    }
  return (
      <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white relative w-[80%] md:w-[50%] xl:w-[30%] p-8 items-center justify-center rounded-lg shadow-lg">
                  <div className="flex flex-row gap-1 items-center justify-center ">
                    <h2 className="text-sm md:text-lg font-semibold mb-4">Session Expired Please Log In again...!</h2>
                  </div>
                  <div className="flex items-center justify-center gap-2 mt-4">
                    <button className="bg-blue-500 text-white px-1 py-0.5 md:px-4 md:py-2 rounded" onClick={handleClick}>Log In</button> 
                  </div>
                </div>
    </div>
  )
}

export default SessionExpire
