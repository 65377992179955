import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa';

function AdvanceSearch({ onSearchValue }) {

    const [SearchValue,setSearchValue] = useState('');
    const [error,setError] = useState(false);

    const handleAdvanceSearch = () => {
        if (SearchValue.length >= 3) {
            console.log(SearchValue);
            onSearchValue(SearchValue);
            setError(false);
        }else{
          setError(true);
        }
    }

    const handleSearch = (value) => {
        if (value.length >= 3) {
            console.log(value);
            onSearchValue(value);
            setError(false);
        }else{
          setError(true);
        }
    }

  return (
    <div className='flex flex-col'>
    <div className="flex flex-row w-full md:w-[180px] h-[35px] xl:w-[300px] gap-1">
                <input type="text" name="search" id="search"
                 className={`w-full md:w-[150px] xl:w-[300px] p-2 rounded-md border-2 transition 
                 focus:outline-none focus:ring-2 focus:ring-textYellow`} 
                 onChange={ (e) => setSearchValue(e.target.value) }
                 onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(e.target.value); // Call the function when Enter is pressed
                    }
                  }}/>
            <div className="flex p-2 items-center h-[35px] bg-gray-500 rounded-md cursor-pointer"  onClick={handleAdvanceSearch} >
                <FaSearch className="text-bgWhite"/>
            </div>
    </div>
    { error && (
    <p className='text-xs text-red-600'>minimum 3 letters required</p>
    )}
    </div>
  )
}

export default AdvanceSearch
