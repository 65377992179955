import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../Components/Spinner/Spinner";
import { MdDeleteSweep } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { authorizedDel, authorizedGet, authorizedPost, authorizedPut } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import MessageBar, { config, errorNotify, getValueFromLocalStorage, localStorageKey, successNotify } from "../../utils/helper";

const Notes = () => {

  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [updateLoading,setUpdateLoading] = useState(false)
  const [notes,setNotes] = useState([]);
  const [isModalVisible,setIsModalVisible] = useState(false);
  const [saveNote, setSaveNote] = useState('');
  const [updateIndex,setUpdateIndex] = useState(0); 
  const [isUpdateButtonVisible, setIsUpdateButtonVisible] = useState(false);
  const  adminId  = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId"); 

    useEffect(() => {
        setLoading(true);
        fetchGetNotes();
    },[]);

  //Function for get note API call
    const fetchGetNotes = async () => {
        try {
            const res = await authorizedGet(`${configuration.apis.getNotes}?userId=${userId}&adminId=${adminId}`,config);
            const response = res?.data?.data;
            if(response){
              setNotes(response);
            }
        } catch (error) {
            console.log("Error fetching get Notes :"+error);
        }
        setLoading(false);
    };
    
    //Function for add note API call 
    const handleAddNotes = async () =>{
        try{
         const data = {   
            userId: userId,
            adminId: adminId,
            notes: saveNote,
         }
         if(saveNote !== ''){
         setUpdateLoading(true);
         const res = await authorizedPost(configuration.apis.addNotes, data, config);
         const response = res?.data;
         if (res?.status) {
            successNotify(response?.message);
          } else {
            errorNotify(response?.message);
          }
          if (response.data) {
             setNotes(response.data);
          }          
        }else{
            errorNotify("Please Add some Values");
        }
        }catch(error){
            console.log();
        }
        setUpdateLoading(false);
        closeModal();
    }

    //Function for update API call
    const handleUpdateNotes = async () => {
        try {
          setUpdateLoading(true);
              const data={
                  noteId: notes[updateIndex]._id,
                  notes: saveNote
              }
              const res = await authorizedPut(configuration.apis.updateNote, data, config);
              const response = res?.data;
              if(res?.status){
                successNotify(response?.message);
                const updateNote =[...notes];
                updateNote[updateIndex].notes = response?.data?.notes;
                setNotes(updateNote);
              }else{
                errorNotify(response.message);
              }
        } catch (error) {
            console.log(error);
        }
        setUpdateLoading(false);
        setIsModalVisible(false);
        setIsUpdateButtonVisible(false);
        setSaveNote('');
    }

    //Function to open the modal
    const handleSubmit = () => {
        setIsModalVisible(true);
    }

    //Function to update the note data
    const handleNoteChange = (event) => {
        setSaveNote(event.target.value);
      };
    

    //Function to remove or delete the note
    const removeNote = async (index) => {
         try {
            setIsModalVisible(true);
            setUpdateLoading(true);
            const noteId = notes[index]._id;
            const res = await authorizedDel(`${configuration.apis.deleteNote}?noteId=${noteId}`,config);
            console.log(res);
            const response= res?.data;
            console.log(response);
            if (response?.status) {
              successNotify(response?.message);
              const updateNote = notes.filter((_, i) => i !== index);
              setNotes(updateNote);
            }else{
              errorNotify(response?.message);
            }       
         } catch (error) {
            console.log(error);
         }
         setIsModalVisible(false);
         setUpdateLoading(false);
      };

    //Function to update the note
     const updatedNote = (index) => {
        setIsModalVisible(true);
        setSaveNote(notes[index].notes);
        setIsUpdateButtonVisible(true);
        setUpdateIndex(index);
     };
      
    //Function to close the modal
    const closeModal = () => {
        setIsModalVisible(false); 
        setIsUpdateButtonVisible(false);
        setSaveNote('');
      };

return (
    <>
    <div>
        {loading ? (
        <div className="loader flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <div className=" flex flex-row w-full mx-auto bg-bgWhite justify-center p-5"> 
            <div className="flex flex-col w-[85%] ">
                <h2 className="text-2xl mb-6 text-gray-800">Notes</h2>
                 <div className="bg-bgWhite border-blue w-full p-4 h-auto rounded-lg shadow-sm mb-5">
                { notes.length !== 0 ? ( 
                    <ul className="flex flex-col w-full gap-5 h-full">
                        {notes.map((list,index) => (
                            <li key={index} className="text-black flex flex-row justify-between font-medium w-full h-full">
                              {list.notes}
                              <div className="flex flex-row gap-3">
                              <p className="text-xs text-gray-400"> created by {notes[index]?.admin?.name}</p>
                              <div className="update icon cursor-pointer text-sky-300 " onClick={ () => updatedNote(index)}><FaEdit />                              </div>
                              <div className="delete icon cursor-pointer text-textRed " onClick={ () => removeNote(index)}><MdDeleteSweep /> </div>
                              </div>
                            </li>
                            
                        ))}
                       
                    </ul>
                ) : ( <span> No details available </span>) }
                </div>
            </div>
        <div className="flex flex-col w-[15%] items-center rounded-sm ">
            <button className="font-medium text-xs md:text-lg h-10 w-20 md:w-28 bg-textBlue text-bgWhite rounded-lg" onClick={handleSubmit}>Add Notes</button>
        </div>
     </div>
      )}
    </div>
    { isModalVisible && ( 
       <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
       <div className="bg-white relative w-[50%] p-8 items-center justify-center rounded-lg shadow-lg">
       { updateLoading ? (<div className="update_loader flex bg-bgWhite p-5 items-center justify-center">
         <h1 className=" text-xl text-textGreen text-center fond-semibold">Loading....</h1> </div>) : 
        (  <div className="flex flex-col justify-center">
         <h2 className="text-lg font-semibold mb-4">Add a Note</h2>
            <textarea 
              className="w-full p-2 border border-gray-300 rounded mb-4"
              rows="4"
              value={saveNote}
              onChange={handleNoteChange}
              placeholder="Enter your note here..."
            />
            <div className="flex justify-end gap-2">
              <button className="bg-gray-300 text-black px-1 py-0.5 md:px-4 md:py-2 rounded" onClick={closeModal}>Close</button>
              { isUpdateButtonVisible  ? (
                <button className="bg-blue-500 text-white px-1 py-0.5 md:px-4 md:py-2 rounded" onClick={handleUpdateNotes}>Update</button>
              ) : (
                <button className="bg-blue-500 text-white px-1 py-0.5 md:px-4 md:py-2 rounded" onClick={handleAddNotes}>Save</button>
              )}
            </div>
            </div>
              )}
         </div>
        
       </div>
    )} 
     <MessageBar />
    </>
)
}

export default Notes;
