import React from "react";
import { CiEdit } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";

const NewsLetter = () => {
  const tableData = [
    {
      List: "Messages",
      Add: "",
      Edit: "",
    },
    {
      List: "New Letters",
      Add: "",
      Edit: "",
    },
    {
      List: "Submissions",
      Add: "",
      Edit: "",
    },
    {
      List: "Education Categories",
      Add: "",
      Edit: "",
    },
    {
      List: "Subcriptions",
      Add: "",
      Edit: "",
    },
  ];
  return (
    <div className="mx-auto w-[95%] ">
      <table className="w-full table-auto">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">List of News letter</th>
            <th className="px-4 py-2">ADD</th>
            <th className="px-4 py-2">CHANGE</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{data.List}</td>
              <td className="border px-4 py-2">
                <div className="flex items-center justify-center gap-2  ">
                  <span className="text-lg">
                    <IoMdAdd />
                  </span>{" "}
                  <p>ADD</p>
                </div>
              </td>
              <td className="border px-4 py-2">
                <div className="flex items-center justify-center gap-2  ">
                  <span className="text-lg">
                    <CiEdit />
                  </span>{" "}
                  <p>EDIT</p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewsLetter;
