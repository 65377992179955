import React, { useState, useEffect } from "react";
import loadingImg from "../../Assets/loadingImg.gif";

const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Hide the loader after 1 second
    return () => clearTimeout(timer);
  }, []);

  // Render the loader only if loading state is true
  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-bgColor">
        <img
          src={loadingImg}
          className="mt-24 h-[200px] w-[200px]"
          alt="Loading"
        />
      </div>
    );
  } else {
    return null; // Return null if loading is false
  }
};

export default Loader;
