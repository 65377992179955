import React, { useEffect, useState, useRef } from "react";

import InputField from "../../InputField/InputField";
import SelectField from "../../SelectField/SelectField";
import Strings from "../../../utils/Strings";
import Spinner from "../../Spinner/Spinner";
import PhoneInput from "react-phone-input-2";
import {
  birthTimeUnknownOption,
  casteOption,
  countryOptions,
  familyLocationOption,
  familyStatusOption,
  familyTypeOption,
  familyValuesOption,
  fatherStatusOption,
  motherStatusOption,
  parentsDevoteesOption,
  religionOption,
  subcasteOption,
  manglikOptions,
  parentsStatusOption,
  siblingsDevoteesOption,
  connectedTempleOptions,
} from "../../../utils/data";
import { authorizedPost } from "../../../Config/networkWithToken";
import configuration from "../../../Config/configuration";
import {
  config,
  updateValueInLocalStorage,
  getValueFromLocalStorage,
  localStorageKey,
} from "../../../utils/helper";

const Religion = ({
  currentStep,
  steps,
  setCurrentStep,
  userDetails,
  userRole,
  setUserDetails,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const prevFormData = useRef(null);
  const [filteredSubcasteOptions, setFilteredSubcasteOptions] = useState([]);
  const [isMandartory, setIsMandartory] = useState(false);

  useEffect(() => {
    if (userRole === "admin") {
      if (userDetails) {
        fetchReligionAndFamily(userDetails);
      }
    } else {
      const userData = JSON.parse(localStorage.getItem("userData"));
      fetchReligionAndFamily(userData);
    }
  }, [userDetails]);
  // useEffect(() => {
  //   fetchReligionAndFamily();
  //   getValueFromLocalStorage(localStorageKey.USERDATA, "religion & family");
  // }, []);

  const [formData, setFormData] = useState({
    religion: "",
    caste: "",
    otherCaste: "",
    subcaste: "",
    otherSubcaste: "",
    dob: "",
    birthTimeUnknown: "",
    birthTime: "",
    // birthPlace: "",
    birthCity: "",
    birthState: "",
    birthCountry: "",
    religiousBackground: "",
    parentsDevotees: "",
    familyValues: "",
    familyType: "",
    familyStatus: "",
    fatherName: "",
    fatherStatus: "",
    fatherPhoneNumber: "",
    motherName: "",
    motherStatus: "",
    motherPhoneNumber: "",
    noOfBrothers: "",
    marriedBrothers: "",
    noOfSisters: "",
    marriedSisters: "",
    familyLocation: "",
    familyOrigin: "",
    familyDetails: "",
    mentorName: "",
    mentorsEmail: "",
    mentorsPhoneNumber: "",
    manglik: "",
    parentsStatus: "",
    siblingsDevotees: "",
    siblingsDetails: "",
    connectedTemple: "",
    mentorPosition: "",
  });
  useEffect(() => {
    if (formData.caste) {
      setFilteredSubcasteOptions(subcasteOption[formData.caste] || []);
    } else {
      setFilteredSubcasteOptions([]);
    }
  }, [formData.caste]);

  const fetchReligionAndFamily = (data) => {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    const userData = data;
    if (userData?.profile?.mentor?.mentorName?.[0] !== "") {
      setIsMandartory(true);
    }
    if (userData) {
      const fetchedData = {
        religion: userData?.profile?.religionAndFamily?.religion || "",
        caste: userData?.profile?.religionAndFamily?.caste || "",
        otherCaste: userData?.profile?.religionAndFamily?.otherCaste || "",
        subcaste: userData?.profile?.religionAndFamily?.subcaste || "",
        otherSubcaste:
          userData?.profile?.religionAndFamily?.otherSubcaste || "",
        dob: new Date(userData?.dob).toLocaleDateString("en-CA") || "",
        birthTimeUnknown:
          userData?.profile?.religionAndFamily?.birthTimeUnknown || "",
        birthTime: userData?.profile?.religionAndFamily?.birthTime || "",
        // birthPlace: userData?.profile?.religionAndFamily?.birthPlace || "",
        birthCity: userData?.profile?.religionAndFamily?.birthCity || "",
        birthState: userData?.profile?.religionAndFamily?.birthState || "",
        birthCountry: userData?.profile?.religionAndFamily?.birthCountry || "",
        manglik: userData?.profile?.religionAndFamily?.manglik,
        religiousBackground:
          userData?.profile?.religionAndFamily?.religiousBackground || "",
        parentsStatus:
          userData?.profile?.religionAndFamily?.parentsStatus || "",
        siblingsDevotees:
          userData?.profile?.religionAndFamily?.siblingsDevotees || "",
        siblingsDetails:
          userData?.profile?.religionAndFamily?.siblingsDetails || "",
        parentsDevotees: userData?.profile?.family?.parentsDevotees || "",
        familyValues: userData?.profile?.family?.familyValues || "",
        familyType: userData?.profile?.family?.familyType || "",
        familyStatus: userData?.profile?.family?.familyStatus || "",
        fatherName: userData?.profile?.family?.fatherName || "",
        fatherStatus: userData?.profile?.family?.fatherStatus || "",
        fatherPhoneNumber: userData?.profile?.family?.fatherPhoneNumber || "",
        motherName: userData?.profile?.family?.motherName || "",
        motherStatus: userData?.profile?.family?.motherStatus || "",
        motherPhoneNumber: userData?.profile?.family?.motherPhoneNumber || "",
        noOfBrothers: userData?.profile?.family?.noOfBrothers ?? "",
        marriedBrothers: userData?.profile?.family?.marriedBrothers ?? "",
        noOfSisters: userData?.profile?.family?.noOfSisters ?? "",
        marriedSisters: userData?.profile?.family?.marriedSisters ?? "",
        familyLocation: userData?.profile?.family?.familyLocation || "",
        familyOrigin: userData?.profile?.family?.familyOrigin || "",
        familyDetails: userData?.profile?.family?.familyDetails || "",
        mentorName: userData?.profile?.mentor?.mentorName || "",
        mentorsEmail: userData?.profile?.mentor?.mentorsEmail || "",
        mentorsPhoneNumber:
          userData?.profile?.mentor?.mentorsPhoneNumber?.[0] || "",
        connectedTemple: userData?.profile?.mentor?.connectedTemple || "",
        mentorPosition: userData?.profile?.mentor?.mentorPosition || "",
      };

      setFormData(fetchedData);
      prevFormData.current = fetchedData;
    }
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target ? e.target : e;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
      ...(name === "parentsDevotees" && value !== "No"
        ? { parentsStatus: "" }
        : {}),
      ...(name === "siblingsDevotees" && value !== "Yes"
        ? { siblingsDetails: "" }
        : {}),
      [name]: type === "checkbox" ? checked : value,
    }));

    // if (type === "checkbox") {
    //   setIsMandartory(!isMandartory);
    // }
  };

  const validate = (values) => {
    const errors = {};
    let requiredFields = [];
    if (isMandartory) {
      console.log("true");

      requiredFields = [
        "religion",
        "caste",
        "birthTimeUnknown",
        "birthTime",
        "parentsDevotees",
        "familyType",
        "familyStatus",
        "fatherName",
        "fatherStatus",
        "motherStatus",
        "noOfBrothers",
        "marriedBrothers",
        "noOfSisters",
        "marriedSisters",
        "familyLocation",
        "mentorName",
        "mentorsEmail",
        "mentorsPhoneNumber",
        "birthCity",
        "mentorPosition",
        "connectedTemple",
        "manglik",
      ];
    } else {
      requiredFields = [
        "religion",
        "caste",
        "birthTimeUnknown",
        "birthTime",
        "parentsDevotees",
        "familyType",
        "familyStatus",
        "fatherName",
        "fatherStatus",
        "motherStatus",
        "noOfBrothers",
        "marriedBrothers",
        "noOfSisters",
        "marriedSisters",
        "familyLocation",
        "birthCity",
        "manglik",
      ];
    }

    requiredFields.forEach((field) => {
      console.log(values[field]);

      if (Array.isArray(values[field]) && values[field]?.[0] === "") {
        console.error(`Validation failed for: ${field}`);
        errors[field] = "Required";
      } else if (
        values[field] === undefined ||
        values[field] === null ||
        (typeof values[field] === "string" && values[field].trim() === "") ||
        (Array.isArray(values[field]) && values[field].length === 0)
      ) {
        console.error(`Validation failed for: ${field}`);
        errors[field] = "Required";
      }
    });

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate(formData);
    if (Object.keys(newErrors).length === 0) {
      setIsButtonLoading(true);
      if (JSON.stringify(prevFormData.current) === JSON.stringify(formData)) {
        handleNext("next");
        return;
      }
      // const booleanManglik = formData.manglik;
      const data = {
        religionAndFamily: {
          religion: formData.religion,
          caste: formData.caste,
          otherCaste: formData.otherCaste,
          subcaste: formData.subcaste,
          otherSubcaste: formData.otherSubcaste,
          birthTimeUnknown: formData.birthTimeUnknown,
          birthTime: formData.birthTime,
          // birthPlace: formData.birthPlace,
          birthCity: formData.birthCity,
          birthState: formData.birthState,
          birthCountry: formData.birthCountry,
          religiousBackground: formData.religiousBackground,
          manglik: formData.manglik,
          parentsStatus: formData.parentsStatus,
          siblingsDevotees: formData.siblingsDevotees,
          siblingsDetails: formData.siblingsDetails,
        },
        family: {
          parentsDevotees: formData.parentsDevotees,
          familyValues: formData.familyValues,
          familyType: formData.familyType,
          familyStatus: formData.familyStatus,
          fatherName: formData.fatherName,
          fatherStatus: formData.fatherStatus,
          fatherPhoneNumber: formData.fatherPhoneNumber,
          motherName: formData.motherName,
          motherStatus: formData.motherStatus,
          motherPhoneNumber: formData.motherPhoneNumber,
          noOfBrothers: formData.noOfBrothers,
          marriedBrothers: formData.marriedBrothers,
          noOfSisters: formData.noOfSisters,
          marriedSisters: formData.marriedSisters,
          familyLocation: formData.familyLocation,
          familyOrigin: formData.familyOrigin,
          familyDetails: formData.familyDetails,
          siblingsDevotees: formData.siblingsDevotees,
        },
        mentor: {
          mentorPosition: formData.mentorPosition,
          connectedTemple: formData.connectedTemple,
          mentorName: formData.mentorName,
          mentorsEmail: formData.mentorsEmail,
          mentorsPhoneNumber: formData.mentorsPhoneNumber,
        },
      };

      try {
        let res = [];
        if (userRole === "admin") {
          res = await authorizedPost(
            `${configuration.apis.saveProfile}?editUserId=${userDetails?._id}`,
            data,
            config,
          );
        } else {
          res = await authorizedPost(
            configuration.apis.saveProfile,
            data,
            config,
          );
        }

        if (res.data.status) {
          handleNext("next");

          const updatedProfileData = res?.data?.data;
          const profileCompletionPercentage =
            res?.data?.profileCompletionPercentage;

          if (userRole === "admin") {
            setUserDetails({
              ...userDetails,

              profile: {
                ...userDetails.profile,

                religionAndFamily: {
                  ...updatedProfileData?.profile?.religionAndFamily,
                },

                family: {
                  ...updatedProfileData?.profile?.family,
                },
                mentor: {
                  ...updatedProfileData?.profile?.mentor,
                },
              },
            });
          } else {
            if (updatedProfileData) {
              Object.keys(updatedProfileData).forEach((field) => {
                updateValueInLocalStorage(
                  "userData",
                  field,
                  updatedProfileData[field],
                );
              });
            }
            if (profileCompletionPercentage) {
              updateValueInLocalStorage(
                "userData",
                "profileCompletionPercentage",
                profileCompletionPercentage,
              );
            }
          }
        }
      } catch (err) {
        console.log("error", err);
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const handleNext = (direction) => {
    if (direction === "next" && currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else if (direction === "prev" && currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const RequiredLabel = ({ label }) => (
    <span>
      {label}
      <span className="text-red-500">*</span>
    </span>
  );

  return (
    <div className="font-helvetica flex min-h-screen w-full items-center justify-center">
      <div className="mx-auto mb-14 mt-10 flex w-[95%] flex-col gap-1 rounded-lg bg-bgWhite pb-3 ">
        <form
          onSubmit={handleSubmit}
          className="mt-4 flex w-full flex-col gap-3 px-2 lg:gap-0 lg:p-1"
        >
          <div>
            <div className="mx-auto mt-5 flex w-[95%] flex-col gap-5  p-5">
              <div className="flex flex-col gap-3 ">
                <h3 className="font-bold text-textGreen">
                  {Strings.RELIGION_FAMILY}{" "}
                </h3>
                <h1 className="pb-3 text-3xl">{Strings.RELIGION} </h1>
                <hr />
              </div>
              <div className="pb-2">
                <div className="flex flex-col gap-2 pb-3">
                  <SelectField
                    selectType="field1"
                    className="w-full"
                    label={<RequiredLabel label="Religion By Birth" />}
                    name="religion"
                    placeholder="Select Religion"
                    options={religionOption}
                    value={formData.religion}
                    onChange={handleChange}
                    error={errors.religion}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Caste By Birth" />}
                    name="caste"
                    placeholder="Select Caste"
                    options={casteOption}
                    value={formData.caste}
                    onChange={handleChange}
                    error={errors.caste}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label="Other caste "
                    name="otherCaste"
                    type="text"
                    placeholder="Enter Other caste"
                    value={formData.otherCaste}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label="Subcaste By Birth"
                    name="subcaste"
                    placeholder="Select Subcaste"
                    options={filteredSubcasteOptions}
                    value={formData.subcaste}
                    onChange={handleChange}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label="Other subcaste "
                    name="otherSubcaste"
                    type="text"
                    placeholder="Enter Other subcaste"
                    value={formData.otherSubcaste}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label={<RequiredLabel label="Date of Birth" />}
                    name="dob"
                    type="date"
                    disabled={true}
                    placeholder="Enter Date of Birth"
                    value={formData.dob}
                    onChange={handleChange}
                    error={errors.dob}
                  />
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Is Birth time unknown" />}
                    name="birthTimeUnknown"
                    placeholder="Select Is Birth time unknown"
                    options={birthTimeUnknownOption}
                    value={formData.birthTimeUnknown}
                    onChange={handleChange}
                    error={errors.birthTimeUnknown}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Birth time" />}
                    name="birthTime"
                    type="time"
                    placeholder="Enter Birth time"
                    value={formData.birthTime}
                    onChange={handleChange}
                    error={errors.birthTime}
                  />
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Manglik" />}
                    name="manglik"
                    placeholder="Select Manglik"
                    options={manglikOptions}
                    value={formData.manglik}
                    onChange={handleChange}
                    error={errors.manglik}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  {/* <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Birth place"/>}
                    name="birthPlace"
                    type="text"
                    placeholder="Enter Birth place"
                    value={formData.birthPlace}
                    onChange={handleChange}
                    error={errors.birthPlace}
                  /> */}
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label={<RequiredLabel label="Birth city" />}
                    name="birthCity"
                    type="text"
                    placeholder="Enter Birth city"
                    value={formData.birthCity}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[A-Za-z\s]*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    error={errors.birthCity}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label="Birth State"
                    name="birthState"
                    type="text"
                    placeholder="Enter Birth state"
                    value={formData.birthState}
                    onChange={handleChange}
                    error={errors.birthState}
                  />
                </div>

                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full  md:w-[50%]"
                    label="Birth Country"
                    name="birthCountry"
                    placeholder="Select Birth Country"
                    options={countryOptions}
                    value={formData.birthCountry}
                    onChange={handleChange}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label="Religious background "
                    name="religiousBackground"
                    type="text"
                    placeholder="Enter Religious background"
                    value={formData.religiousBackground}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <hr className="border-2 border-textBlack" />
            </div>
            <div className="mx-auto mt-5 flex w-[95%] flex-col gap-5 p-5">
              <div className="flex flex-col gap-3 ">
                <h3 className="font-bold text-textGreen">
                  {Strings.RELIGION_FAMILY}{" "}
                </h3>
                <h1 className="pb-3 text-3xl">{Strings.FAMILY} </h1>
                <hr />
              </div>
              <div className="pb-2">
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Are your parents devotees?" />}
                    name="parentsDevotees"
                    placeholder="Select Parents Devotees"
                    options={parentsDevoteesOption}
                    value={formData.parentsDevotees}
                    onChange={handleChange}
                    error={errors.parentsDevotees}
                  />

                  {/* Conditional Select Field */}
                  {formData.parentsDevotees === "No" && (
                    <SelectField
                      selectType="field1"
                      className="w-full md:w-[50%]"
                      label="Parents' Status"
                      name="parentsStatus"
                      placeholder="Select Parents' Status"
                      options={parentsStatusOption}
                      value={formData.parentsStatus}
                      onChange={handleChange}
                      error={errors.parentsStatus}
                    />
                  )}
                </div>

                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Family Status" />}
                    name="familyStatus"
                    placeholder="Select Family Status"
                    options={familyStatusOption}
                    value={formData.familyStatus}
                    onChange={handleChange}
                    error={errors.familyStatus}
                  />
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label="Family Values"
                    name="familyValues"
                    placeholder="Select Family Values"
                    options={familyValuesOption}
                    value={formData.familyValues}
                    onChange={handleChange}
                  />
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Family Type" />}
                    name="familyType"
                    placeholder="Select Family Type"
                    options={familyTypeOption}
                    value={formData.familyType}
                    onChange={handleChange}
                    error={errors.familyType}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Father Name " />}
                    name="fatherName"
                    type="text"
                    placeholder="Enter Father Name"
                    value={formData.fatherName}
                    onChange={handleChange}
                    error={errors.fatherName}
                  />
                  {/* <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label="Father Phone Number "
                    name="fatherPhoneNumber"
                    type="text"
                    placeholder="Enter Father Phone Number"
                    value={formData.fatherPhoneNumber}
                    onChange={handleChange}
                    error={errors.fatherPhoneNumber}
                  /> */}
                  <div className="w-full md:w-[50%]">
                    <label
                      htmlFor="fatherPhoneNumber"
                      className="mb-2 block text-base text-black"
                    >
                      Father Phone Number :
                    </label>
                    <PhoneInput
                      country={"in"}
                      inputClass="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none pt-4 pb-4 "
                      inputProps={{
                        name: "fatherPhoneNumber",
                        id: "fatherPhoneNumber",
                      }}
                      value={formData.fatherPhoneNumber}
                      onChange={(value) =>
                        handleChange({
                          target: { name: "fatherPhoneNumber", value },
                        })
                      }
                    />
                    {errors.fatherPhoneNumber && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors.fatherPhoneNumber}
                      </p>
                    )}
                  </div>
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%] "
                    label={<RequiredLabel label="Father Status " />}
                    name="fatherStatus"
                    placeholder="Select Father Status"
                    options={fatherStatusOption}
                    value={formData.fatherStatus}
                    onChange={handleChange}
                    error={errors.fatherStatus}
                  />
                </div>

                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label="Mother Name "
                    name="motherName"
                    type="text"
                    placeholder="Enter Mother Name"
                    value={formData.motherName}
                    onChange={handleChange}
                  />
                  {/* <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label="Mother Phone Number "
                    name="motherPhoneNumber"
                    type="text"
                    placeholder="Enter Mother Phone Number"
                    value={formData.motherPhoneNumber}
                    onChange={handleChange}
                  /> */}
                  <div className="w-full md:w-[50%]">
                    <label
                      htmlFor="motherPhoneNumber"
                      className="mb-2 block text-base text-black"
                    >
                      Mother Phone Number :
                    </label>
                    <PhoneInput
                      country={"in"}
                      inputClass="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none pt-4 pb-4 "
                      inputProps={{
                        name: "motherPhoneNumber",
                        id: "motherPhoneNumber",                      }}
                      value={formData.motherPhoneNumber}
                      onChange={(value) =>
                        handleChange({
                          target: { name: "motherPhoneNumber", value },
                        })
                      }
                    />
                    {errors.motherPhoneNumber && (
                      <p className="mt-1 text-sm text-red-500">
                        {errors.motherPhoneNumber}
                      </p>
                    )}
                  </div>
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%] "
                    label={<RequiredLabel label="Mother Status " />}
                    name="motherStatus"
                    placeholder="Select Mother Status"
                    options={motherStatusOption}
                    value={formData.motherStatus}
                    onChange={handleChange}
                    error={errors.motherStatus}
                  />
                </div>

                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label="Are your siblings (or) any other relatives devotees?"
                    name="siblingsDevotees"
                    placeholder="Select Siblings (or) Relatives Devotees"
                    options={siblingsDevoteesOption}
                    value={formData.siblingsDevotees}
                    onChange={handleChange}
                    error={errors.siblingsDevotees}
                  />
                  {formData.siblingsDevotees === "Yes" && (
                    <div className=" w-full md:w-[50%]">
                      <label>
                        Please provide details about siblings or relatives
                      </label>
                      <input
                        type="text"
                        name="siblingsDetails"
                        value={formData.siblingsDetails}
                        onChange={handleChange}
                        placeholder="Enter details"
                        className={`w-full border ${
                          errors.siblingsDetails
                            ? "border-red-500"
                            : "border-gray-300"
                        } p-2`}
                      />
                      {errors.siblingsDetails && (
                        <span className="text-sm text-red-500">
                          {errors.siblingsDetails}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label={<RequiredLabel label="No.of Brothers" />}
                    name="noOfBrothers"
                    type="number"
                    placeholder="Enter No.of Brothers"
                    value={formData.noOfBrothers}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9]*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    error={errors.noOfBrothers}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label={<RequiredLabel label="No.of Brothers Married " />}
                    name="marriedBrothers"
                    type="text"
                    placeholder="Enter Brothers married"
                    value={formData.marriedBrothers}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9]*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    error={errors.marriedBrothers}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label={<RequiredLabel label="No.of Sisters " />}
                    name="noOfSisters"
                    type="number"
                    placeholder="Enter No.of Sisters"
                    value={formData.noOfSisters}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9]*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    error={errors.noOfSisters}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label={<RequiredLabel label="No. of Sisters Married " />}
                    name="marriedSisters"
                    type="number"
                    placeholder="Enter Sisters married"
                    value={formData.marriedSisters}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9]*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    error={errors.marriedSisters}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%] "
                    label={<RequiredLabel label="Family Current Location " />}
                    name="familyLocation"
                    placeholder="Select Family Location"
                    options={familyLocationOption}
                    value={formData.familyLocation}
                    onChange={handleChange}
                    error={errors.familyLocation}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%] "
                    label="Ancestral/Family Origin "
                    name="familyOrigin"
                    type="text"
                    placeholder="Enter Ancestral/Family Origin"
                    value={formData.familyOrigin}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col gap-2 pb-2">
                  <label htmlFor="familyDetails">Family Details:</label>
                  <textarea
                    type="Type"
                    placeholder="Family details"
                    autoComplete="off"
                    name="familyDetails"
                    className="rounded-lg border border-gray-200 p-2.5  text-textBlack focus:outline-none"
                    onChange={handleChange}
                    value={formData.familyDetails}
                  />
                </div>
              </div>
              <hr className="border-2 border-textBlack" />
              <div>
                <div className="mx-auto mt-5 flex w-[95%] flex-col gap-5  p-5">
                  <div className="flex flex-col gap-3 ">
                    <h3 className="font-bold text-textGreen">
                      {Strings.RELIGION_FAMILY}
                    </h3>
                    <h1 className="pb-3 text-3xl">{Strings.MENTOR} </h1>
                    <hr />
                  </div>
                  <div className="w-full ">
                    <div className="mb-3 flex items-center gap-2">
                      <input
                        className="mb-1 h-[16px] w-[16px] cursor-pointer focus:outline-none"
                        type="checkbox"
                        name="havementor"
                        checked={isMandartory}
                        onChange={() => setIsMandartory(!isMandartory)}
                      />
                      <label className="" htmlFor="birth">
                        Do you have devotee counselor/siksha guru/guiding
                        devotee
                      </label>
                    </div>
                    {errors.birth && (
                      <span className="text-xs text-textRed">
                        {errors.birth}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                    <InputField
                      fieldType="field1"
                      className="w-full  md:w-[50%]"
                      label={
                        isMandartory ? (
                          <RequiredLabel label="Mentor Name" />
                        ) : (
                          "Mentor Name"
                        )
                      }
                      name="mentorName"
                      type="text"
                      placeholder="Enter Mentor Name"
                      value={formData.mentorName}
                      onChange={handleChange}
                      error={errors.mentorName}
                    />
                    <InputField
                      fieldType="field1"
                      className="w-full  md:w-[50%]"
                      label={
                        isMandartory ? (
                          <RequiredLabel label="Mentor Position" />
                        ) : (
                          "Mentor Position"
                        )
                      }
                      name="mentorPosition"
                      type="text"
                      placeholder="Enter Mentor Position"
                      value={formData.mentorPosition}
                      onChange={handleChange}
                      error={errors.mentorPosition}
                    />
                  </div>
                  <div className="flex flex-col gap-2 pb-3">
                    <InputField
                      fieldType="field1"
                      className="w-full"
                      label={
                        isMandartory ? (
                          <RequiredLabel label="Which ISKCON Temple is your mentor connected to? Please specify if it's a Yatra, Namahatta, or Temple" />
                        ) : (
                          "Which ISKCON Temple is your mentor connected to? Please specify if it's a Yatra, Namahatta, or Temple"
                        )
                      }
                      name="connectedTemple"
                      type="text"
                      placeholder="Enter Your Mentor Connected ISKCON Temple"
                      value={formData.connectedTemple}
                      onChange={handleChange}
                      error={errors.connectedTemple}
                    />
                  </div>

                  <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                    <InputField
                      fieldType="field1"
                      className="w-full md:w-[50%] "
                      label={
                        isMandartory ? (
                          <RequiredLabel label="Mentor email" />
                        ) : (
                          "Mentor email"
                        )
                      }
                      name="mentorsEmail"
                      type="email"
                      placeholder="Enter Mentor email "
                      value={formData.mentorsEmail}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleChange(e);
                      }}
                      error={errors.mentorsEmail}
                    />
                    {/* <InputField
                      fieldType="field1"
                      className="w-full md:w-[50%] "
                      label={
                        isMandartory ? (
                          <RequiredLabel label="Mentor Phone Number" />
                        ) : (
                          "Mentor Phone Number"
                        )
                      }
                      name="mentorsPhoneNumber"
                      type="text"
                      placeholder="Enter Mentor Phone Number"
                      value={formData.mentorsPhoneNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[0-9\s]*$/.test(value)) { 
                          handleChange(e);
                        }
                      }}
                      error={errors.mentorsPhoneNumber}
                    /> */}
                    <div className="w-full md:w-[50%]">
                      <label
                        htmlFor="mentorsPhoneNumber"
                        className="mb-2 block text-base text-black"
                      >
                        {isMandartory ? (
                          <RequiredLabel label="Mentor Phone Number :" />
                        ) : (
                          "Mentor Phone Number :"
                        )}
                      </label>                    
                      <PhoneInput
                        country={"in"}
                        inputClass="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none pt-4 pb-4 "
                        inputProps={{
                          name: "mentorsPhoneNumber",
                          id: "mentorsPhoneNumber",
                        }}
                        value={formData.mentorsPhoneNumber}
                        onChange={(value) =>
                          handleChange({
                            target: { name: "mentorsPhoneNumber", value },
                          })
                        }
                      />
                      {errors.mentorsPhoneNumber && (
                        <p className="mt-1 text-sm text-red-500">
                          {errors.mentorsPhoneNumber}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errors && (
              <div className="flex w-full items-center justify-center p-2">
                <p className="text-xs text-red-500">
                  {Object.entries(errors)
                    .map(([key, value]) => `${key} is ${value}`)
                    .join(", ")}
                </p>
              </div>
            )}
            <div className="container mb-2 flex justify-around">
              <button
                onClick={() => handleNext("prev")}
                type="button"
                className="cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white "
              >
                Previous
              </button>
              <button
                type="submit"
                className="relative cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white "
              >
                {isButtonLoading ? <Spinner /> : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Religion;
