import React from "react";
import Images from "../../utils/images";
import { DOBASEURL } from "../../utils/helper";

const PhotoDetails = ({ userData, Loading }) => {
  if (Loading) {
    return (
      <div className="flex h-screen  items-center justify-center text-center text-lg">
        Loading...
      </div>
    );
  }

  if (!userData) {
    return <div className="text-center text-lg ">No data available</div>;
  }

  // const photoInfo = userData?.profile?.photos?.images[0] || {};

  // return (
  //   <div className="max-w-sm bg-white p-5  ">
  //     {/* <h3 className="text-xl  border-t pt-4 mt-4 text-center">Photos</h3> */}
  //     <div className="mt-6 flex gap-10">
  //       <img
  //         src={DOBASEURL + photoInfo}
  //         className="h-full w-full object-cover "
  //         alt="Profile"
  //         onContextMenu={(e) => e.preventDefault()}
  //         onDragStart={(e) => e.preventDefault()}
  //       />
  //     </div>
  //   </div>
  // );

  const photoInfo = userData?.profile?.photos?.images || [];
  return (
    <div className="bg-transparent p-5">
      <div className="grid xs:grid-cols-2 md:grid-cols-4 gap-2">
        {photoInfo.length > 0 ? (
          photoInfo.map((image, index) => (
            <div className="mt-6 ">
              <img
                src={DOBASEURL + image}
                className="h-full w-full object-cover rounded"
                alt="Profile"
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
              />
            </div>
          ))
        ) : (
          <p className="col-span-3 text-center text-gray-500">
            No photos available
          </p>
        )}
      </div>
    </div>
  );
};

export default PhotoDetails;
