import React, { useEffect, useState } from "react";
import Images from "../../utils/images";
import { Link, Navigate, useNavigate } from "react-router-dom";

import MessageBar, {
  DOBASEURL,
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import { FaUserTimes } from "react-icons/fa";
import { MdDoubleArrow } from "react-icons/md";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa6";
import { authorizedGet, authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import Strings from "../../utils/Strings";
import { useParams } from "react-router-dom";
import Spinner from "../../Components/Spinner/Spinner";
import { logDOM } from "@testing-library/react";

const RequestDetails = ({ request }) => {
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const { userId } = useParams();
  const [User, setUserData] = useState([]);
  const [sendRequest, setSendRequests] = useState([]);
  const [receivedRequest, setReceiveRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAcceptRequestId, setLoadingAcceptRequestId] = useState(null);
  const [loadingDenyRequestId, setLoadingDenyRequestId] = useState(null);
  const [approve, setApprove] = useState(null);
  const [reject, setReject] = useState(null);
  const [approvedRequests, setApprovedRequests] = useState({});
  const [rejectedRequests, setRejectedRequests] = useState({});
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [error, setError] = useState(false);
  const [comment, setComment] = useState("");
  const [requestedId, setRequestedId] = useState(null);
  

  useEffect(() => {
    requestDetails();
  }, []);

  const tabList = [
    { name: "User Requests" },
    { name: "Accepted Requests" },
    { name: "Denied Requests" },
  ];

  const requestDetails = async () => {
    setLoading(true);
    try {
      const res = await authorizedGet(
        `${configuration.apis.requestDetail}?adminId=${adminId}&userId=${userId}`,
        config,
      );
      console.log("jhgdgjhc", res);
      const response = res?.data;
      if (response?.status) {
        setUserData(response?.data?.userData);
        setSendRequests(response?.data?.sendRequest);
        setReceiveRequests(response?.data?.receiveRequest);
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const handleApproval = async (decision, requestId) => {
    try {
      if (decision === "approve") {
        setLoadingAcceptRequestId(requestId);
      } else {
        setIsCommentSectionOpen(true);
      }

      const data = {
        adminId: adminId,
        requestId: requestId,
        action: decision === "approve" ? Strings.APPROVE : Strings.REJECT,
        comments: comment,
      };

      const res = await authorizedPost(
        configuration.apis.adminApproval,
        data,
        config,
      );
      const response = res?.data?.data;

      if (res?.data?.status) {
        successNotify(res?.data?.message);

        if (decision === "approve") {
          setApprove(requestId);
          setApprove(requestId);
          setApprovedRequests((prev) => ({
            ...prev,
            [requestId]: true,
          }));
          setRejectedRequests((prev) => ({
            ...prev,
            [requestId]: false,
          })); //
        } else {
          setReject(requestId);
          setRejectedRequests((prev) => ({
            ...prev,
            [requestId]: true,
          }));
          setApprovedRequests((prev) => ({
            ...prev,
            [requestId]: false,
          })); //
        }
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
    } finally {
      setLoadingAcceptRequestId(null);
      setLoadingDenyRequestId(null);
    }
  };

  const navigate = useNavigate();

  // function to go back to the last URL
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleAction = (decision,requestedId) => {
    setRequestedId(requestedId);
    if (decision === "approve") {
      handleApproval(decision, requestedId);
    } else {
      setIsCommentSectionOpen(true);
    }
  };


  const handleSaveComment = () => {
    if (comment.length < 15) {
      setError(true);
    } else {
      handleApproval("reject", requestedId);
      setIsCommentSectionOpen(false);
      setError(false);
      setComment("");
    }
  };

  const handleNoteChange = (event) => {
    setError(false);
    setComment(event.target.value);
  };

  const closeModal = () => {
    setComment("");
    setError(false);
    setIsCommentSectionOpen(false);
  };

  return (
    <div className="p-4">
      {/* Show Loading Spinner */}
      {loading ? (
        <div className="flex min-h-screen flex-col items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {/* Profile Header */}
          <div className="mb-[30px] flex flex-col items-center sm:flex-row sm:justify-between">
            <div className="hidden h-[100px] w-[240px] items-center gap-4 rounded-[15px] border bg-white p-3 shadow-md sm:flex  lg:w-[240px]">
              <div className="flex flex-col justify-between">
                <p className="mb-2 text-sm text-gray-600">
                  <span className="font-semibold text-black">
                    Send Request Count:{" "}
                  </span>
                  {sendRequest?.length}
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold text-black">
                    Requested Users Count:{" "}
                  </span>
                  {receivedRequest.length}
                </p>
              </div>
            </div>

            {/* User Profile Card */}
            <div className="flex h-[140px] w-[340px] items-center gap-4 rounded-[15px] border bg-white p-3 shadow-md lg:w-[340px]">
              <div></div>
              <div className="flex h-auto w-auto rounded-full">
                <Link to={`/user-request/viewProfile/${User.id}`}>
                  <img
                    className="h-[100px] w-[100px] rounded-full object-cover"
                    src={
                      User?.photos?.images.length > 0
                        ? DOBASEURL + User?.photos?.images[0]
                        : Images.DEFAULT_USER
                    }
                    alt="User Profile"
                  />
                </Link>
              </div>
              <div className="flex flex-col justify-between">
                <p className="mb-2 text-[18px] font-semibold">{User.name}</p>
                <p className="mb-2 text-sm text-gray-600">
                  <span className="font-semibold text-black">Age: </span>
                  {User.age}
                </p>
                <p className="text-sm text-gray-600">
                  <span className="font-semibold text-black">
                    Marital Status:{" "}
                  </span>
                  {User.maritalStatus}
                </p>
              </div>
            </div>

            <div className="hidden sm:flex">
              <p
                onClick={handleGoBack}
                className="ml-auto cursor-pointer text-blue-600 underline"
              >
                Back
              </p>
            </div>
          </div>

          <div>
            <div className="grid w-[100%] grid-cols-1  gap-6 md:grid-cols-1 lg:grid-cols-2 ">
              <div className=" borderpb-4 items-start justify-start rounded-lg pl-4 pr-4 pt-4  ">
                <h2 className="mb-5 flex items-center justify-center text-[25px]  text-textGreen underline">
                  Send Request
                </h2>

                {sendRequest.length === 0 ? (
                  <p className="col-span-3 text-center  font-normal text-gray-500">
                    No Send Request is found
                  </p>
                ) : (
                  <div className="mt-2 grid grid-cols-1 gap-4">
                    {sendRequest.map((user) => (
                      <div
                        key={user?.id}
                        className=" rounded-[15px] border bg-white p-3 shadow-md"
                      >
                        <div className="tems-center flex justify-between gap-4 ">
                          <div className="flex h-auto w-auto rounded-full">
                            <Link to={`/user-request/viewProfile/${user?.id}`}>
                              <img
                                className="h-[100px] w-[100px] rounded-full object-cover"
                                src={
                                  user?.photos?.images.length > 0
                                    ? DOBASEURL + user?.photos?.images[0]
                                    : Images.DEFAULT_USER
                                }
                                alt="User Profile"
                              />
                            </Link>
                          </div>

                          <div className="flex flex-1 flex-col">
                            <p className="mb-2 text-[18px] font-semibold">
                              {user?.name}
                            </p>
                            <p className="mb-2 text-sm text-gray-600">
                              <span className="font-semibold text-black ">
                                Age:{" "}
                              </span>
                              {user?.age}
                            </p>
                            <p className="text-sm text-gray-600">
                              <span className="font-semibold text-black">
                                Marital Status:{" "}
                              </span>
                              {user?.maritalStatus}
                            </p>
                          </div>

                          {/* Icons based on acceptance/rejection status */}
                          <div className="flex flex-col gap-2">
                            {user?.adminApproval === false &&
                            user?.adminReject === false ? (
                              <>
                                {approvedRequests[user?.requestId] ? (
                                  <div className="flex flex-col items-center justify-center">
                                    <button className="rounded-full bg-green-500 p-2 text-white hover:bg-green-800">
                                      <FaUserCheck size={28} />
                                    </button>
                                    <div className="mt-2 flex items-center justify-center text-sm">
                                      <p>Accepted</p>
                                    </div>
                                  </div>
                                ) : rejectedRequests[user?.requestId] ? (
                                  <div className="flex flex-col items-center justify-center">
                                    <button className="rounded-full bg-red-500 p-2 text-white hover:bg-red-800">
                                      <FaUserTimes size={28} />
                                    </button>
                                    <div className="mt-2 flex items-center justify-center text-sm">
                                      <p>Rejected</p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="gap-4">
                                    {/* Approve Button */}
                                    <button
                                      className={`mb-4 flex h-10 w-10 items-center justify-center rounded-full bg-green-500 text-white ${
                                        loadingAcceptRequestId ===
                                        user?.requestId
                                          ? "cursor-not-allowed opacity-50"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleAction(
                                          "approve",
                                          user?.requestId,
                                        )
                                      }
                                      disabled={
                                        loadingAcceptRequestId ===
                                        user?.requestId
                                      }
                                    >
                                      {loadingAcceptRequestId ===
                                      user?.requestId ? (
                                        <Spinner className="h-5 w-5" />
                                      ) : (
                                        <FaUserCheck className="h-5 w-4" />
                                      )}
                                    </button>

                                    {/* Reject Button */}
                                    <button
                                      className={`flex h-10 w-10 items-center justify-center rounded-full bg-red-500 text-white ${
                                        loadingDenyRequestId === user?.requestId
                                          ? "cursor-not-allowed opacity-50"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleAction(
                                          "reject",
                                          user?.requestId,
                                        )
                                      }
                                      disabled={
                                        loadingDenyRequestId === user?.requestId
                                      }
                                    >
                                      {loadingDenyRequestId ===
                                      user?.requestId ? (
                                        <Spinner className="h-5 w-5" />
                                      ) : (
                                        <FaUserTimes className="h-5 w-4" />
                                      )}
                                    </button>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {/* ✅ Show if Already Approved */}
                                {user?.adminApproval && (
                                  <div className="flex h-full flex-col items-center justify-center">
                                    <button className="rounded-full bg-green-500 p-2 text-white hover:bg-green-800">
                                      <FaUserCheck className="h-5 w-5" />
                                    </button>
                                    <div className="mt-2 flex items-center justify-center text-xs">
                                      <p>Accepted</p>
                                    </div>
                                  </div>
                                )}

                                {/* ✅ Show if Already Rejected */}
                                {user?.adminReject && (
                                  <div className="flex h-full flex-col items-center justify-center">
                                    <button className="rounded-full bg-red-500 p-2 text-white hover:bg-red-800">
                                      <FaUserTimes className="h-5 w-5" />
                                    </button>
                                    <div className="mt-2 flex items-center justify-center text-sm">
                                      <p>Rejected</p>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="mt-5 flex items-center justify-center">
                          <p className="text-xs text-gray-400">
                            Click profile to see full details
                          </p>
                          {isCommentSectionOpen && (
                            <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-10">
                              <div className="relative w-[80%] items-center justify-center rounded-lg bg-white p-8 shadow-lg md:w-[50%]">
                                <div className="flex flex-row gap-1">
                                  <h2 className="mb-4 text-lg font-semibold">
                                    Add a reason for reject
                                  </h2>
                                  <p className="text-sm font-semibold text-textRed">
                                    *
                                  </p>
                                </div>
                                <textarea
                                  className="w-full rounded border border-gray-300 p-2 "
                                  rows="4"
                                  onChange={handleNoteChange}
                                  placeholder="Enter your comments..."
                                />
                                {error && (
                                  <span className="mt-0 text-xs text-textRed">
                                    {comment.length < 15
                                      ? "Comment must be at least 15 characters"
                                      : "Required"}
                                  </span>
                                )}
                                <div className="mt-4 flex justify-end gap-2">
                                  <button
                                    className="rounded bg-gray-300 px-1 py-0.5 text-black md:px-4 md:py-2"
                                    onClick={closeModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                                    onClick={handleSaveComment}
                                  >
                                    Send response
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Requested Users Section */}
              <div className="mb-2 rounded-lg   p-4 text-[18px]  ">
                <h2 className="mb-6 text-center text-[25px] text-textGreen underline">
                  Requested Users
                </h2>

                {receivedRequest.length === 0 ? (
                  <p className="col-span-3 text-center font-normal text-gray-500 ">
                    No Requested User is found
                  </p>
                ) : (
                  <div className="mt-2 grid grid-cols-1 gap-4">
                    {receivedRequest.map((user) => (
                      <div
                        key={user.id}
                        className=" rounded-[15px] border bg-white p-3 shadow-md"
                      >
                        <div className="flex items-center justify-between gap-4">
                          <div className="flex h-auto w-auto rounded-full">
                            <div className="flex h-auto w-auto rounded-full">
                              <Link
                                to={`/user-request/viewProfile/${user?.id}`}
                              >
                                <img
                                  className="h-[100px] w-[100px] rounded-full object-cover"
                                  src={
                                    user?.photos?.images.length > 0
                                      ? DOBASEURL + user?.photos?.images[0]
                                      : Images.DEFAULT_USER
                                  }
                                  alt="User Profile"
                                />
                              </Link>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col">
                            <p className="mb-2 text-[18px] font-semibold">
                              {user.name}
                            </p>
                            <p className="mb-2 text-sm text-gray-600">
                              <span className="font-semibold text-black">
                                Age:{" "}
                              </span>
                              {user.age}
                            </p>
                            <p className="text-sm text-gray-600">
                              <span className="font-semibold text-black">
                                Marital Status:{" "}
                              </span>
                              {user.maritalStatus}
                            </p>
                          </div>

                          {/* Icons based on acceptance/rejection status */}
                          <div className="flex flex-col gap-2">
                            {user?.adminApproval === false &&
                            user?.adminReject === false ? (
                              <>
                                {approvedRequests[user?.requestId] ? (
                                  <div className="flex flex-col items-center justify-center">
                                    <button className="rounded-full bg-green-500 p-2 text-white hover:bg-green-800">
                                      <FaUserCheck size={28} />
                                    </button>
                                    <div className="mt-2 flex items-center justify-center text-sm">
                                      <p>Accepted</p>
                                    </div>
                                  </div>
                                ) : rejectedRequests[user?.requestId] ? (
                                  <div className="flex flex-col items-center justify-center">
                                    <button className="rounded-full bg-red-500 p-2 text-white hover:bg-red-800">
                                      <FaUserTimes size={28} />
                                    </button>
                                    <div className="mt-2 flex items-center justify-center text-sm">
                                      <p>Rejected</p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="gap-4">
                                    {/* Approve Button */}
                                    <button
                                      className={`mb-4 flex h-10 w-10 items-center justify-center rounded-full bg-green-500 text-white ${
                                        loadingAcceptRequestId ===
                                        user?.requestId
                                          ? "cursor-not-allowed opacity-50"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleAction(
                                          "approve",
                                          user?.requestId,
                                        )
                                      }
                                      disabled={
                                        loadingAcceptRequestId ===
                                        user?.requestId
                                      }
                                    >
                                      {loadingAcceptRequestId ===
                                      user?.requestId ? (
                                        <Spinner className="h-5 w-5" />
                                      ) : (
                                        <FaUserCheck className="h-5 w-4" />
                                      )}
                                    </button>

                                    {/* Reject Button */}
                                    <button
                                      className={`flex h-10 w-10 items-center justify-center rounded-full bg-red-500 text-white ${
                                        loadingDenyRequestId === user?.requestId
                                          ? "cursor-not-allowed opacity-50"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleAction(
                                          "reject",
                                          user?.requestId,
                                        )
                                      }
                                      disabled={
                                        loadingDenyRequestId === user?.requestId
                                      }
                                    >
                                      {loadingDenyRequestId ===
                                      user?.requestId ? (
                                        <Spinner className="h-5 w-5" />
                                      ) : (
                                        <FaUserTimes className="h-5 w-4" />
                                      )}
                                    </button>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {/* ✅ Show if Already Approved */}
                                {user?.adminApproval && (
                                  <div className="flex h-full flex-col items-center justify-center">
                                    <button className="rounded-full bg-green-500 p-2 text-white hover:bg-green-800">
                                      <FaUserCheck className="h-5 w-5" />
                                    </button>
                                    <div className="mt-2 flex items-center justify-center text-xs">
                                      <p>Accepted</p>
                                    </div>
                                  </div>
                                )}

                                {/* ✅ Show if Already Rejected */}
                                {user?.adminReject && (
                                  <div className="flex h-full flex-col items-center justify-center">
                                    <button className="rounded-full bg-red-500 p-2 text-white hover:bg-red-800">
                                      <FaUserTimes className="h-5 w-5" />
                                    </button>
                                    <div className="mt-2 flex items-center justify-center text-sm">
                                      <p>Rejected</p>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="mt-5 flex items-center justify-center">
                          <p className="text-xs text-gray-400">
                            Click profile to see full details
                          </p>
                          {isCommentSectionOpen && (
                            <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-40">
                              <div className="relative w-[80%] items-center justify-center rounded-lg bg-white p-8 shadow-lg md:w-[50%]">
                                <div className="flex flex-row gap-1">
                                  <h2 className="mb-4 text-lg font-semibold">
                                    Add a reason for reject
                                  </h2>
                                  <p className="text-sm font-semibold text-textRed">
                                    *
                                  </p>
                                </div>
                                <textarea
                                  className="w-full rounded border border-gray-300 p-2 "
                                  rows="4"
                                  onChange={handleNoteChange}
                                  placeholder="Enter your comments..."
                                />
                                {error && (
                                  <span className="mt-0 text-xs text-textRed">
                                    {comment.length < 15
                                      ? "Comment must be at least 15 characters"
                                      : "Required"}
                                  </span>
                                )}
                                <div className="mt-4 flex justify-end gap-2">
                                  <button
                                    className="rounded bg-gray-300 px-1 py-0.5 text-black md:px-4 md:py-2"
                                    onClick={closeModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                                    onClick={handleSaveComment}
                                  >
                                    Send response
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <MessageBar />
        </>
      )}
    </div>
  );
};

export default RequestDetails;
