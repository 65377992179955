import React from "react";
import Strings from "../../utils/Strings";
import { privacy } from "./Details";
import Footer from "../Footer/Footer";

const Privacy = () => {
  return (
    <div className="bg-gray-50">
      <div className="font-jost mb-4 min-h-screen w-full ">
      <div className="mx-auto flex w-[90%] flex-col gap-5 p-5 text-justify pt-24">
      <div className="p-5 bg-white shadow-lg rounded-xl">
      <div>
          <h1 className="p-2 text-4xl text-center">
            {Strings.PRIVACY_HEADER}{" "}
          </h1>
          <p className="text-md p-3 ">{Strings.PRIVACY_TEXT}</p>
        </div>
        {privacy.map((privacy, index) => {
          return (
            <div className="flex flex-col gap-2 p-2" key={index}>
              <h1 className="font-semibold underline ">{privacy.Title}</h1>
              <p className="text-md">{privacy.Content}</p>
              <p className="text-md">{privacy.Content1}</p>
              <p className="text-md">{privacy.Content2}</p>
              <p className="text-md">{privacy.Content3}</p>
              <p className="text-md">{privacy.Content4}</p>
            </div>
          );
        })}
      </div>
       
      </div>
    </div>
    <Footer/>
    </div>
    
  );
};

export default Privacy;
