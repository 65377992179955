import React from 'react'
import { BiFemale, BiMale } from 'react-icons/bi';
import { FaRegPenToSquare } from 'react-icons/fa6';
import { GiLovers } from 'react-icons/gi';

    const DashBoard = () => {
        const data = [
          {
            icon: <BiMale />,
            head: "Male",
            users: "1,500",
            bgColor: "bg-gradient-to-r from-teal-400 to-teal-700",
          },
          {
            icon: <BiFemale />,
            head: "Female",
            users: "1,500",
            bgColor: "bg-gradient-to-r from-rose-400 to-rose-700",
          },
          {
            icon: <GiLovers />,
            head: "Matches",
            users: "2,500",
            bgColor: "bg-gradient-to-r from-green-400 to-green-600",
          },
          {
            icon: <FaRegPenToSquare />,
            head: "Registers",
            users: "2,500",
            bgColor: "bg-gradient-to-r from-yellow-400 to-yellow-600",
          },
        ];
    
        return (
          <div className="mx-auto flex w-[97%] flex-row flex-wrap items-center justify-center gap-4 p-4 md:pt-8 lg:flex-nowrap">
            {data.map((details, index) => (
              <div
                key={index}
                className={`flex h-32 w-[45%] transform flex-col items-center justify-center rounded-lg p-4 shadow-lg transition-transform hover:scale-105 ${details.bgColor} border border-white/30 text-white`}
              >
                <div className="flex items-center justify-center p-2 text-4xl">
                  {details.icon}
                </div>
                <h1 className="text-lg font-semibold">{details.head}</h1>
                <p className="text-xl font-bold">{details.users}</p>
              </div>
            ))}
          </div>
        );
      };
    


export default DashBoard;
