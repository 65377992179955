import React, { useEffect, useState } from "react";
import Images from "../../utils/images";
import ProfileList from "../ProfileList/ProfileList";
import Spinner from "../Spinner/Spinner";
import Filter from "../../Admin/Pages/Filter/Filter";
import MessageBar, {
  config,
  DOBASEURL,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import { authorizedGet, authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import Pagination from "../Pagination/Pagination";
import Strings from "../../utils/Strings";
import { GoDotFill } from "react-icons/go";
import { ItemsPerPages } from "../../utils/data";
import ViewQuestionnaire from "../ViewQuestionnarie/ViewQuestionnaire";
import { Link } from "react-router-dom";

const UserInterest = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [userList, setUserList] = useState([]);
  const [saveUserList, setSaveUserlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredData, setFilteredData] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [requests, setRequest] = useState([]);
  const [acceptbuttonLoading, setAcceptButtonLoading] = useState(false);
  const [rejectButtonLoading, setRejectButtonLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [approvalDecision, setApprovalDecision] = useState(null);
  const [loadingrequestId, setLoadingRequestId] = useState(null);
  const [acceptedUsers, setAcceptedUsers] = useState([]);
  const gender = getValueFromLocalStorage(localStorageKey.USERDATA, "gender");
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
  const [canSendRequest, setCanSendRequest] = useState(false);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const [requestedId, setRequestedId] = useState(null);
  const [rejectedUsers, setRejectedusers] = useState([]);
  const itemsPerPage = ItemsPerPages;

  useEffect(() => {
    fetchUserRole();
    fetchAllUserData();
  }, []);

  const tabList = [
    { name: "Users" },
    { name: "Requests" },
    { name: "Accepted Users" },
    { name: "Rejected Users" },
  ];

  const fetchUserRole = async () => {
    const role = getValueFromLocalStorage(localStorageKey.USERDATA, "role");
    if (role) {
      setUserRole(role);
    }
  };

  //get all the users
  const fetchAllUserData = async () => {
    setLoading(true);
    try {
      const res = await authorizedGet(
        `${configuration.apis.searchAndSent}?gender=${gender}&userId=${userId}`,
        config,
      );
      const response = res?.data?.data?.userDetails;
      if (res?.data?.status) {
        let users = [];
        setCanSendRequest(res?.data?.data?.canSendRequest);
        response.forEach((list) => {
          if (
            list?.suggestionStatus === null ||
            list?.suggestionStatus === "Rejected"
          ) {
            if (
              list?.requestStatus === null ||
              (list?.requestStatus === "Rejected" && list?.request?.userReject)
            ) {
              users.push(list);
            }
          }
        });
        setUserList(users);
        setSaveUserlist(users);
      }
    } catch (err) {
      // Handle network or unexpected errors
      let errorMessage =
        "An unexpected error occurred. Please try again later.";

      if (err.response) {
        errorMessage =
          err.response.data.message || "Server error. Please try again.";
      } else if (err.request) {
        errorMessage = "Network error ";
      }

      errorNotify(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (index === 1) {
      fetchAllRequests();
    }
    if (index === 2 || index === 3) {
      approveRequests();
    }
  };

  //Get the Filter Details based on the filter input
  const handleApplyFilters = async (queryParams) => {
    try {
      setLoading(true);
      const res = await authorizedGet(
        `${configuration.apis.userFilter}?status=active&userId=${userId}&${queryParams}`,
        config,
      );
      const response = res?.data?.data?.userDetails;
      if (res?.data?.status) {
        // successNotify(res?.data?.message);
        let users = [];
        response.forEach((list) => {
          if (
            list?.suggestionStatus === null ||
            list?.suggestionStatus === "Rejected"
          ) {
            if (
              list?.requestStatus === null ||
              (list?.requestStatus === "Rejected" && list?.request)
            ) {
              users.push(list);
            }
          }
        });
        setUserList(users);
        setFilteredData(true);
      } else {
        setUserList([]);
        setFilteredData(true);
      }
    } catch (err) {
      errorNotify(Strings.ERROR);
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  //Get all requests for the user
  const fetchAllRequests = async () => {
    try {
      setLoading(true);
      const res = await authorizedGet(
        `${configuration.apis.userGetRequest}?userId=${userId}`,
        config,
      );
      const response = res?.data;
      if (response?.status) {
        setRequest(response?.data);
      }
    } catch (error) {
      console.log(error);
      errorNotify("Unexpected Error");
    } finally {
      setLoading(false);
    }
  };

  const handleClearFilter = () => {
    setFilteredData(false);
    setUserList(saveUserList);
  };

  const toggleFilter = () => setShowFilter(!showFilter);

  const totalPages = Math.ceil(userList.length / itemsPerPage);

  const currentUsers = userList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  //User Approval
  const handleApproval = async (decision, requestId) => {
    setLoadingRequestId(requestId);
    try {
      const data = {
        userId: userId,
        requestId: requestId,
        action: decision,
        comments: comment,
      };
      // console.log(data);

      if (decision === "approve") {
        setAcceptButtonLoading(true);
      } else {
        setRejectButtonLoading(true);
      }
      const res = await authorizedPost(
        configuration.apis.userApproval,
        data,
        config,
      );
      const response = res?.data?.data;
      if (res?.data?.status) {
        successNotify(res?.data?.message);
        const users = [];
        response.forEach((value) => {
          if (value?.requestStatus === "Pending") {
            users.push(value);
          }
        });
        setRequest(users);
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
      console.log(error);
    } finally {
      setAcceptButtonLoading(false);
      setRejectButtonLoading(false);
      setLoadingRequestId(null);
    }
  };

  //get all accepted Requests
  const approveRequests = async () => {
    try {
      setLoading(true);
      const res = await authorizedGet(
        `${configuration.apis.approveRequest}?userId=${userId}`,
        config,
      );
      const response = res?.data?.data;
      if (res?.data?.status) {
        const acceptedUsers = [];
        const rejectedUsers = [];

        response?.approved?.forEach((value) => {
          if (userId === value?.requestedUser?._id) {
            acceptedUsers.push(value);
          } else {
            acceptedUsers.push(value);
          }
        });

        response?.rejected?.forEach((value) => {
          if (userId === value?.requestedUser?._id) {
            rejectedUsers.push(value);
          } else {
            rejectedUsers.push(value);
          }
        });

        setAcceptedUsers(acceptedUsers);
        setRejectedusers(rejectedUsers);
      }
    } catch (error) {
      console.log(error);
      errorNotify("Unexpected Error");
    } finally {
      setLoading(false);
    }
  };

  const handleAction = (requestedId, decision) => {
    setRequestedId(requestedId);

    if (decision === "approve") {
      handleApproval(decision, requestedId);
    } else {
      setIsCommentSectionOpen(true);
    }
  };

  const handleNoteChange = (event) => {
    setError(false);
    setComment(event.target.value);
  };

  const closeModal = () => {
    setComment("");
    setError(false);
    setIsCommentSectionOpen(false);
  };

  const handleSaveComment = () => {
    if (comment.length < 15) {
      setError(true);
    } else {
      handleApproval("reject", requestedId);
      setIsCommentSectionOpen(false);
      setError(false);
      setComment("");
    }
  };

  return (
    <div className="flex  w-full flex-col px-3 py-12 md:mt-10">
      <div className="mb-3 h-auto w-full rounded-lg bg-bgWhite p-4 shadow-md">
        <div className="flex flex-col items-center justify-between gap-5 border-b text-textGreen md:flex-row">
          <ul className="font-jost flex w-full  flex-col md:w-auto md:flex-row">
            {tabList.map((list, index) => (
              <li
                key={index}
                className={`cursor-pointer px-4 py-2 text-center transition-all sm:text-xs md:text-base ${
                  activeTab === index
                    ? "border-b-2 border-textYellow  text-textYellow"
                    : "hover:bg-gray-100 hover:text-textYellow"
                }`}
                onClick={() => {
                  if (activeTab !== 1 || index !== 1) {
                    handleTabClick(index);
                  }
                }}
              >
                {list.name}
              </li>
            ))}
          </ul>

          {activeTab === 0 && (
            <div className="font-jost mt-4 flex gap-2 md:mt-0">
              <button
                className="rounded-lg bg-textYellow px-4 py-2 text-bgWhite"
                onClick={toggleFilter}
              >
                {showFilter ? "Hide Filter" : "Show Filter"}
              </button>
              <button
                className="rounded-lg bg-red-600 px-4 py-2 text-bgWhite"
                onClick={handleClearFilter}
              >
                Clear Filter
              </button>
            </div>
          )}
        </div>

        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="font-jost mt-4">
              {filteredData && activeTab === 0 && (
                <p className="mb-2 text-lg text-textGreen">
                  Filtered Result Users:
                </p>
              )}
              <>
                {activeTab === 0 && (
                  <>
                    {userList.length === 0 ? (
                      <div className="flex flex-col items-center justify-center ">
                        <p className="text-center">
                          No Users found for your criteria!
                        </p>
                      </div>
                    ) : (
                      <>
                        {currentUsers.map((item, index) => (
                          <ProfileList
                            key={index}
                            userData={item}
                            role={userRole}
                            canSendRequest={canSendRequest}
                          />
                        ))}
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          handlePageChange={setCurrentPage}
                        />
                      </>
                    )}
                  </>
                )}
              </>
              <>
                {activeTab === 1 && (
                  <>
                    {requests.length === 0 ? (
                      <div className="flex flex-col items-center justify-center ">
                        <p className="text-center">No Request Available!</p>
                      </div>
                    ) : (
                      <>
                        {requests.map((list, index) => (
                          <>
                            <div className="relative mb-4 flex flex-col gap-4 rounded-lg border border-gray-200 bg-bgWhite  shadow-md md:flex-row">
                              <div className="flex flex-col items-center  md:flex-row ">
                                <div className="md:h-[120px] md:w-[35%] lg:h-[150px] lg:w-[30%] xl:w-[30%]">
                                  <img
                                    src={
                                      list?.requestedUser?.photos?.length > 0
                                        ? DOBASEURL +
                                          list?.requestedUser?.photos?.[0]
                                        : gender === "male"
                                          ? Images.WOMEN
                                          : Images.MEN
                                    }
                                    onContextMenu={(e) => e.preventDefault()}
                                    onDragStart={(e) => e.preventDefault()}
                                    alt=""
                                    className={`h-full w-full rounded-xl object-contain p-2 blur`}
                                  />
                                </div>
                                <ul
                                  key={index}
                                  className="flex  flex-wrap gap-3 text-sm"
                                >
                                  {[
                                    {
                                      label: Strings.DOB,
                                      value: new Date(
                                        list?.requestedUser?.dob,
                                      ).toLocaleDateString("en-CA"),
                                    },
                                    {
                                      label: Strings.BIRTH_TIME,
                                      value: list?.requestedUser?.birthTime,
                                    },
                                    {
                                      label: Strings.BIRTH_CITY,
                                      value: list?.requestedUser?.birthCity,
                                    },
                                    {
                                      label: Strings.HEIGHT,
                                      value: list?.requestedUser?.height,
                                    },
                                    {
                                      label: Strings.MOTHERTONGUE,
                                      value: list?.requestedUser?.motherTongue,
                                    },
                                    {
                                      label: Strings.EDUCATION,
                                      value:
                                        list?.requestedUser?.education?.join(
                                          ", ",
                                        ),
                                    },
                                    {
                                      label: Strings.OCCUPATIONS,
                                      value:
                                        list?.requestedUser?.occupations?.join(
                                          ", ",
                                        ),
                                    },
                                    {
                                      label: Strings.ANNUAL_INCOME,
                                      value: list?.requestedUser?.annualIncome,
                                    },
                                    {
                                      label: Strings.SPIRITUAL_STATUS,
                                      value:
                                        list?.requestedUser?.spiritualStatus,
                                    },
                                    {
                                      label: Strings.CASTE,
                                      value: list?.requestedUser?.caste,
                                    },
                                  ].map((item, idx) => (
                                    <li
                                      key={idx}
                                      className="flex items-center  text-base"
                                    >
                                      <GoDotFill className="text-sm text-textBlack" />
                                      <span className="">
                                        {" "}
                                        {item.label} :{" "}
                                        <span className=" text-textGreen">
                                          {item.value}
                                        </span>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="flex flex-row items-center justify-center gap-2 p-2 md:flex-col">
                                <>
                                  {disableButton &&
                                  loadingrequestId === list._id ? (
                                    <div>
                                      <button className="rounded-lg bg-textYellow px-8 py-2 text-sm text-white md:w-auto md:px-6 md:py-2">
                                        {approvalDecision}
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      <button
                                        className="rounded-lg bg-green-500  px-8 py-2 text-sm text-white md:w-auto md:px-6 md:py-2"
                                        onClick={() =>
                                          handleAction(list._id, "approve")
                                        }
                                        disabled={loadingrequestId === list._id}
                                      >
                                        {" "}
                                        {loadingrequestId === list._id &&
                                        acceptbuttonLoading ? (
                                          <Spinner size={5} />
                                        ) : (
                                          "Accept"
                                        )}{" "}
                                      </button>
                                      <button
                                        className="rounded-lg bg-red-600 px-8 py-2 text-sm text-white md:w-auto md:px-6 md:py-2"
                                        onClick={() =>
                                          handleAction(list._id, "reject")
                                        }
                                        disabled={loadingrequestId === list._id}
                                      >
                                        {" "}
                                        {loadingrequestId === list._id &&
                                        rejectButtonLoading ? (
                                          <Spinner size={5} />
                                        ) : (
                                          "Reject"
                                        )}{" "}
                                      </button>
                                    </>
                                  )}
                                </>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
              <>
                {activeTab === 2 && (
                  <>
                    {acceptedUsers?.length === 0 ? (
                      <div
                        key={activeTab}
                        className="flex flex-col items-center justify-center "
                      >
                        <p className="text-center">No Data found!</p>
                      </div>
                    ) : (
                      <>
                        {acceptedUsers?.map((list, index) => (
                          <>
                            <div
                              key={index}
                              className="relative mb-4 flex flex-col gap-2 rounded-lg border border-gray-200 bg-bgWhite  p-2 shadow-md md:flex-row md:justify-between"
                            >
                              <div className="flex w-full flex-col items-center justify-center gap-2 md:w-[85%] md:flex-row md:justify-between ">
                                <div className="md:h-[120px] md:w-[45%] xl:h-[80px] xl:w-[20%]">
                                  <img
                                    src={
                                      userId === list?.requestedUser?._id
                                        ? list?.user?.images?.length > 0
                                          ? DOBASEURL + list?.user?.images?.[0]
                                          : gender === "male"
                                            ? Images.WOMEN
                                            : Images.MEN
                                        : userId === list?.requestedUser?._id
                                          ? DOBASEURL + list?.user?.images?.[0]
                                          : list?.requestedUser?.images
                                                ?.length > 0
                                            ? DOBASEURL +
                                              list?.requestedUser?.images?.[0]
                                            : gender === "male"
                                              ? Images.WOMEN
                                              : Images.MEN
                                    }
                                    onContextMenu={(e) => e.preventDefault()}
                                    onDragStart={(e) => e.preventDefault()}
                                    alt=""
                                    className={`h-full w-full rounded-xl object-cover `}
                                  />
                                </div>
                                <ul
                                  key={index}
                                  className="mt-4 flex flex-wrap items-center gap-2 text-sm"
                                >
                                  {[
                                    {
                                      label: Strings.DOB,
                                      value: new Date(
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.dob
                                          : list?.requestedUser?.dob || "N/A",
                                      ).toLocaleDateString("en-CA"),
                                    },
                                    {
                                      label: Strings.BIRTH_TIME,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.birthTime
                                          : list?.requestedUser?.birthTime ||
                                            "N/A",
                                    },
                                    {
                                      label: Strings.BIRTH_CITY,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.birthCity
                                          : list?.requestedUser?.birthCity ||
                                            "N/A",
                                    },
                                    {
                                      label: Strings.HEIGHT,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.height
                                          : list?.requestedUser?.height ||
                                            "N/A",
                                    },
                                    {
                                      label: Strings.MOTHERTONGUE,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.motherTongue
                                          : list?.requestedUser?.motherTongue ||
                                            "N/A",
                                    },
                                    {
                                      label: Strings.EDUCATION,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.education?.join(", ")
                                          : list?.requestedUser?.education?.join(
                                              ", ",
                                            ),
                                    },
                                    {
                                      label: Strings.OCCUPATIONS,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.occupations?.join(", ")
                                          : list?.requestedUser?.occupations?.join(
                                              ", ",
                                            ),
                                    },
                                    {
                                      label: Strings.ANNUAL_INCOME,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.annualIncome
                                          : list?.requestedUser?.annualIncome,
                                    },
                                    {
                                      label: Strings.SPIRITUAL_STATUS,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.spiritualStatus
                                          : list?.requestedUser
                                              ?.spiritualStatus,
                                    },
                                    {
                                      label: Strings.CASTE,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.caste
                                          : list?.requestedUser?.caste,
                                    },
                                  ].map((item, idx) => (
                                    <li
                                      key={idx}
                                      className="flex items-center text-base"
                                    >
                                      <GoDotFill className="text-sm text-textBlack" />
                                      <span className="">
                                        {" "}
                                        {item.label} :{" "}
                                        <span className="font-medium text-textGreen">
                                          {item.value}
                                        </span>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="flex w-full items-center justify-center md:w-auto">
                                <Link
                                  to={`/interest/viewProfile/${userId === list?.requestedUser?._id ? list?.user?._id : list?.requestedUser?._id}`}
                                >
                                  <button className="flex w-full rounded-lg  bg-textYellow px-4 py-2 text-sm text-white md:w-auto">
                                    View Profile
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
              <>
                {activeTab === 3 && (
                  <>
                    {rejectedUsers?.length === 0 ? (
                      <div className="flex flex-col items-center justify-center ">
                        <p className="text-center">No Data found!</p>
                      </div>
                    ) : (
                      <>
                        {rejectedUsers?.map((list, index) => (
                          <>
                            <div className="relative mb-4 flex flex-col gap-2 rounded-lg border border-gray-200 bg-bgWhite  p-2 shadow-md md:flex-row md:justify-between">
                              <div className="flex w-full flex-col items-center justify-center gap-2 md:w-[85%] md:flex-row md:justify-between ">
                                <div className="md:h-[120px] md:w-[45%] xl:h-[80px] xl:w-[20%]">
                                  <img
                                    src={
                                      userId === list?.requestedUser?._id
                                        ? list?.user?.images?.length > 0
                                          ? DOBASEURL + list?.user?.images?.[0]
                                          : gender === "male"
                                            ? Images.WOMEN
                                            : Images.MEN
                                        : userId === list?.requestedUser?._id
                                          ? DOBASEURL + list?.user?.images?.[0]
                                          : list?.requestedUser?.images
                                                ?.length > 0
                                            ? DOBASEURL +
                                              list?.requestedUser?.images?.[0]
                                            : gender === "male"
                                              ? Images.WOMEN
                                              : Images.MEN
                                    }
                                    onContextMenu={(e) => e.preventDefault()}
                                    onDragStart={(e) => e.preventDefault()}
                                    alt=""
                                    className={`h-full w-full rounded-xl object-cover blur `}
                                  />
                                </div>
                                <ul
                                  key={index}
                                  className="mt-4 flex flex-wrap items-center gap-2 text-sm"
                                >
                                  {[
                                    {
                                      label: Strings.DOB,
                                      value: new Date(
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.dob
                                          : list?.requestedUser?.dob || "N/A",
                                      ).toLocaleDateString("en-CA"),
                                    },
                                    {
                                      label: Strings.BIRTH_TIME,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.birthTime
                                          : list?.requestedUser?.birthTime ||
                                            "N/A",
                                    },
                                    {
                                      label: Strings.BIRTH_CITY,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.birthCity
                                          : list?.requestedUser?.birthCity ||
                                            "N/A",
                                    },
                                    {
                                      label: Strings.HEIGHT,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.height
                                          : list?.requestedUser?.height ||
                                            "N/A",
                                    },
                                    {
                                      label: Strings.MOTHERTONGUE,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.motherTongue
                                          : list?.requestedUser?.motherTongue ||
                                            "N/A",
                                    },
                                    {
                                      label: Strings.EDUCATION,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.education?.join(", ")
                                          : list?.requestedUser?.education?.join(
                                              ", ",
                                            ),
                                    },
                                    {
                                      label: Strings.OCCUPATIONS,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.occupations?.join(", ")
                                          : list?.requestedUser?.occupations?.join(
                                              ", ",
                                            ),
                                    },
                                    {
                                      label: Strings.ANNUAL_INCOME,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.annualIncome
                                          : list?.requestedUser?.annualIncome,
                                    },
                                    {
                                      label: Strings.SPIRITUAL_STATUS,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.spiritualStatus
                                          : list?.requestedUser
                                              ?.spiritualStatus,
                                    },
                                    {
                                      label: Strings.CASTE,
                                      value:
                                        userId === list?.requestedUser?._id
                                          ? list?.user?.caste
                                          : list?.requestedUser?.caste,
                                    },
                                  ].map((item, idx) => (
                                    <li
                                      key={idx}
                                      className="flex items-center text-base"
                                    >
                                      <GoDotFill className="text-sm text-textBlack" />
                                      <span className="">
                                        {" "}
                                        {item.label} :{" "}
                                        <span className="font-medium text-textGreen">
                                          {item.value}
                                        </span>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </>
        )}
      </div>

      {/* {questionVisibile && (
        <div className="popup-container fixed left-0 top-0 z-40 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="relative h-[98vh] w-[90%]  items-center justify-center rounded-lg bg-white p-8 shadow-lg">
            // <Questions file={"../../Assets/demopdf.pdf"} />
            <embed
              src={require("../../Assets/sampleQuestions.pdf#toolbar=0")}
              className="h-[80vh] w-full"
              type="application/pdf"
            />
            <div className="">
              <p className="cursor-pointer text-blue-500 hover:underline">
                Download Questionary
              </p>
              <div className="mt-1 flex items-center justify-between">
                <button
                  onClick={() => setUploadVisibile(true)}
                  className="w-[130px] rounded-sm bg-yellow-500 p-2 text-white hover:bg-yellow-600"
                >
                  Upload
                </button>
                <button
                  onClick={() => setQuestionVisibile(false)}
                  className="w-[100px] rounded-sm bg-red-600 p-2 text-white hover:bg-red-700"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {uploadVisibile && (
        <div className="popup-container fixed left-0 top-0 z-40 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-[50%]  items-center justify-center rounded-lg p-8 shadow-lg">
            <Questionnaire setUploadVisibile={setUploadVisibile} />
          </div>
        </div>
      )} */}
      {showFilter && (
        <Filter
          gender={gender}
          handleShowFilter={toggleFilter}
          onApplyFilters={handleApplyFilters}
        />
      )}
      {isCommentSectionOpen && (
        <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-[80%] items-center  justify-center rounded-lg bg-white p-8 shadow-lg md:w-[50%]">
            <div className="flex flex-row gap-1">
              <h2 className="mb-4 text-lg font-semibold">
                Add a reason for reject
              </h2>
              <p className="text-sm font-semibold text-textRed">*</p>
            </div>
            <textarea
              className="w-full rounded border border-gray-300 p-2 "
              rows="4"
              onChange={handleNoteChange}
              placeholder="Enter your comments..."
            />
            {error && (
              <span className="mt-0 text-xs text-textRed">
                {comment.length < 15
                  ? "Comment must be at least 15 characters"
                  : "Required"}
              </span>
            )}
            <div className="mt-4 flex justify-end gap-2">
              <button
                className="rounded bg-gray-300 px-1 py-0.5 text-black md:px-4 md:py-2"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                onClick={handleSaveComment}
              >
                Send response
              </button>
            </div>
          </div>
        </div>
      )}
      <MessageBar />
    </div>
  );
};

export default UserInterest;
