import React, { useEffect, useState } from "react";
import ProfileDetails from "./ProfileDetails";
import ProfessionDetails from "./ProfessionDetails";
import ReligionDetails from "./ReligionDetails";
import MentorDetails from "./MentorDetails";
import ExpectationDetails from "./ExpectationDetails";
import PhotoDetails from "./PhotoDetails";
import Images from "../../utils/images";
import Matches from "./Matches";
import { authorizedGet, authorizedDel } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import MessageBar, {
  config,
  DOBASEURL,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Notes from "./Notes";
import ViewQuestionnaire from "../../Components/ViewQuestionnarie/ViewQuestionnaire";
import Settings from "../../Components/AdminSettings/AdminSettings";
import Spinner from "../../Components/Spinner/Spinner";
import Strings from "../../utils/Strings";
import { IoSettingsOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";

const ViewProfile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const localStorageUserData = JSON.parse(localStorage.getItem("userData"));
  const userRole = localStorageUserData?.role;
  const { userId } = useParams();
  const [approvedQuestionaire, setApprovedQuestionaire] = useState([]);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const fetchSingleUser = async () => {
    try {
      setLoading(true);
      const res = await authorizedGet(
        `${configuration.apis.singleUser}?userId=${userId}`,
        config,
      );
      const response = res?.data?.data;
      if (response) {
        setUserData(response);
        setActiveTab(0);
      }
    } catch (err) {
      // Handle network or unexpected errors
      let errorMessage =
        "An unexpected error occurred. Please try again later.";

      if (err.response) {
        errorMessage =
          err.response.data.message || "Server error. Please try again.";
      } else if (err.request) {
        errorMessage = "Network error ";
      }

      errorNotify(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const getApproveRequests = async () => {
    setLoading(true);
    try {
      const res = await authorizedGet(
        `${configuration.apis.getQuestions}?userId=${userId}&requestedUserId=${localStorageUserData?._id}`,
        config,
      );
      const response = res?.data?.data;
      if (res?.data?.status) {
        setApprovedQuestionaire(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleUser();
    getApproveRequests();
  }, [userId]);

  const tabList = [
    "Profile",
    "Profession",
    "Religion & Family",
    "Mentor",
    "Photo",
    "Expectation",
    "Questionnaire",
    ...(userRole === "admin" ? ["Notes"] : []),
    ...(userRole === "admin" &&
    !(userData?.isBlocked || userData?.maritalStatus === "married")
      ? ["Matches"]
      : []),

    // ...(userRole === "user" ? ["Questionnire"] : []),
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const getPath = pathName.split("/");

  // function to go back to the last URL
  const handleGoBack = () => {
    navigate(-1);
    setUserData(null);
  };

  return (
    <div
      className={`font-jost flex min-h-screen w-full justify-center bg-bgColor ${userRole !== "admin" ? "mt-16" : ""}`}
    >
      <div className="flex w-full flex-col" style={{ maxWidth: "1400px" }}>
        {(userRole === "admin" ||
          getPath[1] === "interest" ||
          getPath[1] === "suggestion" ||
          getPath[1] === "accepted-users") && (
          <div className="relative flex w-full items-center p-2 md:p-4">
            <div className="flex items-center">
              <div className="font-jost flex w-[100px] cursor-pointer items-center gap-1 overflow-hidden rounded-full bg-textYellowLight pr-1  ms:w-[130px] md:w-[180px] md:gap-0 ">
                <div className="w-[30%] rounded-full">
                  <img
                    className="h-10 w-10 rounded-full object-cover"
                    src={
                      userData?.profile?.photos?.images?.[0]
                        ? DOBASEURL + userData?.profile?.photos?.images?.[0]
                        : userData?.gender === "male"
                          ? Images.MEN
                          : Images.WOMEN
                    }
                    alt=""
                    onContextMenu={(e) => e.preventDefault()}
                    onDragStart={(e) => e.preventDefault()}
                  />
                </div>
                <p className="scrollbar-hide relative w-[70%] overflow-x-auto whitespace-nowrap text-center">
                  {userData?.name}
                </p>
              </div>
            </div>

            <h1 className="text-md absolute left-1/2 -translate-x-1/2 transform text-textGreen md:text-3xl">
              Profile Details
            </h1>

            <p
              onClick={handleGoBack}
              className="ml-auto cursor-pointer text-blue-600 underline"
            >
              Back
            </p>
          </div>
        )}

        <div className="flex w-full flex-col px-4">
          <div className="h-auto w-full flex-grow overflow-auto rounded-lg bg-white p-4 shadow-md">
            <div className="flex gap-2  text-textGreen">
              <ul className="flex w-full flex-wrap  border-b-[0.5px]">
                {tabList.map((list, index) => (
                  <li
                    key={index}
                    className={`playFair cursor-pointer p-3 text-center transition-colors duration-300 
                      ${loading ? "cursor-not-allowed" : ""}
                      ${activeTab === index ? "playFair border-b-2 border-textYellow text-textYellow after:bg-textYellow" : "text-textGreen"}`}
                    onClick={() => !loading && handleTabClick(index)}
                  >
                    {list}
                  </li>
                ))}
              </ul>
              {userRole === "admin" && (
                <FaEdit
                  className="mr-2 cursor-pointer"
                  size={30}
                  onClick={() => setShowEditModal(true)}
                />
              )}
              {userRole === "admin" && (
                <IoSettingsOutline
                  className="mr-4 cursor-pointer"
                  size={30}
                  onClick={() => setShowSettingsModal(true)}
                />
              )}
            </div>

            {activeTab === 0 && (
              <ProfileDetails userData={userData} Loading={loading} />
            )}
            {activeTab === 1 && (
              <ProfessionDetails userData={userData} Loading={loading} />
            )}
            {activeTab === 2 && (
              <ReligionDetails userData={userData} Loading={loading} />
            )}
            {activeTab === 3 && (
              <MentorDetails userData={userData} Loading={loading} />
            )}
            {activeTab === 4 && (
              <PhotoDetails userData={userData} Loading={loading} />
            )}
            {activeTab === 5 && (
              <ExpectationDetails userData={userData} Loading={loading} />
            )}
            {activeTab === 6 && (
              <ViewQuestionnaire
                userId={userId}
                requestedUserId={localStorageUserData?._id}
                questionnaireData={approvedQuestionaire}
                userData={userData}
              />
            )}
            {/* {userRole === "admin" && activeTab === 7 && (
              <Matches gender={userData?.gender} />
            )} */}

            {userRole === "admin" && activeTab === 7 && <Notes />}
            {userRole === "admin" &&
              activeTab === 8 &&
              !(
                userData?.isBlocked || userData?.maritalStatus === "married"
              ) && <Matches gender={userData?.gender} />}
          </div>
        </div>

        {/* Settings Modal */}
        {showSettingsModal && (
          <Settings
            userData={userData}
            userId={userId}
            onClose={() => setShowSettingsModal(false)}
          />
        )}
        {/* Settings Modal */}
        {showEditModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="rounded-lg bg-white p-6 shadow-lg">
              <p className="mb-4 text-center">{Strings.EDIT_USER}</p>
              <div className="flex justify-around">
                <Link to="/editprofile" state={{ userId: userId }}>
                  <button className="rounded bg-blue-500 px-4 py-2 text-white">
                    Yes
                  </button>
                </Link>
                <button
                  onClick={() => setShowEditModal(false)}
                  className="rounded bg-gray-300 px-4 py-2"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <MessageBar />
    </div>
  );
};

export default ViewProfile;
