import React, { useEffect, useState } from "react";
import { Link, useParams,useLocation } from "react-router-dom";
import { authorizedGet, authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import MessageBar, {
  config,
  DOBASEURL,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import Spinner from "../../Components/Spinner/Spinner";
import { GoDotFill } from "react-icons/go";
import Filter from "../../Admin/Pages/Filter/Filter";
import Strings from "../../utils/Strings";
import Pagination from "../../Components/Pagination/Pagination";
import { ItemsPerPages } from "../../utils/data";
import AdvanceSearch from "../../Components/AdvanceSearch/AdvanceSearch";
import { ImCancelCircle } from "react-icons/im";
import { TbUserSearch } from "react-icons/tb";
import Images from "../../utils/images";
// import { io } from "socket.io-client";
import { IoIosSearch } from "react-icons/io";

const Matches = (gender) => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("perfectMatches");
  const [potentialMatches, setPotentialMatches] = useState([]);
  const [userList, setUserList] = useState([]);
  const [saveUserList, setSaveUserlist] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [sendButtonStatus, setSendButtonStatus] = useState({});
  const [searchloading, setSearchLoading] = useState(false);
  const [loadingMatchId, setLoadingMatchId] = useState(null);
  const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentPage = Number(searchParams.get("page")) || 1;
  const [matchesCurrentPage, setMatchesCurrentPage] = useState(currentPage);
  const [searchAndSendCurrentPage, setSearchAndSendCurrentPage] = useState(1);
  const [buttonStatus, setButtonStatus] = useState({});
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [searchUserList, setSearchUserlist] = useState([]);
  const itemsPerPage = ItemsPerPages;
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");

  const fetchMatchUser = async () => {
    setLoading(true);
    try {
      const res = await authorizedGet(
        `${configuration.apis.matches}?userId=${userId}`,
        config,
      );
      const response = res?.data?.data;

      if (response) {
        if (response.length > 0) {
          const newButtonStatus = {};
          const users = [];

          response.forEach((list) => {
            if (list?.maritalStatus !== "married") {
              if (
                list?.requestStatus === null ||
                list?.requestStatus === "Rejected"
              ) {
                if (
                  list?.suggestionStatus === null ||
                  list?.suggestionStatus === "Rejected" ||
                  list?.match
                ) {
                  users.push(list);
                }
              }
              if (list?.match?.Userdecision) {
                if (
                  list?.match?.Userdecision === "accepted" &&
                  list?.match?.matchedUserdecision === "accepted"
                ) {
                  newButtonStatus[list._id] = "Accepted";
                } else if (
                  list?.match?.Userdecision === "rejected" ||
                  list?.match?.matchedUserdecision === "rejected"
                ) {
                  newButtonStatus[list._id] = "Rejected";
                } else {
                  newButtonStatus[list._id] = "Pending";
                }
                // newButtonStatus[list._id] = list?.suggestionStatus || "Send Match";
              }
            }
          });
          setSendButtonStatus(newButtonStatus);
          setPotentialMatches(users);
        }
      }
    } catch (err) {
     // console.log("Error fetching data:", err);
    }
    setLoading(false);
  };

  // const [socket, setSocket] = useState(null);

  // useEffect(() => {
  //   setSocket(
  //     io("http://localhost:8000", {
  //       reconnection: true,
  //     }),
  //   );
  // }, []);

  // console.log(socket);

  useEffect(() => {
    fetchMatchUser();
    fetchAllUserData();
  }, [userId]);

  //get all users for sent and search section
  const fetchAllUserData = async () => {
    try {
      const res = await authorizedGet(
        `${configuration.apis.searchAndSent}?gender=${gender?.gender}&userId=${userId}`,
        config,
      );
      const response = res?.data?.data?.userDetails;
      if (res?.data?.status) {
        if (response?.length > 0) {
          const newButtonStatus = {};
          let users = [];

          response.forEach((list) => {
            if (
              list?.requestStatus === null ||
              list?.requestStatus === "Rejected"
            ) {
              if (
                list?.suggestionStatus === null ||
                list?.suggestionStatus === "Rejected" ||
                list?.match
              ) {
                users.push(list);
              }
            }
            if (list?.match?.Userdecision) {
              if (
                list?.match?.Userdecision === "accepted" &&
                list?.match?.matchedUserdecision === "accepted"
              ) {
                newButtonStatus[list._id] = "Accepted";
              } else if (
                list?.match?.Userdecision === "rejected" ||
                list?.match?.matchedUserdecision === "rejected"
              ) {
                newButtonStatus[list._id] = "Rejected";
              } else {
                newButtonStatus[list._id] = "Pending";
              }
              // newButtonStatus[list._id] = list?.suggestionStatus || "Send Match";
            }
          });
          setUserList(users);
          setSaveUserlist(users);
          setSendButtonStatus(newButtonStatus);
        }
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  const handleSendMatch = async (matchedUserId) => {
    setLoadingMatchId(matchedUserId);
    try {
      const data = {
        userId: userId,
        matchedUser: matchedUserId,
        adminId: adminId,
      };
     // console.log(data);
      const res = await authorizedPost(
        configuration.apis.sendMatches,
        data,
        config,
      );
      const response = res?.data?.data;
      if (res?.data?.status) {
        successNotify(res?.data?.message);
        setButtonStatus((prevStatus) => ({
          ...prevStatus,
          [matchedUserId]: true,
        }));
        // send notification
        // socket.emit("suggestion", "suggesstion send to the user");

        if (response && response.length > 0) {
          response.map((value) => {
            if (matchedUserId === value?._id) {
              setSendButtonStatus((prevStatus) => ({
                ...prevStatus,
                [value?._id]: value?.match?.decision,
              }));
            }
          });
        }
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (err) {
      errorNotify(Strings.ERROR);
      //console.log("Error sending match:", err);
    }
    setLoadingMatchId(null);
  };

  const handleApplyFilters = async (queryParams) => {
    setSearchLoading(true);
    try {
      //console.log(queryParams);
      const res = await authorizedGet(
        `${configuration.apis.userFilter}?status=active&userId=${userId}&${queryParams}`,
        config,
      );
      const response = res?.data?.data?.userDetails;
      if (res?.data?.status) {
        // successNotify(res?.data?.message);
        const users = [];
        response.forEach((list) => {
          if (
            list?.requestStatus === null ||
            list?.requestStatus === "Rejected"
          ) {
            if (
              list?.suggestionStatus === null ||
              list?.suggestionStatus === "Rejected" ||
              list?.match
            ) {
              users.push(list);
            }
          }
        });
        setUserList(users);
        //setUserList(response?.data);
      } else {
        // errorNotify(res?.data?.message);
        setUserList([]);
      }
    } catch (err) {
      errorNotify(Strings.ERROR);
      console.error("Error fetching data:", err);
      return [];
    }
    setSearchLoading(false);
  };

  const handleClearFilter = () => {
    setUserList(saveUserList);
  };

  const toggleFilter = () => setShowFilter(!showFilter);

  const totalMatchingPages = Math.ceil(potentialMatches.length / itemsPerPage);

  const totalSearchAndSendpages = Math.ceil(userList.length / itemsPerPage);

  const searchAndSendPagninationData = userList.slice(
    (searchAndSendCurrentPage - 1) * itemsPerPage,
    searchAndSendCurrentPage * itemsPerPage,
  );

  const potentialMatchesPagniationData = potentialMatches.slice(
    (matchesCurrentPage - 1) * itemsPerPage,
    matchesCurrentPage * itemsPerPage,
  );

  const handleClick = (selectedTab) => {
    setMatchesCurrentPage(1);
    setSearchAndSendCurrentPage(1);
    if (selectedTab === "perfectMatches") {
      setSelectedTab(selectedTab);
    } else {
      setSelectedTab(selectedTab);
    }
  };

  const handleAdvanceSearch = async (value) => {
    if (value.length >= 3) {
      // console.log(value);
      setSearchLoading(true);
      try {
       const filteredUsers = saveUserList?.filter((user) =>
        user?.name?.toLowerCase()?.includes(value?.toLowerCase()),
      );
      setUserList(filteredUsers)
        // let data = {};
        // data = {
        //   gender: gender?.gender === "male" ? "female" : "male",
        //   searchTerm: value,
        //   status: "active",
        // };
        // const res = await authorizedPost(
        //   configuration.apis.advanceSearch,
        //   data,
        //   config,
        // );
        // const response = res?.data?.data;
        // console.log("FilterData", response);
        // if (res?.data?.status) {
        //   //successNotify(res?.data?.message);
        //   //console.log(response);
        //   setUserList(response);
        // } else {
        //   setUserList([]);
        // }
      } catch (error) {
        errorNotify(Strings.ERROR);
        //console.log(error);
      } finally {
        //console.log(searchUserList.length);
        setSearchLoading(false);
      }
    }
  };

  const handleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible);
    setUserList(saveUserList);
  };


  return (
    <div className="font-jost mx-auto p-2">
      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="flex flex-grow justify-end md:flex-row">
            {selectedTab === "searchUser" && (
              <div
                className={`flex ${isSearchBarVisible ? "flex-col xs:flex-row" : "flex-row"} items-center justify-center gap-2`}
              >
                {isSearchBarVisible && (
                  <div className="flex flex-col items-center justify-center gap-1 border-x-gray-400 p-2 xs:flex-row">
                    <AdvanceSearch onSearchValue={handleAdvanceSearch} />
                  </div>
                )}
                <div className="flex items-center justify-center ">
                  {isSearchBarVisible ? (
                    <ImCancelCircle
                      style={{
                        fontSize: "25px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSearchBar()}
                    />
                  ) : (
                    <IoIosSearch
                      style={{
                        fontSize: "30px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSearchBar()}
                    />
                  )}
                </div>             
                <div className="flex flex-row justify-between gap-4 p-1">
                  <button
                    className="font-jost rounded-lg bg-textYellow px-4 py-2 text-[12px] text-bgWhite xs:text-[10px] md:text-[12px] xl:text-base"
                    onClick={toggleFilter}
                  >
                    Show Filter
                  </button>
                  <button
                    className="font-jost rounded-lg bg-red-600 px-4  py-2 text-xs text-bgWhite xl:text-base"
                    onClick={handleClearFilter}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Tab list with underline */}
          <div className="relative mb-6 flex flex-row space-x-80">
            <ul className="flex border-b border-gray-300">
              <li
                className={`font-jost cursor-pointer px-4 py-2 transition-colors duration-200 ${selectedTab === "perfectMatches" ? "border-b-2 border-textYellow text-textYellow" : "text-gray-600"}`}
                onClick={() => handleClick("perfectMatches")}
              >
                Potential Matches
              </li>
              <li
                className={`font-jost cursor-pointer px-4 py-2 transition-colors duration-200 ${selectedTab === "searchUser" ? "border-b-2 border-textYellow text-textYellow" : "text-gray-600"}`}
                onClick={() => handleClick("searchUser")}
              >
                Search & Send
              </li>
            </ul>
          </div>

          {/* Tab content */}
          {selectedTab === "perfectMatches" ? (
            <div className="p-4">
              {potentialMatches.length === 0 ? (
                <p className="flex items-center justify-center text-gray-500">
                  No potential matches found.
                </p>
              ) : (
                <>
                  <ul className="space-y-4">
                    {potentialMatchesPagniationData.map((item, index) => (
                      <li
                        key={index}
                        className="flex flex-col items-center justify-between rounded-lg border border-gray-200 bg-white p-4 md:flex-row"
                      >
                        <div className="flex w-full flex-col items-center md:flex-row">
                          <img
                            src={
                              item?.photos.length > 0
                                ? DOBASEURL + item?.photos?.[0]
                                : item?.gender === "male"
                                  ? Images.MEN
                                  : Images.WOMEN
                              // DOBASEURL + item?.photos?.[0]
                            }
                            className="mb-4 h-28 w-28 rounded-full object-cover sm:h-20 sm:w-20 md:mb-0 md:mr-4 md:h-16 md:w-16"
                            alt=""
                            onContextMenu={(e) => e.preventDefault()}
                            onDragStart={(e) => e.preventDefault()}
                          />

                          <div className="font-jost  flex-1">
                            <div className="text-lg">{item?.name}</div>
                            <div className="flex items-center ">
                              <GoDotFill className="mr-2 text-sm text-textBlack" />
                              Age: {item?.age}
                            </div>
                            <div className="flex items-center ">
                              <GoDotFill className="mr-2 text-sm text-textBlack" />
                              Gender: {item?.gender}
                            </div>
                            <div className="flex items-center ">
                              <GoDotFill className="mr-2 text-sm text-textBlack" />
                              Marital Status: {item?.maritalStatus}
                            </div>
                            <div className="flex items-center ">
                              <GoDotFill className="mr-2 text-sm text-textBlack" />
                              Expectated Match Count: {item?.matchCount}
                            </div>
                          </div>
                          <div className="mt-4 flex w-full flex-col items-center gap-4 sm:flex-row md:mt-0 md:w-auto">
                            {buttonStatus[item._id] ? (
                              <button className="font-jost w-full rounded-lg bg-textYellow px-4 py-2 text-white sm:w-auto">
                                Pending
                              </button>
                            ) : (
                              <button
                                className="font-jost flex w-full items-center justify-center rounded-lg bg-textYellow px-4 py-2 text-white sm:w-auto"
                                onClick={() => handleSendMatch(item._id)}
                                disabled={
                                  loadingMatchId === item._id ||
                                  sendButtonStatus[item._id]
                                }
                              >
                                {loadingMatchId === item._id ? (
                                  <Spinner size={16} />
                                ) : sendButtonStatus[item._id] === undefined ? (
                                  "Send Match"
                                ) : (
                                  sendButtonStatus[item._id]
                                )}
                              </button>
                            )}
                            <Link
                              to={`/matrimony/${currentPage}/viewProfile/${item._id || item?.userId}`}
                              className="w-full sm:w-auto"
                            >
                              <button className="font-jost flex w-full items-center justify-center rounded-lg bg-textYellow px-4 py-2 text-white sm:w-auto">
                                View Profile
                              </button>
                            </Link>
                          </div>
                        </div>
                      </li>
                    ))}
                    <Pagination
                      currentPage={matchesCurrentPage}
                      totalPages={totalMatchingPages}
                      handlePageChange={setMatchesCurrentPage}
                    />
                  </ul>
                </>
              )}
            </div>
          ) : (
            <>
              {searchloading ? (
                <div className="flex h-screen items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <div>
                  {userList.length === 0 ? (
                    <div>
                      <p className="flex items-center justify-center text-gray-500">
                        No users found for your criteria!
                      </p>
                    </div>
                  ) : (
                    <>
                      <ul className="space-y-4">
                        {searchAndSendPagninationData.map((item, index) => (
                          <li
                            key={index}
                            className="flex flex-col items-center justify-between rounded-lg border border-gray-200 bg-white p-4 md:flex-row"
                          >
                            <div className="flex w-full flex-col items-center md:flex-row">
                              <img
                                src={
                                  item?.photos?.length > 0
                                    ? DOBASEURL + item.photos?.[0]
                                    : item?.images?.length > 0
                                      ? DOBASEURL + item.images?.[0]
                                      : item?.gender === "male"
                                        ? Images.MEN
                                        : Images.WOMEN

                                  // DOBASEURL + item?.photos?.[0]
                                }
                                className="mb-4 h-28 w-28 rounded-full object-cover sm:h-20 sm:w-20 md:mb-0 md:mr-4 md:h-16 md:w-16"
                                alt=""
                                onContextMenu={(e) => e.preventDefault()}
                                onDragStart={(e) => e.preventDefault()}
                              />
                              <div className="flex-1">
                                <div className="text-lg">{item?.name}</div>
                                <div className="flex items-center">
                                  <GoDotFill className="mr-2 text-sm text-textBlack" />
                                  Age: {item?.age}
                                </div>
                                <div className="flex items-center">
                                  <GoDotFill className="mr-2 text-sm text-textBlack" />
                                  Gender: {item?.gender}
                                </div>
                                <div className="flex items-center">
                                  <GoDotFill className="mr-2 text-sm text-textBlack" />
                                  Marital Status: {item?.maritalStatus}
                                </div>
                                <div className="flex items-center">
                                  <GoDotFill className="mr-2 text-sm text-textBlack" />
                                  occupation: {item?.occupations?.[0]}
                                </div>
                              </div>
                              <div className="mt-4 flex w-full flex-col items-center gap-4 sm:flex-row md:mt-0 md:w-auto">
                                {buttonStatus[item._id] ? (
                                  <button className="font-jost w-full rounded-lg bg-textYellow px-4 py-2 text-white sm:w-auto">
                                    Pending
                                  </button>
                                ) : (
                                  <button
                                    className="font-jost flex w-full items-center justify-center rounded-lg bg-textYellow px-4 py-2 text-white sm:w-auto"
                                    onClick={() => handleSendMatch(item._id)}
                                    disabled={
                                      loadingMatchId === item._id ||
                                      sendButtonStatus[item._id]
                                    }
                                  >
                                    {loadingMatchId === item._id ? (
                                      <Spinner size={16} />
                                    ) : sendButtonStatus[item._id] ? (
                                      sendButtonStatus[item._id]
                                    ) : (
                                      "Send Match"
                                    )}
                                  </button>
                                )}
                                <Link
                                  to={`/matrimony/${currentPage}/viewProfile/${item._id || item?.userId}`}
                                  className="w-full sm:w-auto"
                                >
                                  <button className="font-jost flex w-full items-center justify-center rounded-lg bg-textYellow px-4 py-2 text-white sm:w-auto">
                                    View Profile
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                        <Pagination
                          currentPage={searchAndSendCurrentPage}
                          totalPages={totalSearchAndSendpages}
                          handlePageChange={setSearchAndSendCurrentPage}
                        />
                      </ul>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}

      {showFilter && (
        <Filter
          gender={gender?.gender}
          handleShowFilter={toggleFilter}
          onApplyFilters={handleApplyFilters}
        />
      )}
      {/* <MessageBar /> */}
    </div>
  );
};

export default Matches;
