import React from 'react'
import { Link } from 'react-router-dom'

function MarriedUserList({marriedUsers}) {
  return (
    <div className="flex flex-col gap-2 p-2">
         {marriedUsers?.map((item) => (
      <div key={item?._id} className='flex flex-col md:flex-row gap-2 justify-between p-2 w-full rounded-lg shadow-sm bg-white'>
    <div key={item.id} className="flex flex-wrap md:flex-row items-center gap-3 ">
<Link to={`/married-users/viewProfile/${item?.userId?._id}`} >
    <h3 className="text-lg hover:text-blue-600">{item?.userId?.name}</h3>
</Link>
    <p className="text-sm text-gray-500">{item?.userId?.maritalStatus}</p>
    <p className="text-sm text-gray-500">{item?.userId?.gender}</p>
    <p className="text-sm">{item?.userId?.primaryEmail}</p>
    <p className="text-sm">{item?.userId?.phone}</p>
    <p className="text-sm">{new Date(item?.userId?.dob).toLocaleDateString("en-CA")}</p>
</div>
{/* <Link to={`/married-users/viewProfile/${item?.userId?._id}`} >
<button  className="rounded-lg bg-textYellow px-1 py-1 text-xs text-white w-full md:w-auto "
>
  View Profile
</button>
</Link> */}
</div>
     ))}
    </div>
  )
}

export default MarriedUserList
