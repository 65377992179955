
import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const Spinner = () => {
  return (
    <div className="spinner-container">
      <RotatingLines
        visible={true}
        height="22"
        width="22"
        color="grey"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}} 
        wrapperClass=""  
      />
    </div>
  );
};

export default Spinner;
