import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";
import Register from "./Pages/Register/Register";
import Contact from "./Pages/Contact/Contact";
import About from "./Pages/About/About";
import Terms from "./Components/Terms&conditions/Terms";
import Privacy from "./Components/Terms&conditions/Privacy";
import EditProfile from "./Pages/EditProfile/EditProfile";
import { useState, useEffect } from "react";
import Loader from "./Components/Loader/Loader";
import EmailPopup from "./Components/EmailPopup/EmailPopup";
import Matrimony from "./Admin/Pages/Matrimony/Matrimony";
import ProfileAttributes from "./Admin/Pages/ProfileAttributes/ProfileAttributes";
import NewsLetter from "./Admin/Pages/NewsLetter/NewsLetter";
import PostOffice from "./Admin/Pages/PostOffice/PostOffice";
import PhotoLouge from "./Admin/Pages/PhotoLouge/PhotoLouge";
import UserCreation from "./Admin/Pages/UserCreation/UserCreation";
import ViewProfile from "./Pages/ViewProfile/ViewProfile";
import ForgetPassword from "./Components/ForgetPassword/ForgetPassword";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import DashBoard from "./Admin/Pages/DashBoard/DashBoard";
import { getValueFromLocalStorage, localStorageKey } from "./utils/helper";
import Setting from "./Components/Setting/Setting";
import Notification from "./Components/Notification/Notification";
import Users from "./Components/UserInterest/UserInterest";
import UserRequest from "./Admin/Pages/UserRequest/UserRequest";
import Request from "./Components/Request/Request";
import UserProfile from "./Pages/UserProfile/UserProfile";
import Suggestion from "./Components/Suggestion/Suggestion";
import UserInterest from "./Components/UserInterest/UserInterest";
import UserSideBar from "./Pages/UserSideBar/UserSideBar";
import AdminSideBar from "./Admin/AdminSideBar";
import AcceptedUsers from "./Components/AcceptedUsers/AcceptedUsers";
import QuestionnaireRequests from "./Admin/Pages/QuestionnnaireRequest/QuesttionnnaireRequest";
import QuestionnaireTab from "./Components/QuestionnaireTab.jsx/QuestionnaireTab";
import MarriedUsers from "./Admin/MarriedUsers/MarriedUsers";
import SessionExpire from "./Components/SessionExpire/SessionExpire";
import Testimonals from "./Pages/Testimonials/Testimonials";
import TestimonialsPage from "./Pages/Testimonials/Testimonials";
import Testimonials from "./Pages/Testimonials/Testimonials";
import FAQs from "./Pages/FAQS/Faqs";
import BlockedUsers from "./Pages/BlockedUser/blockedUser";
import RequestDetails from "./Pages/RequestDetails/RequestDetails";

const AppContent = () => {
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const userRole = getValueFromLocalStorage(localStorageKey.USERDATA, "role");
    setRole(userRole);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigate]);

  // Protected route for admin side
  const ProtectedRoute = ({ children }) => {
    if (role !== "user") {
      return <Navigate to={"/dashboard"} />;
    }
    return children;
  };

  // Protected route for admin side
  const ProtectedAdminRoute = ({ children }) => {
    if (role !== "admin") {
      return <Navigate to={"/matrimony"} />;
    }
    return children;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Routes>
      {/* <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<About/>}/>
      <Route path="/testimonials" element={<Testimonials/>}/>
      <Route path="/contactus" element={<Contact/>}/> */}

      <Route path="/login" element={<Login />} />
      <Route path="/admin" element={<Login />} />
      <Route path="/register" element={<Register />} />

      <Route element={<Navbar />}>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/faqs" element={<FAQs />} />
        {/* Profile Route with Nested Routes */}
        <Route element={<UserSideBar />}>
          <Route path="profile/:userId" element={<UserProfile />} />
          <Route
            path="suggestion"
            element={
              // <ProtectedRoute>
              <Suggestion />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/suggestion/viewProfile/:userId"
            element={<ViewProfile />}
          />
          <Route path="interest" element={<UserInterest />} />
          <Route
            path="/interest/viewProfile/:userId"
            element={<ViewProfile />}
          />
          <Route path="questionnaire" element={<QuestionnaireTab />} />
          {/* <Route path="requested-users" element={<Request />} /> */}
          <Route path="accepted-users" element={<AcceptedUsers />} />
          <Route
            path="/accepted-users/viewProfile/:userId"
            element={<ViewProfile />}
          />
          <Route path="settings" element={<Setting />} />
        </Route>

        <Route path="/contactus" element={<Contact />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/editprofile/:userData" element={<EditProfile />} />
        <Route path="/Notification" element={<Notification />} />
        <Route path="/session-expire" element={<SessionExpire />} />

        {/* Admin Routes */}
        <Route element={<AdminSideBar />}>
          <Route
            path="/dashboard"
            element={
              <ProtectedAdminRoute>
                <DashBoard />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/matrimony"
            element={
              // <ProtectedAdminRoute>
              <Matrimony />
              // </ProtectedAdminRoute>
            }
          />
          <Route
            path="/matrimony/:pageNo/viewProfile/:userId"
            element={<ViewProfile />}
          />
          <Route
            path="/married-users"
            element={
              <ProtectedAdminRoute>
                <MarriedUsers />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/married-users/viewProfile/:userId"
            element={
              <ProtectedAdminRoute>
                <ViewProfile />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/blocked-users"
            element={
              <ProtectedAdminRoute>
                <BlockedUsers />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/blocked-users/viewProfile/:userId"
            element={<ViewProfile />}
          />
          <Route
            path="/men-attributes"
            element={
              <ProtectedAdminRoute>
                <ProfileAttributes />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/news-letter"
            element={
              <ProtectedAdminRoute>
                <NewsLetter />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/post-office"
            element={
              <ProtectedAdminRoute>
                <PostOffice />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/photo-lounge"
            element={
              <ProtectedAdminRoute>
                <PhotoLouge />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/user-creation"
            element={
              <ProtectedAdminRoute>
                <UserCreation />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/user-request/viewProfile/:userId"
            element={<ViewProfile />}
          />
           <Route
            path="/request-detail/:userId"
            element={<RequestDetails />}
          />
          <Route
            path="/user-request"
            element={
              <ProtectedAdminRoute>
                <UserRequest />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/questionnaire-request/viewProfile/:userId"
            element={<ViewProfile />}
          />
          <Route
            path="/questionnaire-request"
            element={
              <ProtectedAdminRoute>
                <QuestionnaireRequests />
              </ProtectedAdminRoute>
            }
          />
        </Route>

        {/* <Route path="/viewProfile/:userId" element={<ViewProfile />} /> */}
        <Route path="/user/:user/verify/:token" element={<EmailPopup />} />
        <Route path="/Forgot-password" element={<ForgetPassword />} />
        <Route path="/Password-reset/:id/:token" element={<ResetPassword />} />
        <Route path="/addEmail/:user/verify/:token" element={<EmailPopup />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </div>
  );
};

export default App;
