const Strings = {
  PROFILE_TEXT: "",

  // profile page
  PROFILE: "Profile",
  PROFILE_SETTINGS: "Profile settings",
  PROFILE_STATUS: "Profile Status",
  EDIT_PROFILE: "Edit Profile",
  PROFILE_COMPLETION: "Profile Completion",
  PROFILE_INTERST: "Interests Request",
  PROFILE_ID: "Profile Id",
  AGE: "Age",
  DATE_OF_BIRTH: "Date of Birth",
  ANNUAL_INCOME: "Annual Income",
  STATUS: "Status",
  REGISTER_TIME: "Register Time",
  ID: "Id",
  VIEW_PROFILE: "View profile",
  CITY: "City",
  HEIGHT: "Height",
  JOB: "Job",
  REQUEST_ON: "Request on",
  GENDER: "Gender",
  MARITAL_STATUS: "Marital Status",
  PROFILE_COMPLETED: "Profile Completed",
  // About Page
  ABOUT: "About US",
  ABOUT_TEXT: "Most Trusted and premium Matrimony Service in the World.",
  WELCOME: "WELCOME TO",
  WEDDING_MATRIMONY: "WEDDING MATRIMONY",
  ABOUT_LINK: "Click here to",
  ENQUIRY: "Enquiry",
  PHONE_TEXT: "9994606736",
  GET_SUPPORT: "Get Support",
  EMAILID_TEXT: "dhinu@gmail.com",
  CUSTOMER: "CUSTOMER",
  TESTIMONIALS: " TESTIMONIALS",
  CUSTOMER_TEXT: "FUSCE IMPERDIET ULLAMCORPER FRINGILLA.",
  ABOUT_LINK_TEXT: "Start you matrimony service now.",
  WEDDING_MATRIMONY_HEADER: `Best wedding matrimony It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.`,
  WEDDING_MATRIMONY_TEXT: `There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable.`,

  // Login and Register Page
  NOW: "Now",
  LOGIN_TEXT: "Find your life partner",
  EASY_FAST: "Easy and fast",
  LOGIN_CONTEN: "Login to Vaishnava Matrimony",
  NOT_MEMBER: "Not a member ?",
  Do_Want: "Do you want to ?",
  SIGN_IN: "Sign in",
  SIGN_UP: "Sign Up",
  PASSWORD: "Password:",
  REMEMBER: "Remember me",
  FORGET_PASSWORD: "Forget Password?",
  LOGIN: "Login",

  // View profile page and stepper for profile details
  BASIC_INFO: "BASIC INFO",
  BASIC_PROFILE: "Basic profile",
  ADVANCE_BIO: "ADVANCE BIO",
  EDUCATION_EXPECTATION: "Education expections",
  ADVANCE_BIO_DETAILS: "Advance bio details",
  EXPECTATION: "EXPECTATION",
  BASIC_EXPECTATION: "Basic Expectations",
  PROFESSION: "PROFESSION",
  PROFESSION_TEXT: "Profession & Education",
  RELIGION_FAMILY: "RELIGION & FAMILY",
  RELIGION: "Religion",
  FAMILY: "Family",
  MENTOR: "Mentor",

  // Admin page
  MATRIMONY_TEXT: "Matrimony",
  MARRIAGE_BUREAU: "Vaisnava Marriage",

  // Filter
  FILTER_TEXT: "FILTER",
  APPLY: "Apply",
  NO_OPTION: "No options found",

  // Email Verification Page
  EMAIL_HEADER: "Thank You for Providing Your Email Address.",
  EMAIL_TEXT: "Please Proceed with Email Verification",

  // OtpVerification Page
  OTP_HEADER: "Thank you for registering with the Vaishnava Marriage Bureau.",
  OTP_TEXT: "An OTP has been sent to your email. Please enter the OTP.",

  // ForgetPassword page
  FORGETPASSWORD_HEADER: "Forgotten your password?",
  FORGETPASSWORD_TEXT: " Enter your email to receive a reset link.",

  // Terms&condition page
  // Privacy
  PRIVACY_HEADER: "Privacy Policy",
  PRIVACY_TEXT: `We at Vaishnava Marriage (“Vaishnava Marriage”) know that our users
            (“You”) care about how Your personal information is used and shared,
            and in these circumstances, Vaishnava Marriage takes Your privacy
            very seriously. Please read the following to learn more about
            Vaishnava Marriage's Privacy Policy. If You use the website
            https://vaisnavamarriage.com (the “Website”), features, content, or
            any other feature Vaishnava Marriage offers from time to time in
            connection therewith, You accept the practices and policies outlined
            in this Privacy Policy, and You hereby consent that Vaishnava
            Marriage will collect, use, and share Your information in the
            following ways-`,

  // Terms
  TERMS_HEADER: "Terms and Conditions",
  TERMS_TEXT: ` This page contains the Terms and Conditions which constitute a valid
            and binding agreement between Vaishnava Marriage (“Vaishnava
            Marriage”) and you (“You”), as a user, for using
            https://vaisnavamarriage.com (the “Website”). By accessing,
            browsing, and /or using this Website, You agree to be bound by the
            following terms and conditions as may be amended from time to time.
            If however, you are in disagreement with the Terms and Conditions
            laid down hereinbelow, please do not access the Website and/or
            utilize any services through this Website.`,

  // Contact Page
  CONTACT_US: "Contact Us",
  MOST_TRUSTED: "Most Trusted and premium Matrimony Service in the World.",
  OFFICE: "OUR OFFICE",
  PHONE_NUMBER: "9994606736",
  MAILID: "krayasInfothech@gmail.com",
  LOCATION: "Chettikulam Tamilnadu India",
  CUSTOMER_RELATION: "CUSTOMER RELATION",
  SUPPORT: "Get Support",
  WHATSAPP_SUPPORT: "WHATSAPP SUPPORT",
  SALES: "Talk to Sales",
  EASY: "Easy and fast",
  TALK: "LET'S TALK",
  ENQUIRY: "send your enquiry now",
  NAME: "Name:",
  EMAIL: "Email:",
  PHONE: "Phone:",
  MESSAGE: "Message",
  SEND_ENQUIRY: "SEND ENQURIY",

  //PROFILELIST
  DOB: "Date Of Birth",
  BIRTH_TIME: "Birth Time",
  BIRTH_CITY: "Birth City",
  MOTHERTONGUE: "Mother Tongue",
  EDUCATION: "Education",
  OCCUPATIONS: "Occupations",
  SPIRITUAL_STATUS: "Spiritual Status",
  CASTE: "Caste",

  //ERROR MESSAGE
  ERROR: "Unexcepted error please try again",

  //APPROVE or REJECT
  APPROVE: "approve",
  REJECT: "reject",

  //Admin Delete User
  USER_DELETE: "Are you sure to delete this user?",
  USER_UNBLOCK: "Are you sure to Unblock this user?",
  DELETE_PERMANTANTLY:
    " Deleting this user will permanently remove all associated data.",
  BLOCK_PERMANTANTLY:
    "Blocking this user will permanently restrict access to all associated data.",
  UNBLOCK_PERMANENTLY:
    "Are you sure you want to unblock this user? They will regain access to their account.",
  EDIT_USER: "Are you sure you want to edit this user's details?",

  //Testimonals
  USER_ONE: `Dear Vaishnava Marriage Bureau Members,
  I am writing this letter with a heart full of gratitude. 
  I recently found a wonderful partner through your services,
  and I wanted to express my sincere appreciation for your invaluable assistance in helping me find a suitable partner
   who shares my deep commitment to Krishna consciousness. As a practicing devotee,
    finding a life partner who shares the same spiritual values and beliefs is of utmost importance. 
    I was initially unsure about how to navigate the process of finding a compatible partner within the Vaishnava community. 
    However,from the moment I registered with your bureau,I felt supported and understood. I was particularly impressed by the 
    comprehensive questionnaire you provided. It was incredibly helpful in prompting self-reflection and clarifying what I truly sought in a life partner. 
    The questions delved into various dimensions of life, including spiritual practices, family values, personal goals, and expectations for grihastha ashram (married life). 
 `,
  USER_ONE_SPILT: `   It also cover important aspects of past, present and future mindset from both side. This thorough approach provided a valuable framework for understanding not only myself but also potential matches. 
    It facilitated meaningful conversations and helped us understand each other on a deeper level before making the commitment to enter grihastha ashram.Thanks to your dedicated efforts and understanding of the unique 
    needs of Vaishnava individuals, I was able to connect with Her Grace Brindavaneswari Keshavi Devi Dasi. We are incredibly grateful to have found each other and are excited to embark on this 
    journey of love and devotion together in Krishna consciousness. This platform provided a safe and supportive environment where I could connect with like-minded 
    individuals who are dedicated to Krishna consciousness.I highly recommend Vaishnava Marriage Bureau to other new devotees 
    to register who are seeking a compatible Krishna conscious partner to enter into Grihastha ashram. Commitment to serving the 
    Vaishnava community and facilitating meaningful connections that are rooted in faith and spirituality is truly commendable. May Krishna continue to bless 
    all the members of VMB efforts in bringing together souls who are meant to walk the path of devotion together in Krishna consciousness.`,
  USER_TWO: `Hare Krishna
All glories to Srila prabhupad.
All glories to Hari, Guru  and vaishnavas.

VMB is immensely helpful in selecting life partner within iskcon community, with special mention to contributions of Bihasya mataji. Without her tireless endeavour and guidance like mother it would have been extremely difficult for me to search a devotee lifepartner. For her magnanimous and selfless service, my wife and I are forever indebted to her.

Ys Tustatma Gourahari Das
(Initiated disciple of HH Jaypataka swami maharaj) And
Subhasini Lakshmipriya devi dasi
(Initiated disciple of HH Jaypataka Swami Maharaj) `,

  USER_Three: `Hare Krishna,We are proud to say that Vaisnava Marriage Bureau (VMB) has been an excellent platform for us to have met each other and get our grhastha life started. From the point of registering ourselves with VMB and up until getting married, all assistance provided was very valuable, prompt and accurate. We both felt safe with the way VMB dealt with our details i.e. only minimum required details were shared with prospective partner in the beginning and only after horoscope match and willingness of both parties, further
details were shared.`,
  USER_THREE_SPILT: `The questionnaire required to fill up during registration was a bit onerous, however we believe it helped VMB in filtering and providing appropriate profiles to suited us. Of all the things, we felt the prompt responses and personal attention given by the devotee at VMB were very valuable as it made us feel very comfortable and also that someone is taking our case very sincerely. With the involvement of a senior and experienced devotee in the process, and careful guidance on ensuring we do horoscope match etc. before we proceed ensured that things were not rushed into, and everything was carefully overseen.
`,
};

export default Strings;
