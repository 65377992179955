import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Strings from "../../utils/Strings";
import { authorizedDel, authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import MessageBar, {
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
  updateValueInLocalStorage,
} from "../../utils/helper";
import Spinner from "../Spinner/Spinner";
import { data } from "currency-codes";

const validate = (values) => {
  const errors = {};
  if (!values.currentPassword) {
    errors.currentPassword = "*Required";
  }
  if (!values.newPassword) {
    errors.newPassword = "*Required";
  } else if (values.newPassword.length < 6) {
    errors.newPassword = "*Password must be at least 6 characters long";
  }
  if (values.newPassword === values.currentPassword) {
    errors.newPassword =
      "*New password must be different from the current password";
  }
  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "*Passwords do not match";
  }
  return errors;
};

const Setting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
  const primaryEmail = getValueFromLocalStorage(
    localStorageKey.USERDATA,
    "primaryEmail",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [addEmail, setAddEmail] = useState("");
  const [addEmailLoading, setAddEmailLoading] = useState(false);
  const [isPrimaryEmailLoading, setPrimaryEmailIsLoading] = useState(false);
  const [isRemoveEmailLoading, setIsRemoveEmailLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setIsLoggedIn(true);
      setEmails(userData?.emails || []);
      setSelectedEmail(userData?.primaryEmail);
    }
  }, []);

  const handleEmailChange = (email) => {
    setSelectedEmail(email);
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const data = {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          userId: userId,
        };
        const res = await authorizedPost(
          configuration.apis.changePassword,
          data,
          config,
        );
        if (res?.data?.status) {
          successNotify(res?.data?.message);
          console.log(res?.data);
        } else {
          errorNotify(res?.data?.message);
        }
      } catch (error) {
        console.error("Error changing password:", error);
        setErrorMessage("An error occurred while changing password.");
      } finally {
        setIsLoading(false);
      }
    },
  });

  const validateEmail = (email) => {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleChange = (e) => {
    setError("");
    setAddEmail(e?.target?.value);
  };

  const handleAddEmail = async () => {
    try {
      if (validateEmail(addEmail)) {
        setAddEmailLoading(true);
        setError("");
        if (addEmail) {
          const data = {
            email: addEmail,
            userId: userId,
          };

          const res = await authorizedPost(
            configuration.apis.addEmail,
            data,
            config,
          );
          if (res?.data?.status) {
            successNotify(res?.data?.message);
            console.log(res?.data);
          } else {
            errorNotify(res?.data?.message);
          }
        }
      } else {
        setError("Invalid email format");
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
    } finally {
      setAddEmailLoading(false);
    }
  };

  const handleChangePrimaryEmail = async () => {
    setPrimaryEmailIsLoading(true);
    try {
      if (selectedEmail) {
        const data = {
          email: selectedEmail,
          userId: userId,
        };

        const res = await authorizedPost(
          configuration.apis.changePrimaryEmail,
          data,
          config,
        );

        if (res?.data?.status) {
          successNotify(res?.data?.message);
          updateValueInLocalStorage("userData", "primaryEmail", selectedEmail);
        } else {
          errorNotify(res?.data?.message);
        }
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
    } finally {
      setPrimaryEmailIsLoading(false);
    }
  };

  const handleRemoveEmail = async () => {
    setIsRemoveEmailLoading(true);
    try {
      if (selectedEmail) {
        const data = {
          email: selectedEmail,
          userId: userId,
        };
        console.log(data);

        const res = await authorizedDel(
          `${configuration.apis.deleteEmail}?email=${selectedEmail}&userId=${userId}`,
          config,
        );
        console.log(res?.data);

        if (res?.data?.status) {
          successNotify(res?.data?.message);
          console.log(res?.data?.data);
          updateValueInLocalStorage("userData", "emails", res?.data?.data);
          setEmails(res?.data?.data);
        } else {
          errorNotify(res?.data?.message);
        }
      }
    } catch (errorMessage) {
      errorNotify(Strings.ERROR);
    } finally {
      setIsRemoveEmailLoading(false);
    }
  };

  return (
    <div className="font-jost bg-bgcolor flex w-full flex-col shadow-md md:mt-6">
      <div className="p-6">
        <div className="mt-8 h-auto w-full flex-grow rounded-lg bg-white p-6">
          <div className="mb-6">
            <h4 className="text-xl text-gray-800">Change Password</h4>
          </div>
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            {errorMessage && (
              <span className="text-xs text-red-500">{errorMessage}</span>
            )}
            <div className="flex flex-col gap-3">
              <label className="font-medium text-black">
                Current Password:
              </label>
              <div className="relative">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  name="currentPassword"
                  onChange={formik.handleChange}
                  value={formik.values.currentPassword}
                  onBlur={formik.handleBlur}
                  className="text-md w-full rounded-md border border-gray-300 p-3 font-medium text-gray-600 focus:border-blue-500 focus:outline-none"
                />
                <button
                  type="button"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  className="absolute right-3 top-3 text-gray-500"
                >
                  {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
                {formik.errors.currentPassword && (
                  <span className="text-xs text-red-500">
                    {formik.errors.currentPassword}
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <label className="font-medium text-black">New Password:</label>
              <div className="relative">
                <input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  onChange={formik.handleChange}
                  value={formik.values.newPassword}
                  onBlur={formik.handleBlur}
                  className="text-md w-full rounded-md border border-gray-300 p-3 font-medium text-gray-600 focus:border-blue-500 focus:outline-none"
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  className="absolute right-3 top-3 text-gray-500"
                >
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
                {formik.errors.newPassword && (
                  <span className="text-xs text-red-500">
                    {formik.errors.newPassword}
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <label className="font-medium text-black">
                Confirm New Password:
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  onBlur={formik.handleBlur}
                  className="w-full rounded-md border border-gray-300 p-3 text-sm text-gray-600 focus:border-blue-500 focus:outline-none"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-3 text-gray-500"
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
                {formik.errors.confirmPassword && (
                  <span className="text-xs text-red-500">
                    {formik.errors.confirmPassword}
                  </span>
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="rounded-md bg-blue-500 px-4 py-2 text-center text-white hover:bg-blue-600"
              >
                {isLoading
                  ? isLoading && (
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    )
                  : "Change Password"}
              </button>
            </div>
          </form>

          <div className="mt-8">
            <h4 className="text-xl text-gray-800">E-mail Addresses</h4>
            <p className="mb-4 text-gray-600">
              The following e-mail addresses are associated with your account:
            </p>
            <div className="space-y-4">
              {emails.map((email, index) => (
                <div key={index} className="flex items-center gap-3">
                  <input
                    type="radio"
                    name="email"
                    value={email}
                    checked={selectedEmail === email}
                    onChange={() => handleEmailChange(email)}
                    className="h-4 w-4"
                  />
                  <label className="text-gray-700">{email}</label>
                </div>
              ))}
            </div>
            <div className="mt-6 flex flex-wrap gap-3">
              <button
                className={`rounded-md px-4 py-2 text-white  ${primaryEmail === selectedEmail ? "cursor-not-allowed bg-green-400" : "cursor-pointer bg-green-500 hover:bg-green-600"}`}
                type="button"
                disabled={primaryEmail === selectedEmail}
                onClick={handleChangePrimaryEmail}
              >
                {isPrimaryEmailLoading ? "Loading...." : " Make Primary"}
              </button>
              <button
                className="cursor-not-allowed rounded-md bg-yellow-400 px-4 py-2 text-white"
                type="button"
                disabled={true}
              >
                Re-send Verification
              </button>
              <button
                className={`rounded-md  px-4 py-2 text-white ${primaryEmail === selectedEmail ? "cursor-not-allowed bg-red-400 " : "cursor-pointer bg-red-500 hover:bg-red-600 "}`}
                type="button"
                onClick={handleRemoveEmail}
                disabled={primaryEmail === selectedEmail}
              >
                {isRemoveEmailLoading ? "Removing...." : "Remove"}
              </button>
            </div>
          </div>

          <div className="mt-10">
            <h4 className="mb-4 text-xl text-gray-800">Add E-mail Address</h4>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2">
                <label className="font-medium text-gray-600">Email:</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="focus:cfocus:outline-none rounded-md border border-gray-300 p-3 text-sm"
                  name="email"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <p className="font-poppins text-[10px] text-gray-600">
                Note: Verify your email using another device, log out, and log
                in again. The verified email will be added{" "}
              </p>
              {error && <span className="text-red-500">{error}</span>}
              <button
                className="w-[150px] rounded-md bg-green-500 px-4 py-2 text-white hover:bg-green-600"
                type="submit"
                disabled={addEmailLoading}
                onClick={handleAddEmail}
              >
                {addEmailLoading ? "Loading...." : "Add E-mail"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <MessageBar />
    </div>
  );
};

export default Setting;
