import React, { useEffect, useState } from "react";
import { authorizedDel, authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import MessageBar, {
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import Spinner from "../../Components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { maritalStatusOption } from "../../utils/data";
import Strings from "../../utils/Strings";

const Settings = ({ userData, userId, onClose }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [maritalStatus, setMaritalStatus] = useState(
    userData?.maritalStatus || "",
  );
  const [marriedLocation, setMarriedLocation] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const [showBlockConfirmation, setShowBlockConfirmation] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setIsBlocked(userData?.isBlocked);
  }, []);
  const handleDeleteUser = async () => {
    setDeleting(true);
    try {
      const res = await authorizedDel(
        `${configuration.apis.deleteUser}/${userId}`,
        config,
      );
      const response = res?.data;
      if (response?.status) {
        successNotify(response?.message);
        navigate("/matrimony");
      }
    } catch (error) {
      errorNotify(
        error?.response?.message || "An error occurred while deleting the user",
      );
    } finally {
      setDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  const handleUpdateMarital = async () => {
    setUpdating(true);
    const data = {
      status: maritalStatus,
      userId: userId,
      adminId: adminId,
      marriedViaVmb: marriedLocation === "inside" ? true : false,
    };
    console.log("data", data);
    try {
      const res = await authorizedPost(
        configuration.apis.updateMarital,
        data,
        config,
      );
      const response = res?.data;
      if (response?.status) {
        successNotify(response?.message);
        window.location.reload();
      }
    } catch (error) {
      errorNotify(
        error?.response?.message ||
          "An error occurred while updating marital status",
      );
    } finally {
      setUpdating(false);
    }
  };

  const handleBlockUser = async (status) => {
    setBlocking(true);
    // console.log("Status", status);

    const data = { status: status, userId, adminId, reason };
    // console.log("data", data);
    try {
      const res = await authorizedPost(
        configuration.apis.blockUser,
        data,
        config,
      );
      if (res?.data?.status) {
        successNotify(res?.data?.message);
        setIsBlocked(!isBlocked);
        onClose();
        setShowBlockConfirmation(false);
      } else {
        errorNotify(res?.data?.message);
        setShowBlockConfirmation(false);
      }
    } catch (error) {
      errorNotify(
        error?.response?.message || "An error occurred while blocking user",
      );
    } finally {
      setBlocking(false);
      setShowBlockConfirmation(false);
    }
  };
  const handleReasonChange = (e) => {
    const input = e.target.value;
    setReason(input);
    setError(
      input.trim().length < 15 ? "Reason must be at least 15 characters." : "",
    );
  };
  const handleBlock = () => {
    setShowBlockConfirmation(true);
  };

  const CloseButton = () => (
    <button onClick={onClose} className="rounded bg-gray-300 px-4 py-2">
      Close
    </button>
  );

  const handleModel = () => {
    if (reason.length === 0) {
      setError("Please enter the reason.");
    } else if (reason.length < 15) {
      setError("Reason must be at least 15 characters.");
    } else {
      setShowBlockConfirmation(true);
    }
  };

  return (
    <div className="font-jost fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="h-auto rounded-lg bg-white p-6 shadow-lg sm:px-4  md:w-[400px] lg:w-[400px]">
        {/* Tab navigation */}
        <div className="mb-4 flex justify-between border-b-2">
          <div
            onClick={() => setActiveTab(0)}
            className={`w-1/2 cursor-pointer py-2 text-center text-sm sm:text-base ${activeTab === 0 ? "border-b-2 border-textYellow text-textYellow " : "text-black-500"}`}
          >
            Marital Status
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className={`w-1/2 cursor-pointer py-2 text-center text-sm sm:text-base ${activeTab === 1 ? "border-b-2 border-textYellow text-textYellow " : "text-black-500"}`}
          >
            Delete Account
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className={`w-1/2 cursor-pointer py-2 text-center text-sm sm:text-base ${activeTab === 2 ? "border-b-2 border-textYellow text-textYellow " : "text-black-500"}`}
          >
            Blocked User
          </div>
        </div>

        {/* Tab content */}
        {activeTab === 0 && (
          <div>
            <label className="mb-2 block">Marital Status:</label>
            <select
              value={maritalStatus}
              onChange={(e) => setMaritalStatus(e.target.value)}
              className="mb-4 w-full rounded border border-gray-300 px-3 py-2"
            >
              <option value="" disabled>
                -- Select Marital Status --
              </option>
              {maritalStatusOption.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            {/*  if "Married" is selected */}
            {maritalStatus === "married" && (
              <div className="mb-4">
                <label className="mb-2 block">Married Through:</label>
                <select
                  value={marriedLocation}
                  onChange={(e) => setMarriedLocation(e.target.value)}
                  className="w-full rounded border border-gray-300 px-3 py-2"
                >
                  {" "}
                  <option value="" disabled>
                    -- Select Married Through --
                  </option>
                  <option value="inside">Married Within VMB</option>
                  <option value="outside">Married Outside VMB</option>
                </select>
              </div>
            )}

            <div className="flex justify-between">
              <button
                onClick={handleUpdateMarital}
                className="rounded bg-blue-500 px-4 py-2 text-white"
                disabled={updating}
              >
                {updating ? <Spinner size="small" /> : "Update"}
              </button>
              <CloseButton />
            </div>
          </div>
        )}

        {activeTab === 1 && (
          <div>
            <p className="text-center">{Strings.USER_DELETE}</p>
            <p className="mt-2 text-center text-xs text-red-600"></p>
            <div className="mt-4 flex justify-between">
              <button
                onClick={() => setShowDeleteConfirmation(true)}
                className="rounded bg-red-500 px-4 py-2 text-white"
                disabled={deleting}
              >
                {deleting ? <Spinner size="small" /> : "Delete"}
              </button>
              <CloseButton />
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div>
            {isBlocked === true ? (
              <div>
                <p className="text-center">{Strings.USER_UNBLOCK}</p>
                <div className="mt-4 flex justify-between">
                  <button
                    onClick={handleBlock}
                    className="rounded bg-blue-500 px-4 py-2 text-white"
                    disabled={blocking}
                  >
                    {blocking ? <Spinner size="small" /> : "Unblock"}
                  </button>
                  <CloseButton />
                </div>
              </div>
            ) : (
              <div>
                <label className="mb-2 block">Reason:</label>
                <textarea
                  placeholder="Enter reason..."
                  rows="3"
                  className="w-full rounded-md border border-gray-300 p-2 text-sm focus:border-blue-500"
                  value={reason}
                  onChange={handleReasonChange}
                ></textarea>
                {error && (
                  <p className="mt-2 text-left text-xs text-red-600">{error}</p>
                )}
                <p className="mt-2 text-center text-xs text-red-600"></p>
                <div className="mt-4 flex justify-between">
                  <button
                    onClick={handleModel}
                    className={`rounded px-4 py-2 text-white ${blocking ? "bg-gray-400" : "bg-red-500"}`}
                    disabled={blocking}
                  >
                    {blocking ? <Spinner size="small" /> : "Block"}
                  </button>
                  <CloseButton />
                </div>
              </div>
            )}
          </div>
        )}
        {/* Delete Confirmation Modal */}
        {showDeleteConfirmation && (
          <div className="fixed inset-0 z-50 flex w-full items-center justify-center bg-opacity-50">
            <div className="w-[90%] rounded-lg bg-white p-4 shadow-lg sm:w-[60%] md:w-[40%]">
              <p className="mb-4 text-center text-sm sm:text-base">
                {Strings.DELETE_PERMANTANTLY}
              </p>
              <div className="flex justify-around">
                <button
                  onClick={handleDeleteUser}
                  className="rounded bg-red-500 px-4 py-2 text-white"
                  disabled={deleting}
                >
                  {deleting ? <Spinner size="small" /> : "Yes, Delete"}
                </button>
                <button
                  onClick={() => setShowDeleteConfirmation(false)}
                  className="rounded bg-gray-300 px-4 py-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Block Confirmation Modal */}
        {showBlockConfirmation && (
          <div className="fixed inset-0 z-50 flex w-full items-center justify-center bg-opacity-50">
            <div className="w-[90%] rounded-lg bg-white p-4 shadow-lg sm:w-[70%] md:w-[60%]">
              <p className="mb-4 text-center text-sm sm:text-base">
                {isBlocked
                  ? Strings.UNBLOCK_PERMANENTLY
                  : Strings.BLOCK_PERMANTANTLY}
              </p>
              <div className="flex justify-around">
                {isBlocked ? (
                  <button
                    onClick={() => handleBlockUser("unblock")}
                    className={`rounded px-4 py-2 text-white ${isBlocked ? "bg-blue-500" : "bg-red-500"}`}
                    disabled={blocking}
                  >
                    {blocking ? <Spinner size="small" /> : "Yes, Unblock"}
                  </button>
                ) : (
                  <button
                    onClick={() => handleBlockUser("block")}
                    className={`rounded px-4 py-2 text-white ${isBlocked ? "bg-blue-500" : "bg-red-500"}`}
                    disabled={blocking}
                  >
                    {blocking ? <Spinner size="small" /> : "Yes, Block"}
                  </button>
                )}
                <button
                  onClick={() => setShowBlockConfirmation(false)}
                  className="rounded bg-gray-300 px-4 py-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
