// firebase.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAgopgH1AMneq0NdmkBNLNddPWRp8EZjMQ",
  authDomain: "krays-vmb.firebaseapp.com",
  projectId: "krays-vmb",
  storageBucket: "krays-vmb.appspot.com",
  messagingSenderId: "655414101687",
  appId: "1:655414101687:web:53758752a6966c8a59ef32",
  measurementId: "G-Q9BWP67VTL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);

export { storage };
