import React, { useState } from "react";
import { MdDoubleArrow } from "react-icons/md";
import { FaUserCheck, FaUserTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import {
  config,
  DOBASEURL,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import Spinner from "../Spinner/Spinner";
import Images from "../../utils/images";
import "./UserRequestCard.css";

const UserRequestCard = ({
  user,
  requestedUser,
  activeTab,
  reqId,
  setUserRequests,
  setAcceptedRequest,
  setRejectedRequest,
}) => {
  const [loadingAcceptRequestId, setLoadingAcceptRequestId] = useState(null);
  const [loadingDenyRequestId, setLoadingDenyRequestId] = useState(null);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const handleApproval = async (decision) => {
    if (decision === "approve") {
      setLoadingAcceptRequestId(reqId);
    } else {
      setLoadingDenyRequestId(reqId);
    }
    try {
      const data = {
        reqId: reqId,
        action: decision,
        comments: comment,
        adminId: adminId,
      };
      console.log(data);

      const res = await authorizedPost(
        configuration.apis.questionnaireApproval,
        data,
        config,
      );
      const response = res?.data?.data;
      console.log(response);
      if (res?.data?.status) {
        successNotify(res?.data?.message);
        const users = [];
        const accpted = [];
        const rejected = [];

        response.forEach((value) => {
          if (value?.adminApproval === "pending") {
            users.push(value);
          } else if (value?.adminApproval === "approved") {
            accpted.push(value);
          } else if (value?.adminApproval === "rejected") {
            rejected.push(value);
          }
        });

        setUserRequests(users);
        setAcceptedRequest(accpted);
        setRejectedRequest(rejected);
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (error) {
      console.log(
        "Error in approval:",
        error.response ? error.response.data : error,
      );
      errorNotify("Error processing request.");
    } finally {
      setLoadingAcceptRequestId(null);
      setLoadingDenyRequestId(null);
    }
  };

  const handleNoteChange = (event) => {
    setError(false);
    setComment(event.target.value);
  };

  const closeModal = () => {
    setComment("");
    setError(false);
    setIsCommentSectionOpen(false);
  };

  const handleDeny = () => {
    setIsCommentSectionOpen(true);
  };

  const handleSaveComment = () => {
    if (comment.length < 15) {
      setError(true);
    } else {
      handleApproval("reject");
      setIsCommentSectionOpen(false);
      setError(false);
      setComment("");
    }
  };

  return (
    <div className="flex w-full flex-col items-center justify-between gap-2 rounded-lg bg-white p-2 shadow-md md:w-[40%] xl:w-[30%]">
      <div className="flex flex-col gap-2 xl:flex-row">
        <div className="flex w-full flex-row">
          {/* User Section */}
          <div className="flex flex-col items-center">
            <Link to={`viewProfile/${requestedUser?._id}`}>
              <div className="h-20 w-20 overflow-hidden rounded-full border-4">
                <img
                  src={
                    requestedUser?.images.length > 0
                      ? DOBASEURL + requestedUser?.images[0]
                      : requestedUser?.gender === "male"
                        ? Images.MEN
                        : Images.WOMEN
                    // DOBASEURL + requestedUser?.images[0]
                  }
                  alt={requestedUser?.name}
                  className="h-full w-full cursor-pointer object-cover"
                  onContextMenu={(e) => e.preventDefault()}
                  onDragStart={(e) => e.preventDefault()}
                />
              </div>
            </Link>
            <div className="scrollbar-hide relative w-[80px] overflow-x-auto whitespace-nowrap pl-4">
              <h2 className="text-lg">{requestedUser?.name}</h2>
            </div>
          </div>

          <div className="mt-6 flex justify-center">
            <MdDoubleArrow style={{ fontSize: "40px", color: "#ffbb33" }} />
          </div>

          {/* Requested User Section */}
          <div className="flex flex-col items-center">
            <Link to={`viewProfile/${user?._id}`}>
              <div className="h-20 w-20 overflow-hidden rounded-full border-4">
                <img
                  src={
                    user?.images.length > 0
                      ? DOBASEURL + user?.images[0]
                      : user?.gender === "male"
                        ? Images.MEN
                        : Images.WOMEN
                    // DOBASEURL + user?.images[0]
                  }
                  alt={user?.name}
                  className="h-full w-full cursor-pointer object-cover"
                  onContextMenu={(e) => e.preventDefault()}
                  onDragStart={(e) => e.preventDefault()}
                />
              </div>
            </Link>
            <div className="scrollbar-hide relative w-[80px] overflow-x-auto whitespace-nowrap pl-4">
              <h2 className="text-lg">{user?.name}</h2>
            </div>
          </div>
        </div>
        {/* Accept and Reject Buttons */}
        {activeTab === 0 && (
          <div className="flex flex-row items-center justify-center gap-3 xl:flex-col">
            <button
              onClick={() => handleApproval("approve")}
              className="flex items-center justify-center rounded-full bg-green-500 px-2 py-2 text-white transition hover:bg-green-600"
              disabled={loadingAcceptRequestId === reqId}
            >
              {loadingAcceptRequestId === reqId ? <Spinner /> : <FaUserCheck />}
            </button>
            <button
              onClick={() => handleDeny("reject")}
              className="flex items-center justify-center rounded-full bg-red-500 px-2 py-2 text-white transition hover:bg-red-600"
              disabled={loadingDenyRequestId === reqId}
            >
              {loadingDenyRequestId === reqId ? <Spinner /> : <FaUserTimes />}
            </button>
          </div>
        )}
      </div>
      <p className="text-xs text-gray-400">Click profile to see full details</p>
      {isCommentSectionOpen && (
        <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-[80%] items-center  justify-center rounded-lg bg-white p-8 shadow-lg md:w-[50%]">
            <div className="flex flex-row gap-1">
              <h2 className="mb-4 text-lg font-semibold">
                Add a reason for reject
              </h2>
              <p className="text-sm font-semibold text-textRed">*</p>
            </div>
            <textarea
              className="w-full rounded border border-gray-300 p-2 "
              rows="4"
              onChange={handleNoteChange}
              placeholder="Enter your comments..."
            />
            {error && (
              <span className="mt-0 text-xs text-textRed">
                {comment.length < 15
                  ? "Comment must be at least 15 characters"
                  : "Required"}
              </span>
            )}
            <div className="mt-4 flex justify-end gap-2">
              <button
                className="rounded bg-gray-300 px-1 py-0.5 text-black md:px-4 md:py-2"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                onClick={handleSaveComment}
              >
                Send response
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserRequestCard;
