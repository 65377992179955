import { useEffect } from "react";
import Strings from "../../utils/Strings";
import Testimonials1 from "../../Assets/Testimonials1.jpeg";
import Testimonials2 from "../../Assets/gallery6.JPG";
import Testimonials3 from "../../Assets/gallery8.jpeg";
import Footer from "../../Components/Footer/Footer";

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: "Gunograhi and brindavaneswari",
      image: Testimonials1,
      text: Strings.USER_ONE,
      text1:Strings.USER_ONE_SPILT,
    },
    {
      id: 2,
      name: "Ys Tustatma Gourahari and Subhasini Lakshmipriya devi",
      image: Testimonials2,
      text: Strings.USER_TWO,
    },
    {
      id: 3,
      name: "Anantasesa Balabhadra Das and Deepapriya Gandharvika Devi Dasi",
      image: Testimonials3,
      text: Strings.USER_Three,
      text1:Strings.USER_THREE_SPILT,
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <section className="font-jost px-4 md:px-8 lg:px-16">
      <div className="py-7 pt-20 text-3xl md:text-4xl">
        <p className="text-center">Testimonials</p>
        <div className="mx-auto mt-2 w-11 border-b-2 border-yellow-400"></div>
      </div>

      <div className="flex flex-col gap-6 md:gap-8 lg:gap-10">
        {testimonials.length > 0 ? (
          testimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.id} data={testimonial} />
          ))
        ) : (
          <p className="text-center text-yellow-700">
            No testimonials available at the moment.
          </p>
        )}
      </div>
    </section>
    <div className="pt-10">
    <Footer/>
    </div>
    
    </>
  );
};

const TestimonialCard = ({ data }) => {
  return (
    <div className="flex flex-col md:flex-row items-center gap-6 rounded-lg bg-yellow-400 p-6 shadow-md">
      <div className="flex-shrink-0">
        <img
          src={data.image}
          alt={data.name}
          className="h-40 w-40 md:h-60 md:w-60 rounded-full border-4 border-yellow-100 object-cover"
        />
      </div>

      <div className="flex flex-col">
        {/* Quote */}
        <div className="relative mt-2 text-black text-justify leading-relaxed">
          <span className="absolute top-[-18px] left-[-8px] font-noto text-5xl text-yellow-800">“</span>
          <p className="text-sm md:text-base align-top  p-2 rounded">{data.text}</p>  
          <p className="text-sm md:text-base align-top  p-2 rounded">{data.text1}</p>  

          <span className="relative top-[-2px] left-[4px] font-noto text-5xl text-yellow-800">”</span>
        </div>

        <p className="text-lg md:text-xl text-yellow-800">- {data.name}</p>

      </div>
    </div>
  );
};

export default Testimonials;
