import React from "react";
import { GoDotFill } from "react-icons/go";

const InputField = ({ fieldType, isEditing, ...rest }) => {
  if (fieldType === "field1") {
    return <InputField1 {...rest} />;
  } else if (fieldType === "field2") {
    return <InputField2 isEditing={isEditing} {...rest} />;
  } else {
    // Handle invalid fieldType
    return null;
  }
};

const InputField1 = ({
  label,
  name,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  className,
  disabled,
  max,
}) => {
  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <label htmlFor={name}>{label}:</label>
      <input
        type={type}
        placeholder={placeholder}
        autoComplete="off"
        name={name}
        className="rounded-lg border border-gray-200 p-2.5 font-helvetica text-sm text-textBlack focus:outline-none"
        onChange={onChange}
        value={value}
        disabled={disabled}
        max={max}
      />
      {error && <span className="text-xs text-textRed">{error}</span>}
    </div>
  );
};

const InputField2 = ({ isEditing, label, value, type, className }) => (
  <div className={`${className} flex items-center gap-2 rounded-t-md`}>
    <GoDotFill className="text-textBlack" />
    <div className="flex-1">
      <span className="font-semibold">{label}:</span>
    </div>
    <div className="flex-1">
      {isEditing ? (
        <input
          className="text-textColor w-full rounded-lg border border-gray-200 p-2 text-xs font-bold focus:outline-none"
          type={type}
          defaultValue={value}
          name={label.toLowerCase()}
        />
      ) : (
        <span className="font-extrabold">{value}</span>
      )}
    </div>
  </div>
);

export default InputField;
