import React from "react";
import Spinner from "../../Components/Spinner/Spinner";

const ProfessionDetails = ({ userData, Loading }) => {
  if (Loading) {
    return (
      <div className="flex h-screen  items-center justify-center text-center text-lg">
        <Spinner />
      </div>
    );
  }
  if (!userData) {
    return (
      <div className="flex h-screen items-center justify-center text-center text-lg">
        No data available
      </div>
    );
  }

  const professionInfo = userData?.profile?.profession || {};
  const mentorInfo = userData?.profile?.mentor || {};

  return (
    <div className="font-jost mx-auto p-6">
      {/* Professional Information */}
      <div className="mb-2 bg-white px-4">
        <h3 className="mb-2  text-xl text-textYellow">
          Professional Information
        </h3>
        <div className="flex flex-col md:flex-row md:flex-wrap">
        <div className="w-full md:w-1/2">
          <p className="mb-2">
            <span className="font-medium">Education:</span>{" "}
            {professionInfo.education?.join(", ") || "NA"}
          </p>

          <p className="mb-2">
            <span className="font-medium">Education Detail:</span>{" "}
            {professionInfo.educationDetail || "NA"}
          </p>

          <p className="mb-2">
            <span className="font-medium">College:</span>{" "}
            {professionInfo.college || "NA"}
          </p>

          <p className="mb-2">
            <span className="font-medium">Spiritual Education:</span>{" "}
            {professionInfo.spiritualEducation?.join(", ") || "NA"}
          </p>
        </div>
        
        <div className="w-full md:w-1/2">
          <p className="mb-2">
            <span className="font-medium">Occupations:</span>{" "}
            {professionInfo.occupations?.join(", ") || "NA"}
          </p>
          <p className="mb-2">
            <span className="font-medium">Occupation Detail:</span>{" "}
            {professionInfo.occupationDetail || "NA"}
          </p>
          <p className="mb-2">
            <span className="font-medium">Organization:</span>{" "}
            {professionInfo.organization || "NA"}
          </p>
          <p className="mb-2">
            <span className="font-medium">Employed In:</span>{" "}
            {professionInfo.employedIn || "NA"}
          </p>
          <p className="mb-2">
            <span className="font-medium">Annual Income:</span>{" "}
            {professionInfo.annualIncome || "NA"}
          </p>
          <p className="mb-2">
            <span className="font-medium">Country Currency:</span>{" "}
            {professionInfo.countryCurrency || "NA"}
          </p>
        </div>
     </div>
     </div>
    </div>
  );
};

export default ProfessionDetails;
