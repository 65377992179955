import React, { useEffect, useState } from 'react'
import Spinner from '../../Components/Spinner/Spinner';
import { authorizedGet } from '../../Config/networkWithToken';
import configuration from '../../Config/configuration';
import { config } from '../../utils/helper';
import MarriedUserList from '../../Components/MarriedUserList/MarriedUserList';

const MarriedUsers = () => {
  const [marriedUsers,setMarriedUsers] = useState([]);
  const [outSideMarriedUsers,setOutSideMarriedUsers] = useState([]);
  const [loading,setLoading] = useState(false);
  const [activeTab,setActiveTab] = useState(0);

  useEffect(() => {
    getAllMarriedUsers();
  },[]);

  //Get All married User List
  const getAllMarriedUsers = async () =>{
   setLoading(true);
    try {
        const res = await authorizedGet(`${configuration.apis.getAllMarried}`,config);
        const response = res?.data?.marriedUsers;
        if (res?.data.status) {
          setMarriedUsers(response);
          const inSideUsers =[];
          const outSideUsers = [];
          response?.forEach((value) => {
            if(value?.marriedViaVmb){
              if(value?.userId?.maritalStatus === 'married'){
                    inSideUsers.push(value);
              }
            }else{
              if(value?.userId?.maritalStatus === 'married'){
                outSideUsers.push(value);
              }
            }
            
          });
          setMarriedUsers(inSideUsers);
          setOutSideMarriedUsers(outSideUsers);
        }
    } catch (error) {
        console.log(error);
    }finally{
        setLoading(false);
    }
  }

  const tabList = [
    { name: "Married InSide" },
    { name: "Married OutSide" },
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  }

  
return (
<div className="flex w-full font-jost flex-col p-4 ">
  { loading ? (
      <div className="flex items-center w-full justify-center h-screen">
            <Spinner />
      </div>
  ) : (
    <>
     <div className="flex flex-col md:flex-row gap-2 bg-bgWhite text-textGreen md:items-end items-center md:justify-between">
        <ul className="flex flex-col md:flex-row border-b border-gray-300">
          {tabList.map((list, index) => (
            <li
              key={index}
              className={`cursor-pointer px-4 py-2 text-center transition-all text-textGreen sm:text-xs md:text-base 
                  ${ activeTab === index
                  ? "border-b-2 border-textYellow text-textYellow font-semibold"
                  : "hover:text-textYellow hover:bg-gray-100"
              }`}
              onClick={() =>!loading && handleTabClick(index)}
            >
              {list.name}
            </li>
          ))}
        </ul>
        <p className='text-xs text-center text-gray-400'>Note : click user name to see full profile</p>
    </div>

  {activeTab === 0 && (
  <>
  {marriedUsers?.length > 0 ? (
    <>
      <MarriedUserList 
       marriedUsers={marriedUsers}
       />
  </>
    ) : (
   <div className="flex flex-col items-center justify-center ">
        <p className="text-center">No Married Users found!</p>
    </div>
   )}
   </>
   )}
   {activeTab === 1 && (
  <>
  {outSideMarriedUsers?.length > 0 ? (
    <>
      <MarriedUserList 
      marriedUsers={outSideMarriedUsers}
      />
   </>
    ) : (
   <div className="flex flex-col items-center justify-center ">
        <p className="text-center">No Outside Married Users found!</p>
    </div>
   )}
   </>
   )}
</>
     )}
</div>
)
}

export default MarriedUsers