import React, { useEffect, useState } from "react";
import ProfileDetails from "../ViewProfile/ProfileDetails";
import ProfessionDetails from "../ViewProfile/ProfessionDetails";
import ReligionDetails from "../ViewProfile/ReligionDetails";
import MentorDetails from "../ViewProfile/MentorDetails";
import ExpectationDetails from "../ViewProfile/ExpectationDetails";
import PhotoDetails from "../ViewProfile/PhotoDetails";
import Matches from "../ViewProfile/Matches";
import Notes from "../ViewProfile/Notes";
import { authorizedGet } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import {
  config,
  getValueFromLocalStorage,
  localStorageKey,
} from "../../utils/helper";
import { GiCancel, GiLovers } from "react-icons/gi";
import { SiTicktick } from "react-icons/si";
import { FaRegPenToSquare } from "react-icons/fa6";

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [userData, setUserData] = useState(null);
  // const localStorageUserData = JSON.parse(localStorage.getItem(localStorageKey.USERDATA));
  const userRole = getValueFromLocalStorage(localStorageKey.USERDATA, "role");
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");

  useEffect(() => {
    // fetchSingleUser();
    const localStorageData = localStorage.getItem(localStorageKey.USERDATA);

    const parsedData = JSON.parse(localStorageData);
    if (parsedData) {
      setUserData(parsedData);
    } else {
      console.log("No user data found in local storage.");
    }
  }, []);

  const tabList = [
    "Profile",
    "Profession",
    "Religion & Family",
    "Mentor",
    "Photo",
    "Expectation",
    ...(userRole === "admin" ? ["Matches"] : []),
    ...(userRole === "admin" ? ["Notes"] : []),
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const data = [
    {
      icon: <GiLovers />,
      head: "Profiles",
      users: "2,500",
      bgColor: "bg-gradient-to-r from-teal-400 to-teal-700",
    },
    {
      icon: <SiTicktick />,
      head: "Verified",
      users: "2,000",
      bgColor: "bg-gradient-to-r from-rose-400 to-rose-700",
    },
    {
      icon: <GiLovers />,
      head: "Married",
      users: "200",
      bgColor: "bg-gradient-to-r from-green-400 to-green-600",
    },
    {
      icon: <FaRegPenToSquare />,
      head: "Pending",
      users: "500",
      bgColor: "bg-gradient-to-r from-yellow-400 to-yellow-600",
    },
  ];

  return (
    <div className="mt-12 flex min-h-screen w-full justify-center bg-bgColor">
      <div className="flex w-full  flex-col" style={{ maxWidth: "1400px" }}>
        <div className="font-jost mx-auto flex w-[100%] flex-row flex-wrap items-center justify-center gap-2 px-3 md:pt-8 lg:flex-nowrap">
          {data.map((details, index) => (
            <div
              key={index}
              className={`mt-6 flex h-32 w-[45%] transform flex-col items-center justify-center rounded-lg p-4 md:mt-0  ${details.bgColor} border border-white/30 text-white`}
            >
              <div className="flex items-center justify-center p-2 text-4xl">
                {details.icon}
              </div>
              <h1 className="text-lg ">{details.head}</h1>
              <p className="text-xl ">{details.users}</p>
            </div>
          ))}
        </div>

        <div className="mt-4 flex w-full flex-col px-4">
          <div className="h-auto w-full flex-grow overflow-auto rounded-lg bg-white p-4 shadow-md">
            <div className="font-jost flex gap-2 text-textGreen">
              <ul className="flex w-full flex-wrap gap-3 border-b-[0.5px]">
                {tabList.map((list, index) => (
                  <li
                    key={index}
                    className={`playFair cursor-pointer p-3 text-center transition-colors duration-300 ${
                      activeTab === index
                        ? "playFair border-b-2 border-textYellow text-textYellow after:bg-textYellow"
                        : "text-textGreen"
                    }`}
                    onClick={() => handleTabClick(index)}
                  >
                    {list}
                  </li>
                ))}
              </ul>
            </div>

            {activeTab === 0 && <ProfileDetails userData={userData} />}
            {activeTab === 1 && <ProfessionDetails userData={userData} />}
            {activeTab === 2 && <ReligionDetails userData={userData} />}
            {activeTab === 3 && <MentorDetails userData={userData} />}
            {activeTab === 4 && <PhotoDetails userData={userData} />}
            {activeTab === 5 && <ExpectationDetails userData={userData} />}
            {userRole === "admin" && activeTab === 6 && (
              <Matches gender={userData?.gender} />
            )}
            {userRole === "admin" && activeTab === 7 && <Notes />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
