import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  authorizedGet,
  authorizedPost,
} from "../../../Config/networkWithToken";
import configuration from "../../../Config/configuration";
import MessageBar, {
  config,
  DOBASEURL,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../../utils/helper";
import Spinner from "../../../Components/Spinner/Spinner";
import Strings from "../../../utils/Strings";
import { MdDoubleArrow } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa6";
import { FaUserTimes } from "react-icons/fa";
import "./UserRequest.css";
import Images from "../../../utils/images";

const RequestCards = ({
  request,
  activeTab,
  index,
  adminId,
  setUserRequestData,
  setAcceptedRequest,
  setRejectedRequest,
}) => {
  const [loadingAcceptRequestId, setLoadingAcceptRequestId] = useState(null);
  const [loadingDenyRequestId, setLoadingDenyRequestId] = useState(null);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const [requestedId, setRequestedId] = useState(null);

  //Admin Request Approval
  const handleApproval = async (decision, requestId) => {
    try {
      if (decision === "approve") {
        setLoadingAcceptRequestId(requestId);
      } else {
        setLoadingDenyRequestId(requestId);
      }

      const data = {
        adminId: adminId,
        requestId: requestId,
        action: decision === "approve" ? Strings.APPROVE : Strings.REJECT,
        comments: comment,
      };

       //console.log(data);

      const res = await authorizedPost(
        configuration.apis.adminApproval,
        data,
        config,
      );
      const response = res?.data?.data;
      if (res?.data?.status) {
        successNotify(res?.data?.message);
        const pending = [];
        const accepted = [];
        const rejected = [];
        response.forEach((value) => {
          if (value?.requestStatus === "Pending") {
            pending.push(value);
          } else if (value?.requestStatus === "Accepted") {
            accepted.push(value);
          } else if (value?.requestStatus === "Rejected") {
            rejected.push(value);
          }
        });
        setUserRequestData(pending);
        setAcceptedRequest(accepted);
        setRejectedRequest(rejected);
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
      // console.log(error);
    } finally {
      setLoadingAcceptRequestId(null);
      setLoadingDenyRequestId(null);
    }
  };

  const handleNoteChange = (event) => {
    setError(false);
    setComment(event.target.value);
  };

  const closeModal = () => {
    setComment("");
    setError(false);
    setIsCommentSectionOpen(false);
  };

  const handleAction = (requestedId, decision) => {
    setRequestedId(requestedId);
    if (decision === "approve") {
      handleApproval(decision, requestedId);
    } else {
      setIsCommentSectionOpen(true);
    }
  };

  const handleSaveComment = () => {
    if (comment.length < 15) {
      setError(true);
    } else {
      handleApproval("reject", requestedId);
      setIsCommentSectionOpen(false);
      setError(false);
      setComment("");
    }
  };



  return (
    <div
      key={index}
      className="font-jost relative flex w-full flex-col items-center gap-2 rounded-xl bg-white p-2 shadow-md md:w-[30%] md:justify-around"
    >
      <div className="flex w-full flex-col items-center gap-2 p-2 md:justify-around xl:flex-row">
        <div className="flex flex-row justify-around">
          {/* Requested User's Profile */}
          <div className="flex flex-col items-center text-center">
            <Link
              to={`/request-detail/${request?.requestedUser?._id}`}
            >
              <img
                src={
                  request?.requestedUser?.images.length > 0
                    ? DOBASEURL + request?.requestedUser?.images[0]
                    : request?.requestedUser?.gender === "male"
                      ? Images.MEN
                      : Images.WOMEN
                  // DOBASEURL + request?.requestedUser?.images[0]
                }
                className="h-20 w-20 cursor-pointer rounded-full border-4 border-yellow-200"
                alt="Requested User"
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
              />
            </Link>
            <span className="scrollbar-hide relative w-[80px] overflow-x-auto whitespace-nowrap text-gray-700">
              {request?.requestedUser?.name}
            </span>
          </div>
          <div className="flex">
            <MdDoubleArrow
              className="aria-hidden:true mt-5"
              style={{ fontSize: "40px", color: "#ffbb33" }}
            />
          </div>
          {/* User Profile */}
          <div className="flex flex-col items-center text-center">
            <Link to={`/request-detail/${request?.user?._id}`}>
              <img
                src={
                  request?.user?.images.length > 0
                    ? DOBASEURL + request?.user?.images[0]
                    : request?.user?.gender === "male"
                      ? Images.MEN
                      : Images.WOMEN
                  // DOBASEURL + request?.user?.images[0]
                }
                className="h-20 w-20 cursor-pointer rounded-full border-4 border-yellow-200"
                alt="User"
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
              />
            </Link>
            <span className="scrollbar-hide relative w-[80px] overflow-x-auto whitespace-nowrap text-gray-700">
              {request?.user?.name}
            </span>
          </div>
        </div>
        {/* Accept and Deny Buttons */}
        {activeTab === 0 && (
          <div className="flex w-full flex-row items-center justify-center gap-3 lg:w-[20%] xl:flex-col">
            <button
              className={`right-24 top-4 rounded-full bg-green-500 px-2 py-2 text-xs text-white ${loadingAcceptRequestId === request._id ? "cursor-not-allowed opacity-50" : ""}`}
              onClick={() => handleAction(request?._id, "approve")}
              disabled={loadingAcceptRequestId === request?._id}
            >
              {loadingAcceptRequestId === request?._id ? (
                <Spinner />
              ) : (
                <FaUserCheck />
              )}
            </button>
            <button
              className={`right-4 top-4 rounded-full bg-red-500 px-2 py-2 text-xs text-white ${loadingDenyRequestId === request._id ? "cursor-not-allowed opacity-50" : ""}`}
              onClick={() => handleAction(request?._id, "reject")}
              disabled={loadingDenyRequestId === request?._id}
            >
              {loadingDenyRequestId === request?._id ? (
                <Spinner />
              ) : (
                <FaUserTimes />
              )}
            </button>
          </div>
        )}
      </div>
      <p className="text-xs text-gray-400">Click profile to see full details</p>
      {isCommentSectionOpen && (
        <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-[80%] md:w-[50%] items-center justify-center rounded-lg bg-white p-8 shadow-lg">
            <div className="flex flex-row gap-1">
              <h2 className="mb-4 text-lg font-semibold">
                Add a reason for reject
              </h2>
              <p className="text-sm font-semibold text-textRed">*</p>
            </div>
            <textarea
              className="w-full rounded border border-gray-300 p-2 "
              rows="4"
              onChange={handleNoteChange}
              placeholder="Enter your comments..."
            />
            {error && (
              <span className="mt-0 text-xs text-textRed">
                {comment.length < 15
                  ? "Comment must be at least 15 characters"
                  : "Required"}
              </span>
            )}
            <div className="mt-4 flex justify-end gap-2">
              <button
                className="rounded bg-gray-300 px-1 py-0.5 text-black md:px-4 md:py-2"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                onClick={handleSaveComment}
              >
                Send response
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UserRequest = () => {
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const [userRequestData, setUserRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [acceptedRequest, setAcceptedRequest] = useState([]);
  const [rejectedRequest, setRejectedRequest] = useState([]);
  useEffect(() => {
    getUserRequest();
  }, []);

  //Get all the Requests
  const getUserRequest = async () => {
    setLoading(true);
    try {
      const res = await authorizedGet(
        `${configuration.apis.adminApprovalPending}?adminId=${adminId}`,
        config,
      );
      const response = res?.data?.data;
      if (res?.data?.status) {
        const pending = [];
        const accepted = [];
        const rejected = [];
        response.forEach((value) => {
          if (value?.requestStatus === "Pending") {
            pending.push(value);
          } else if (value?.requestStatus === "Accepted") {
            accepted.push(value);
          } else if (value?.requestStatus === "Rejected") {
            rejected.push(value);
          }
        });
        setUserRequestData(pending);
        setAcceptedRequest(accepted);
        setRejectedRequest(rejected);
      } else {
        errorNotify(response?.message);
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabList = [
    { name: "User Requests" },
    { name: "Accepted Requests" },
    { name: "Rejected Requests" },
  ];
  return (
    <div className="font-jost relative ml-4 h-auto rounded-lg p-2 ">
      <ul className="flex flex-col border-b border-gray-300 md:flex-row">
        {tabList.map((list, index) => (
          <li
            key={index}
            className={`cursor-pointer px-4 py-2 text-center text-textGreen transition-all sm:text-xs md:text-base 
                    ${
                      activeTab === index
                        ? "border-b-2 border-textYellow text-textYellow "
                        : "hover:bg-gray-100 hover:text-textYellow"
                    }`}
            onClick={() => handleTabClick(index)}
          >
            {list.name}
          </li>
        ))}
      </ul>

      {activeTab === 0 && (
        <>
          {loading ? (
            <div className="flex min-h-screen flex-col items-center justify-center">
              <Spinner />
            </div>
          ) : userRequestData.length > 0 ? (
            <div className="flex flex-wrap gap-4 p-2">
              {userRequestData.map((item, index) => (
                <RequestCards
                  key={index}
                  request={item}
                  activeTab={activeTab}
                  index={index}
                  adminId={adminId}
                  setUserRequestData={setUserRequestData}
                  setAcceptedRequest={setAcceptedRequest}
                  setRejectedRequest={setRejectedRequest}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center p-2 ">
              <h4 className="text-black  ">No User Request Available.</h4>
            </div>
          )}
        </>
      )}
      {activeTab === 1 && (
        <>
          {acceptedRequest.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-2 ">
              <h4 className="  text-black">No Accpted Request Available.</h4>
            </div>
          ) : (
            <div className="flex flex-wrap gap-4 p-2">
              {acceptedRequest.map((item, index) => (
                <RequestCards
                  key={index}
                  request={item}
                  activeTab={activeTab}
                  index={index}
                  adminId={adminId}
                  setUserRequestData={setUserRequestData}
                  setAcceptedRequest={setAcceptedRequest}
                  setRejectedRequest={setRejectedRequest}
                />
              ))}
            </div>
          )}
        </>
      )}

      {activeTab === 2 && (
        <>
          {rejectedRequest.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-2 ">
              <h4 className="  text-black">No Rejected Request Available.</h4>
            </div>
          ) : (
            <div className="flex flex-wrap gap-4 p-2">
              {rejectedRequest.map((item, index) => (
                <RequestCards
                  key={index}
                  request={item}
                  activeTab={activeTab}
                  index={index}
                  adminId={adminId}
                  setUserRequestData={setUserRequestData}
                  setAcceptedRequest={setAcceptedRequest}
                  setRejectedRequest={setRejectedRequest}
                />
              ))}
            </div>
          )}
        </>
      )}
      <MessageBar />
    </div>
  );
};

export default UserRequest;
