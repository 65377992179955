import React, { useEffect, useState, useRef } from "react";
import "./hero.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaFacebook, FaPhoneAlt, FaLinkedin } from "react-icons/fa";
import { AiFillInstagram, AiFillTwitterCircle } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import logo from "../../Assets/logo.png";
import vmblogo from "../../Assets/vmblogo.png";
import aboutImg from "../../Assets/about.jfif";
import one from "../../Assets/one.png";
import two from "../../Assets/two.png";
import three from "../../Assets/three.png";
import four from "../../Assets/four.png";

// testimonials images
import female from "../../Assets/profile.jpeg";
import female1 from "../../Assets/profile.jpg";
// testimonials images
import Testimonials1 from "../../Assets/Testimonials1.jpeg";
import Testimonials2 from "../../Assets/Testimonials2.jpeg";
import Testimonials3 from "../../Assets/Testimonials3.jpeg";
import { getValueFromLocalStorage, localStorageKey } from "../../utils/helper";
import Strings from "../../utils/Strings";
import Footer from "../../Components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { FaHandshake, FaPrayingHands } from "react-icons/fa";
import { TbShieldCheckFilled } from "react-icons/tb";
import { GiKeyCard } from "react-icons/gi";
import gallery1 from "../../Assets/gallery1.jpg";
import gallery2 from "../../Assets/gallery2.jpg";
import gallery3 from "../../Assets/gallery3.jpg";
import gallery4 from "../../Assets/gallery4.JPG";
import gallery5 from "../../Assets/gallery5.JPG";
import gallery6 from "../../Assets/gallery6.JPG";
import gallery7 from "../../Assets/gallery7.jpg";

const Home = () => {
  // Dhinu
  const [currentPath, setCurrentPath] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setCurrentPath(location.pathname);
    const userData = localStorage.getItem("userData");
    setUserId(getValueFromLocalStorage(localStorageKey.USERDATA, "_id"));
    setRole(getValueFromLocalStorage(localStorageKey.USERDATA, "role"));

    if (userData) {
      setIsLoggedIn(true);
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("userData");
    setIsLoggedIn(false);
    window.location.reload();
  };
  const features = [
    {
      icon: (
        <div className="rounded-full bg-yellow-500 p-4">
          <FaPrayingHands className="text-2xl text-white" />
        </div>
      ),
      title: "Spiritually Aligned Matches",
      description:
        "Our platform focuses on spiritual compatibility, ensuring matches that  foster a deep commitment to Krishna Consciousness",
    },
    {
      icon: (
        <div className="rounded-full bg-yellow-500 p-4">
          <TbShieldCheckFilled className="text-2xl text-white" />
        </div>
      ),
      title: "Genuine and Verified Profiles",
      description:
        "We help to connect you to genuine like minded devotees and try to ensure the profiles  are genuine and authentic.",
    },
    {
      icon: (
        <div className="rounded-full bg-yellow-500 p-4">
          <FaHandshake className="text-2xl text-white" />
        </div>
      ),
      title: "Community-Centered Support",
      description:
        "We offer a supportive community with resources, guidance, and advice to help you find and nurture meaningful relationships.",
    },
    {
      icon: (
        <div className="rounded-full bg-yellow-500 p-4">
          <GiKeyCard className="text-2xl text-white" />
        </div>
      ),
      title: "Privacy and Security You Can Trust",
      description:
        "Your data is protected with advanced security measures, ensuring your privacy and giving you control over your information.",
    },
  ];
  const images = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    Testimonials1,
    // "https://i.pinimg.com/736x/b9/f1/6b/b9f16b7f0f02599cff04cae207aa865e.jpg",
    // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc5eVCcLpBdQKZ4L8GCe7gvu2sgRMuj_PGh0DzEGxewdtM7XXtvjCKzJDVf-9WrkHiraU&usqp=CAU",
    // "https://d397bfy4gvgcdm.cloudfront.net/184861-5-12-18-Tejal-Davis-Wedding-Hilton-Baltimore-MD-2500.jpeg",
    // "https://jyothimatrimony.com/assets/story/1553513695660044638.jpg",
    // "https://i.pinimg.com/474x/c6/1d/04/c61d047d0945e0cb70f141b75bf84f3e.jpg",
    // "https://d397bfy4gvgcdm.cloudfront.net/173155-NithilaSanjogWedding-18941.jpeg",
    // "https://d397bfy4gvgcdm.cloudfront.net/173171-NithilaSanjogWedding-20172.jpeg",
    // "https://images.click.in/classifieds/images/38/09_01_2020_13_23_56_b15fb3641b5ee63f1975b5930a130d5a_tydq7fxjri.jpg",
    // "https://i.pinimg.com/736x/2a/27/b7/2a27b70ed666b1414707949876f14694.jpg",
    // "https://i.pinimg.com/474x/6d/29/06/6d290641b387afda3b19e773aaba2192.jpg",
    // "https://d397bfy4gvgcdm.cloudfront.net/173144-NithilaSanjogWedding-18644.jpeg",
  ];
  const testimonials = [
    {
      id: 1,
      name: "Gunograhi and brindavaneswari",
      image: Testimonials1,
      text: Strings.USER_ONE,
      text1: Strings.USER_ONE_SPILT,
    },
    {
      id: 2,
      name: "Ys Tustatma Gourahari and Subhasini Lakshmipriya devi",
      image: Testimonials2,
      text: Strings.USER_TWO,
    },
    {
      id: 3,
      name: "Anantasesa Balabhadra Das and Deepapriya Gandharvika Devi Dasi",
      image: Testimonials3,
      text: Strings.USER_Three,
      text1: Strings.USER_THREE_SPILT,
    },
  ];

  const navigate = useNavigate();

  const handleReadMore = () => {
    navigate("/testimonials");
  };
  return (
    <div className="landingPage ">
      <section className="hero mt-16">
        <div className="topBar flex justify-between px-10 pb-[10px] pt-24">
          <div className="contactInfo flex items-center gap-5">
            {/* <ul className="hidden items-center gap-2 md:flex">
              <li>
                <FaFacebook className="h-[13px]" />
              </li>
              <li>
                <AiFillInstagram className="h-[15px]" />
              </li>
              <li>
                <FaLinkedin className="h-[15px] " />
              </li>
              <li>
                <AiFillTwitterCircle className="h-[15px] " />
              </li>
            </ul>
            <ul className="flex flex-col gap-0 md:flex-row md:gap-2">
              <li className="flex items-center gap-1">
                <FaPhoneAlt className="h-[10px]" />
                <span className="font-jost text-[13px] font-normal text-gray-700">
                +91 9647089832 
                </span>
              </li>
              <li className="flex items-center gap-1">
                <MdEmail className="h-[11px] " />
                <span className="font-jost text-[13px] font-normal text-gray-700">
                vaisnavamarriage@gmail.com
                </span>
              </li>
            </ul> */}
          </div>
          {/* <div className="authBtn font-jost flex items-center gap-2">
  {isLoggedIn ? (
    <>
      <span
        className="cursor-pointer font-normal text-gray-700"
        onClick={handleLogout}
      >
        Log Out
      </span>
    </>
  ) : (
    <>
      <span className="cursor-pointer font-normal text-black">
        <Link to={"/login"}>Log In</Link>
      </span>
      |
      <span className="cursor-pointer font-normal text-black">
        <Link to={"/register"}>Sign Up</Link>
      </span>
    </>
  )}
</div> */}

          {/* <div className="authBtn font-jost flex items-center gap-2">
            {isLoggedIn ? (
              <>
                <span className="cursor-pointer font-normal text-gray-700">
                  {role !== "user" ? (
                    <Link to={"/matrimony"}>Dashboard</Link>
                  ) : (
                    <Link to={`/profile/${userId}`}>Profile</Link>
                  )}
                </span>
                |
                <span
                  className="cursor-pointer font-normal text-gray-700"
                  onClick={handleLogout}
                >
                  Log Out
                </span>
                <span className="cursor-pointer font-normal text-gray-700">
                  <Link to={"/login"}>Log In</Link>
                </span>
                |
                <span className="cursor-pointer font-normal text-gray-700">
                  <Link to={"/register"}>Sign Up</Link>
                </span>
              </>
            ) : (
              <>
                <span className="cursor-pointer font-normal  text-black">
                  <Link to={"/login"}>Log In</Link>
                </span>
                |
                <span className="cursor-pointer font-normal  text-black">
                  <Link to={"/register"}>Sign Up</Link>
                </span>
              </>
            )}
          </div> */}
        </div>

        {/* NavBar Section */}
        {/* Hero Section */}

        <div className="heroContent items-center ">
          <div className="flex flex-col gap-3">
            <p className="font-jost p-3 text-2xl text-gray-800 md:w-[35%]">
              " I encourage all my diksa disciples, siksa disciples and others
              who seek my spiritual help to register at this site if they are
              looking to find a Krishna Conscious spouse."
            </p>
            <p className="font-jost text-xl text-gray-700">
              -His Holiness Jayapataka Swami
            </p>
            {/* <p className="font-jost w-[400px] text-left text-xl">
              Join the largest community of Vaishnavas seeking sacred unions
              rooted in dharma and devotion. Together, let's build families
              centered on love, respect, and spiritual growth.
            </p> */}
          </div>
          <Link to="/register">
            <button className="font-jost mt-5 rounded-md  bg-red-600 p-3 text-white">
              Register Now
            </button>
          </Link>
        </div>
      </section>
      <section>
        <div className="mb-12 pt-6 text-center">
          <h2 className="font-jost text-3xl text-gray-800 md:text-4xl">
            Why Us
          </h2>
          <div className="mx-auto mt-4 w-16 border-b-4 border-yellow-500"></div>
        </div>

        <div className="mx-auto flex flex-wrap justify-center gap-6 px-6 lg:justify-between lg:px-10">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex w-full flex-col items-center rounded-xl border border-yellow-500 bg-white px-6 py-8 text-center shadow-md sm:w-[48%] lg:w-[22%]"
            >
              {feature.icon}
              <h3 className="font-jost mt-4 text-2xl text-gray-800">
                {feature.title}
              </h3>
              <p className="font-jost mt-3 text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/*WhyUs Section*/}

      {/* <section className="py-10" id="whyus">
        <div className="font-jost  text-3xl">
          <p className="text-center">Why Us</p>
          <div className="mx-auto w-11 border-b-2 border-yellow-400 text-center" />
        </div>

        <div className="mx-auto flex w-[98%] flex-col items-center justify-around gap-5 md:flex-row md:flex-wrap lg:flex-nowrap">
          <div className="flex flex-col items-center text-center sm:w-3/4 md:w-1/4 ">
            <img src={one} alt="one" className="w-[100px]" />
            <p className="font-jost"> Spiritually Aligned Matches</p>
            <p>
              Our platform focuses on spiritual compatibility, ensuring matches
              that share a deep commitment to bhakti and Vaishnava values.
            </p>
          </div>
          <div className=" flex flex-col items-center text-center sm:w-3/4 md:w-1/4">
            <img src={two} alt="one" className="w-[100px]" />
            <p className="font-jost"> Genuine and Verified Profiles</p>
            <p>
              We rigorously verify every profile to ensure authenticity,
              allowing you to connect with genuine, like-minded devotees
              confidently.
            </p>
          </div>
          <div className=" flex flex-col items-center text-center sm:w-3/4 md:w-1/4">
            <img src={three} alt="one" className="w-[100px]" />
            <p className="font-jost">Community-Centered Support</p>
            <p>
              We offer a supportive community with resources, guidance, and
              advice to help you find and nurture meaningful relationships.
            </p>
          </div>
          <div className=" flex flex-col items-center text-center sm:w-3/4 md:w-1/4">
            <img src={four} alt="one" className="w-[100px]" />
            <p className="font-jost">Privacy and Security You Can Trust</p>
            <p>
              Your data is protected with advanced security measures, ensuring
              your privacy and giving you control over your information.
            </p>
          </div>
        </div>
      </section> */}
      {/*Gallery Section*/}
      {/* <section className="mx-auto w-[95%] py-2" id="gallery">
        <div className="font-jost py-7  text-3xl">
          <p className="text-center">Gallery</p>
          <div className="mx-auto w-11 border-b-2 border-yellow-400 text-center" />
        </div>

        <div className="grid grid-cols-2 gap-4 pt-5 md:grid-cols-4">
          <div className="grid gap-4">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://i.pinimg.com/736x/b9/f1/6b/b9f16b7f0f02599cff04cae207aa865e.jpg"
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc5eVCcLpBdQKZ4L8GCe7gvu2sgRMuj_PGh0DzEGxewdtM7XXtvjCKzJDVf-9WrkHiraU&usqp=CAU"
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://d397bfy4gvgcdm.cloudfront.net/184861-5-12-18-Tejal-Davis-Wedding-Hilton-Baltimore-MD-2500.jpeg"
                alt=""
              />
            </div>
          </div>
          <div className="grid gap-4">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://jyothimatrimony.com/assets/story/1553513695660044638.jpg"
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://i.pinimg.com/474x/c6/1d/04/c61d047d0945e0cb70f141b75bf84f3e.jpg"
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://d397bfy4gvgcdm.cloudfront.net/173155-NithilaSanjogWedding-18941.jpeg"
                alt=""
              />
            </div>
          </div>
          <div className="grid gap-4">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://d397bfy4gvgcdm.cloudfront.net/173171-NithilaSanjogWedding-20172.jpeg"
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://images.click.in/classifieds/images/38/09_01_2020_13_23_56_b15fb3641b5ee63f1975b5930a130d5a_tydq7fxjri.jpg"
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src={gallery}
                alt=""
              />
            </div>
          </div>
          <div className="grid gap-4">
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://i.pinimg.com/736x/2a/27/b7/2a27b70ed666b1414707949876f14694.jpg"
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://i.pinimg.com/474x/6d/29/06/6d290641b387afda3b19e773aaba2192.jpg"
                alt=""
              />
            </div>
            <div>
              <img
                className="h-auto max-w-full rounded-lg"
                src="https://d397bfy4gvgcdm.cloudfront.net/173144-NithilaSanjogWedding-18644.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}
      <section className="mx-auto w-[90%] py-6" id="gallery">
        <div className="font-jost py-7 text-3xl">
          <p className="text-center">Gallery</p>
          <div className="mx-auto mt-4 w-16 border-b-4 border-yellow-500"></div>
        </div>

        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{
            clickable: true,
            el: ".custom-pagination",
          }}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          loop={true}
          slidesPerView={3}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            320: { slidesPerView: 1 },
          }}
          className="custom-swiper pt-5"
        >
          {images.map((src, index) => (
            <SwiperSlide key={index} className="custom-slide">
              <img
                className="h-[580px] w-[350px] rounded-lg object-cover"
                src={src}
                alt={`Gallery image ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="custom-pagination mt-4 flex justify-center"></div>
      </section>
      {/*Testimonials Section*/}

      {/* <section className="font-jost py-10" >
  <div className="py-2 text-3xl">
    <p className="text-center">Testimonials</p>
    <div className="mx-auto w-16 border-b-4 border-yellow-500 mt-4"></div>
  </div>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 sm:px-8 md:px-12 py-4">
    {testimonials.map((testimonial) => (
      <div
        key={testimonial.id}
        className="testimonialCard w-full max-w-xs sm:max-w-sm md:max-w-md mx-auto rounded-lg bg-yellow-400 p-6 shadow-lg"
      >
        <div className="flex flex-col items-center">
          <img
            src={testimonial.image}
            alt={testimonial.name}
            className="h-[110px] w-[110px] rounded-full border-4 border-white/80"
          />
          <p className="mt-2 text-center text-lg text-yellow-800">{testimonial.name}</p>
        </div>
        <p className="mt-4 text-justify text-yellow-800">
          {testimonial.text.substring(0, 100)}...
          <button
            onClick={() => handleReadMore(testimonial.id)}
            className="text-sm text-blue-500 hover:underline"
          >
            Read More
          </button>
        </p>
      </div>
    ))}
  </div>
</section> */}
      <div className="mt-10"></div>
      <Footer />
    </div>
  );
};

export default Home;
