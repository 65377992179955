import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Images from "../../utils/images";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FAQs = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "What is a Vaishnava marriage website?",
      answer:
        "A Vaishnava marriage website is a matchmaking platform designed specifically for ISKCON devotees ",
    },
    {
      question: "Who can register on this website?",
      answer: "This platform is open to for all practicing ISKCON devotees",
    },
    {
      question: "Is my personal information secure?",
      answer:
        "Yes, we follow strict data privacy policies and do not share your information with third parties without consent.",
    },
    {
      question: "Do you support international matchmaking?",
      answer:
        "Yes, we welcome Vaishnavas from all over the world to connect and find their ideal partners.",
    },
    {
      question: "Can parents create a profile for their children?",
      answer:
        "Yes, parents can create and manage profiles on behalf of their children.",
    },
    {
      question: "Is there an age restriction for registration?",
      answer:
        "Yes, users must be at least 21 years old to register on the platform.",
    },
  ];

  return (
    <div className="font-jost flex min-h-screen flex-col">
      <div className="from-borderGray flex flex-grow flex-col items-center bg-gradient-to-b to-white py-12 md:flex-row-reverse">
        <div className="w-full max-w-3xl px-6 pt-14">
          <div className="font-jost text-3xl ">
            <p className="text-center text-xl sm:text-2xl lg:text-3xl">
              Frequently Asked Questions
            </p>
            <div className="mx-auto mt-2 w-40 border-b-2 border-yellow-400 text-center"></div>
          </div>
          <div className="h-[400px] space-y-6 overflow-y-auto p-2 py-10">
            {faqData.map((faq, index) => (
              <div key={index} className="border-b pb-2">
                <div
                  className="flex cursor-pointer items-center justify-between rounded-lg p-2 transition-all duration-300 hover:bg-gray-200"
                  onClick={() => toggleQuestion(index)}
                >
                  <h2 className="text-textDarkBlue text-[18px]">
                    {faq.question}
                  </h2>
                  <div>
                    {openQuestion === index ? (
                      <FaChevronUp className="text-primary transition-transform duration-300" />
                    ) : (
                      <FaChevronDown className="text-primary transition-transform duration-300" />
                    )}
                  </div>
                </div>
                {openQuestion === index && (
                  <p className="px-2 text-[14px] text-gray-700">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center md:w-1/2">
          <img
            src={Images.FAQS.default}
            alt="FAQ Image"
            className="h-auto w-full"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQs;
