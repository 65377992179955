import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import tick from "../../../Assets/tickImg.png";
import {
  getValueFromLocalStorage,
  localStorageKey,
} from "../../../utils/helper";

const Complete = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
  const userRole = getValueFromLocalStorage(localStorageKey.USERDATA, "role");

  const handleOk = () => {
    setLoading(true);
    if (userRole === "admin") {
      navigate(`/matrimony`);
    } else {
      setTimeout(() => {
        navigate(`/profile/${userId}`);
      }, 1000);
    }
  };

  return (
    <div className="font-helvetica z-50 mt-6 flex min-h-screen w-full justify-center p-8">
      <div className="popup-content rounded-lg text-center">
        <img src={tick} className="mx-auto h-[100px] w-[100px]" alt="Tick" />
        <h2 className="mb-4 text-2xl ">Thank You!</h2>
        <p className="mb-8 text-xl">
          Your profile details have been completed successfully
        </p>
        <button
          type="button"
          className="ok-button rounded bg-textYellow px-4 py-2  text-white"
          onClick={handleOk}
          disabled={loading} // Disable button while loading
        >
          {loading ? <Spinner /> : "OK"}
        </button>
      </div>
    </div>
  );
};

export default Complete;
