import { countries, languages } from "countries-list";
import { codes } from "currency-codes";
// Register Page Details

export const genderOption = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];
export const maritalOption = [
  { label: "Never Married", value: "never married" },
  { label: "Divorced", value: "divorced" },
  { label: "Widowed", value: "widowed" },
  { label: "Widower", value: "widower" },
];
export const profileOption = [
  { name: "Self", value: "self" },
  { name: "Admin", value: "admin" },
  { name: "Parent", value: "parent" },
  { name: "Sibling", value: "sibling" },
  { name: "Conselor", value: "conselor" },
  { name: "Friend", value: "friend" },
];
// Edit Profile Page Details

export const maritalStatusOption = [
  { label: "Never Married", value: "never married" },
  { label: "Married", value: "married" },
  { label: "Divorced", value: "divorced" },
  { label: "Widowed", value: "widowed" },
  { label: "Widower", value: "widower" },
];
export const languagesOptions = Object.keys(languages).map((code) => ({
  value: languages[code].name,
  label: languages[code].name,
}));

export const expectLanguagesOptions = [
  { value: "Any", label: "Any" }, // Add this as the first option
  ...Object.keys(languages).map((code) => ({
    value: languages[code].name,
    label: languages[code].name,
  })),
];

const currencyData = codes();

export const currencyOptions = currencyData.map((currencyCode) => {
  return {
    value: currencyCode,
    label: currencyCode,
  };
});

export const spiritualStatusOptions = [
  { label: "Aspiring", value: "Aspiring" },
  { label: "Shelter", value: "Shelter" },
  { label: "Harinam", value: "Harinam" },
  { label: "Brahmin", value: "Brahmin" },
  { label: "Favorable-Following", value: "Favorable-Following" },
  {
    label: "Favorable for Krishna consciousness",
    value: "Favorable for Krishna consciousness",
  },
];

export const connectedTempleOptions = [
  { label: "Yatra", value: "Yatra" },
  { label: "Namahatta", value: "Namahatta" },
  { label: "Temple", value: "Temple" },
];

export const spiritualMasterOptions = [
  { label: "HH.Atmanivedana Swami", value: "HH.Atmanivedana Swami" },
  {
    label: "HH.Bhakti Bhrnga Govinda Swami",
    value: "HH.Bhakti Bhrnga Govinda Swami",
  },
  { label: "HH.Bhakti-bhusana Swami", value: "HH.Bhakti-bhusana Swami" },
  { label: "HH.Bhakti-Caitanya Swami", value: "HH.Bhakti-Caitanya Swami" },
  { label: "HH.Bhakti-Charu Swami", value: "HH.Bhakti-Charu Swami" },
  {
    label: "HH.Bhakti Dhira Damodara Swami",
    value: "HH.Bhakti Dhira Damodara Swami",
  },
  {
    label: "HH.Bhakti-Gaurava Narayan Swami",
    value: "HH.Bhakti-Gaurava Narayan Swami",
  },
  {
    label: "HH.Bhakti-Gauravani Goswami",
    value: "HH.Bhakti-Gauravani Goswami",
  },
  { label: "HH.Bhaktimarga Swami", value: "HH.Bhaktimarga Swami" },
  {
    label: "HH.Bhakti Prabhupada-vrata Damodara Swami",
    value: "HH.Bhakti Prabhupada-vrata Damodara Swami",
  },
  { label: "HH.Bhakti Raghava Swami", value: "HH.Bhakti Raghava Swami" },
  { label: "HH.Bhakti Sundar Goswami", value: "HH.Bhakti Sundar Goswami" },
  { label: "HH.Bhaktivaibhava Swami", value: "HH.Bhaktivaibhava Swami" },
  { label: "HH.Bhakti Vijnana Goswami", value: "HH.Bhakti Vijnana Goswami" },
  { label: "HH.Bhakti Vikasa Swami", value: "HH.Bhakti Vikasa Swami" },
  {
    label: "HH.Bhakti VV Narasimha Swami",
    value: "HH.Bhakti VV Narasimha Swami",
  },
  {
    label: "HH.Bhaktivyasa Tirtha Swami",
    value: "HH.Bhaktivyasa Tirtha Swami",
  },
  { label: "HH.Bhurijana Das", value: "HH.Bhurijana Das" },
  { label: "HH.Bir Krsna Das Goswami", value: "HH.Bir Krsna Das Goswami" },
  { label: "HH.Bhakti-Charu Swami", value: "HH.Bhakti-Charu Swami" },
  {
    label: "HH.Caitanya Candra Caran Das",
    value: "HH.Caitanya Candra Caran Das",
  },
  { label: "HH.Caitanya Candra Das", value: "HH.Caitanya Candra Das" },
  { label: "HH.Candramauli Swami", value: "HH.Candramauli Swami" },
  { label: "HH.Candra Mukha Swami", value: "HH.Candra Mukha Swami" },
  { label: "HH.Caru Das", value: "HH.Caru Das" },
  { label: "HH.Danavir Goswami", value: "HH.Danavir Goswami" },
  { label: "HH.Devamrita Swami", value: "HH.Devamrita Swami" },
  { label: "HH.Dhanvantari Swami", value: "HH.Dhanvantari Swami" },
  { label: "HH.Dhirasanta Das Goswami", value: "HH.Dhirasanta Das Goswami" },
  { label: "HH.Drutakarma Das", value: "HH.Drutakarma Das" },
  { label: "HH.Giridhari Swami", value: "HH.Giridhari Swami" },
  { label: "HH.Giriraja Swami", value: "HH.Giriraja Swami" },
  { label: "HH.Gopal Krsna Goswami", value: "HH.Gopal Krsna Goswami" },
  { label: "HH.Gopaswami Das", value: "HH.Gopaswami Das" },
  { label: "HH.Guru Prasad Swami", value: "HH.Guru Prasad Swami" },
  { label: "HH.Hanumatpresaka Swami", value: "HH.Hanumatpresaka Swami" },
  { label: "HH.Harivilas Das", value: "HH.Harivilas Das" },
  {
    label: "HH.Hrdayananda dasa Goswami",
    value: "HH.Hrdayananda dasa Goswami",
  },
  { label: "HH.Indradyumna Swami", value: "HH.Indradyumna Swami" },
  { label: "HH.Janananda dasa Goswami", value: "HH.Janananda dasa Goswami" },
  { label: "HH.Jayapataka Swami", value: "HH.Jayapataka Swami" },
  { label: "HH.Jivananda Das", value: "HH.Jivananda Das" },
  { label: "HH.Kadamba Kanana Swami", value: "HH.Kadamba Kanana Swami" },
  { label: "HH.Kalakantha Das", value: "HH.Kalakantha Das" },
  { label: "HH.Kavicandra Swami", value: "HH.Kavicandra Swami" },
  {
    label: "HH.Kesava Bharati dasa Goswami",
    value: "HH.Kesava Bharati dasa Goswami",
  },
  { label: "HH.Kratu Das", value: "HH.Kratu Das" },
  { label: "HH.Kripamoya Das", value: "HH.Kripamoya Das" },
  { label: "HH.Krsna Ksetra Swami", value: "HH.Krsna Ksetra Swami" },
  { label: "HH.Lokanath Swami", value: "HH.Lokanath Swami" },
  { label: "HH.Madhu Sevita Das", value: "HH.Madhu Sevita Das" },
  { label: "HH.Mahadyuti Swami", value: "HH.Mahadyuti Swami" },
  { label: "HH.Mahaman Das", value: "HH.Mahaman Das" },
  { label: "HH.Mahatma Das", value: "HH.Mahatma Das" },
  { label: "HH.Mahavisnu Swami", value: "HH.Mahavisnu Swami" },
  { label: "HH.Manonatha Das", value: "HH.Manonatha Das" },
  { label: "HH.Matsya Avatara Das", value: "HH.Matsya Avatara Das" },
  { label: "HH.Medhavi Das", value: "HH.Medhavi Das" },
  { label: "HH.Nanda Kumar Das", value: "HH.Nanda Kumar Das" },
  { label: "HH.Navayogendra Swami", value: "HH.Navayogendra Swami" },
  { label: "HH.Niranjana Swami", value: "HH.Niranjana Swami" },
  {
    label: "HH.Partha Sarathi Das Goswami",
    value: "HH.Partha Sarathi Das Goswami",
  },
  { label: "HH.Prahladananda Swami", value: "HH.Prahladananda Swami" },
  { label: "HH.Purushatraya Swami", value: "HH.Purushatraya Swami" },
  { label: "HH.Radha Govinda Swami", value: "HH.Radha Govinda Swami" },
  { label: "HH.Radhanath Swami", value: "HH.Radhanath Swami" },
  { label: "HH.Rama Govinda Swami", value: "HH.Rama Govinda Swami" },
  { label: "HH.Ravindra Svarupa Das", value: "HH.Ravindra Svarupa Das" },
  { label: "HH.Romapada Swami", value: "HH.Romapada Swami" },
  { label: "HH.Rtadhvaja Swami", value: "HH.Rtadhvaja Swami" },
  { label: "HH.Sacinandana Swami", value: "HH.Sacinandana Swami" },
  { label: "HH.Samik Rsi Das", value: "HH.Samik Rsi Das" },
  { label: "HH.Sankarsana Das", value: "HH.Sankarsana Das" },
  { label: "HH.Satyadeva Das", value: "HH.Satyadeva Das" },
  { label: "HH.Sivarama Swami", value: "HH.Sivarama Swami" },
  { label: "HH.Smita Krsna Swami", value: "HH.Smita Krsna Swami" },
  { label: "HH.Subhaga Swami", value: "HH.Subhaga Swami" },
  { label: "HH.Suresvara Das", value: "HH.Suresvara Das" },
  { label: "HH.Trivikrama Swami", value: "HH.Trivikrama Swami" },
  { label: "HH.Vaisesika Das", value: "HH.Vaisesika Das" },
  { label: "HH.Varsana Swami", value: "HH.Varsana Swami" },
  { label: "HH.Vedavyasapriya Swami", value: "HH.Vedavyasapriya Swami" },
  { label: "HH.Virabahu Das", value: "HH.Virabahu Das" },
  { label: "Others within ISKCON", value: "Others within ISKCON" },
];

export const bodyTypeOptions = [
  { label: "Slim", value: "Slim" },
  { label: "Average", value: "Average" },
  { label: "Athlete", value: "Athlete" },
  { label: "Heavy", value: "Heavy" },
];

export const complexionOptions = [
  { label: "Fair", value: "Fair" },
  { label: "Very Fair", value: "Very Fair" },
  { label: "Wheatish", value: "Wheatish" },
  { label: "Wheatish Brown", value: "Wheatish Brown" },
  { label: "Dark", value: "Dark" },
];

export const countryOptions = Object.keys(countries).map((countryCode) => ({
  value: countries[countryCode].name,
  label: countries[countryCode].name,
}));

export const wantChildrenOption = [
  { label: "Yes", value: "Yes" },
  { label: "May be", value: "May be" },
  { label: "No", value: "No" },
];

export const occupationOption = [
  {
    label: "Temple residence devotee/Temple depended devotee",
    value: "Temple residence devotee/Temple depended devotee",
  },
  {
    label: "Accounts/Finance Professional",
    value: "Accounts/Finance Professional",
  },
  { label: "Actor/Model", value: "Actor/Model" },
  {
    label: "Administrative Professional",
    value: "Administrative Professional",
  },
  {
    label: "Advertising / PR Professional",
    value: "Advertising / PR Professional",
  },
  { label: "Agent/Broker/Trader", value: "Agent/Broker/Trader" },
  {
    label: "Agriculture & Farming Professional",
    value: "Agriculture & Farming Professional",
  },
  { label: "Air Force", value: "Air Force" },
  {
    label: "Air Hostess/Flight Attendant",
    value: "Air Hostess/Flight Attendant",
  },
  { label: "Airline Professional", value: "Airline Professional" },
  { label: "Analyst", value: "Analyst" },
  { label: "Animator", value: "Animator" },
  { label: "Architect", value: "Architect" },
  { label: "Army", value: "Army" },
  { label: "Artist", value: "Artist" },
  { label: "Arts & Craftsman", value: "Arts & Craftsman" },
  { label: "Associate", value: "Associate" },
  { label: "Auditor", value: "Auditor" },
  { label: "Banking Professional", value: "Banking Professional" },
  { label: "Beautician", value: "Beautician" },
  {
    label: "BPO / KPO / ITES Professional",
    value: "BPO / KPO / ITES Professional",
  },
  { label: "Builder", value: "Builder" },
  {
    label: "Business (Medical & healthcare)",
    value: "Business (Medical & healthcare)",
  },
  {
    label: "Business Development Professional",
    value: "Business Development Professional",
  },
  {
    label: "Business Owner / Entrepreneur",
    value: "Business Owner / Entrepreneur",
  },
  { label: "Chartered Accountant", value: "Chartered Accountant" },
  { label: "Chef / Cook", value: "Chef / Cook" },
  { label: "Chemist", value: "Chemist" },
  { label: "Chemical Engineer", value: "Chemical Engineer" },
  { label: "Civil Engineer", value: "Civil Engineer" },
  {
    label: "Civil Services (IAS / IPS / IRS / IES / IFS)",
    value: "Civil Services (IAS / IPS / IRS / IES / IFS)",
  },
  { label: "Clerk", value: "Clerk" },
  { label: "CNC Operator", value: "CNC Operator" },
  { label: "Company Secretary", value: "Company Secretary" },
  { label: "Consultant", value: "Consultant" },
  { label: "Content Writer", value: "Content Writer" },
  { label: "Contractor", value: "Contractor" },
  { label: "Corporate Communication", value: "Corporate Communication" },
  { label: "Corporate Planning", value: "Corporate Planning" },
  {
    label: "Customer Service Professional",
    value: "Customer Service Professional",
  },
  {
    label: "CXO / President, Director, Chairman",
    value: "CXO / President, Director, Chairman",
  },
  { label: "Cyber / Network Security", value: "Cyber / Network Security" },
  { label: "Data Analyst", value: "Data Analyst" },
  { label: "Data Scientist", value: "Data Scientist" },
  { label: "Defence Services (Others)", value: "Defence Services (Others)" },
  { label: "Dentist", value: "Dentist" },
  { label: "Dietician / Nutritionist", value: "Dietician / Nutritionist" },
  { label: "Distributor", value: "Distributor" },
  { label: "Doctor", value: "Doctor" },
  { label: "Driver", value: "Driver" },
  { label: "Education Professional", value: "Education Professional" },
  { label: "Electrical Engineer", value: "Electrical Engineer" },
  {
    label: "Electronics / Telecom Engineer",
    value: "Electronics / Telecom Engineer",
  },
  { label: "Engineer - Non IT", value: "Engineer - Non IT" },
  { label: "Engineer (Computer)", value: "Computer Engineer" },
  { label: "Entertainment Professional", value: "Entertainment Professional" },
  {
    label: "Event Management Professional",
    value: "Event Management Professional",
  },
  { label: "Executive", value: "Executive" },
  { label: "Fashion Designer", value: "Fashion Designer" },
  { label: "Financial Accountant", value: "Financial Accountant" },
  {
    label: "Financial Analyst / Planning",
    value: "Financial Analyst / Planning",
  },
  { label: "Fitness Professional", value: "Fitness Professional" },
  { label: "Freelancer", value: "Freelancer" },
  { label: "Full time service (ISKCON)", value: "Full time service (ISKCON)" },
  { label: "Hair Stylist", value: "Hair Stylist" },
  { label: "Hardware Professional", value: "Hardware Professional" },
  { label: "Healthcare Professional", value: "Healthcare Professional" },
  { label: "Homemaker", value: "Homemaker" },
  { label: "Horticulturist", value: "Horticulturist" },
  {
    label: "Hotel / Hospitality Professional",
    value: "Hotel / Hospitality Professional",
  },
  {
    label: "Human Resources Professional",
    value: "Human Resources Professional",
  },
  { label: "Interior Designer", value: "Interior Designer" },
  { label: "Investment Professional", value: "Investment Professional" },
  { label: "Jewellery Designer", value: "Jewellery Designer" },
  { label: "Journalist", value: "Journalist" },
  { label: "Lab Technician", value: "Lab Technician" },
  { label: "Law Enforcement Officer", value: "Law Enforcement Officer" },
  {
    label: "Lawyer & Legal Professional",
    value: "Lawyer & Legal Professional",
  },
  { label: "Legal Assistant", value: "Legal Assistant" },
  { label: "Librarian", value: "Librarian" },
  { label: "Makeup Artist", value: "Makeup Artist" },
  { label: "Manager", value: "Manager" },
  { label: "Mariner / Merchant Navy", value: "Mariner / Merchant Navy" },
  { label: "Marketing Professional", value: "Marketing Professional" },
  { label: "Mechanic", value: "Mechanic" },
  {
    label: "Mechanical / Production Engineer",
    value: "Mechanical / Production Engineer",
  },
  { label: "Media Professional", value: "Media Professional" },
  { label: "Medical Representative", value: "Medical Representative" },
  { label: "Medical Transcriptionist", value: "Medical Transcriptionist" },
  { label: "Musician", value: "Musician" },
  { label: "Navy", value: "Navy" },
  { label: "Network Engineer", value: "Network Engineer" },
  { label: "Nurse", value: "Nurse" },
  { label: "Officer", value: "Officer" },
  { label: "Operations Management", value: "Operations Management" },
  { label: "Others", value: "Others" },
  { label: "Paramedical Professional", value: "Paramedical Professional" },
  { label: "Paramilitary", value: "Paramilitary" },
  { label: "Part time service (ISKCON)", value: "Part time service (ISKCON)" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Photo / Videographer", value: "Photo / Videographer" },
  { label: "Physiotherapist", value: "Physiotherapist" },
  { label: "Pilot", value: "Pilot" },
  { label: "Police", value: "Police" },
  { label: "Politician", value: "Politician" },
  { label: "Product Manager", value: "Product Manager" },
  { label: "Product Manager - Non IT", value: "Product Manager - Non IT" },
  { label: "Professor / Lecturer", value: "Professor / Lecturer" },
  { label: "Program Manager", value: "Program Manager" },
  { label: "Project Manager", value: "Project Manager" },
  { label: "Project Manager - Non IT", value: "Project Manager - Non IT" },
  { label: "Psychologist", value: "Psychologist" },
  { label: "Quality Assurance Engineer", value: "Quality Assurance Engineer" },
  {
    label: "Quality Assurance Engineer - Non IT",
    value: "Quality Assurance Engineer - Non IT",
  },
  { label: "Research Assistant", value: "Research Assistant" },
  { label: "Research Scholar", value: "Research Scholar" },
  {
    label: "Restaurant / Catering Professional",
    value: "Restaurant / Catering Professional",
  },
  { label: "Retired", value: "Retired" },
  { label: "Sailor", value: "Sailor" },
  { label: "Sales Professional", value: "Sales Professional" },
  { label: "Scientist / Researcher", value: "Scientist / Researcher" },
  { label: "Secretary / Front Office", value: "Secretary / Front Office" },
  { label: "Security Professional", value: "Security Professional" },
  { label: "Senior Manager / Manager", value: "Senior Manager / Manager" },
  { label: "Singer", value: "Singer" },
  {
    label: "Social Worker / Volunteer / NGO",
    value: "Social Worker / Volunteer / NGO",
  },
  { label: "Software Consultant", value: "Software Consultant" },
  { label: "Software Professional", value: "Software Professional" },
  { label: "Sportsperson", value: "Sportsperson" },
  { label: "Subject Matter Expert", value: "Subject Matter Expert" },
  { label: "Supervisor", value: "Supervisor" },
  { label: "Surgeon", value: "Surgeon" },
  { label: "Surveyor", value: "Surveyor" },
  { label: "Tailor", value: "Tailor" },
  { label: "Teacher", value: "Teacher" },
  { label: "Teacher / Tutor", value: "Teacher / Tutor" },
  { label: "Technician", value: "Technician" },
  { label: "Telecom Professional", value: "Telecom Professional" },
  {
    label: "Tourism / Travel Professional",
    value: "Tourism / Travel Professional",
  },
  { label: "Trainer", value: "Trainer" },
  {
    label: "Transport / Logistics Professional",
    value: "Transport / Logistics Professional",
  },
  { label: "Truck Driver", value: "Truck Driver" },
  { label: "UI / UX Designer", value: "UI / UX Designer" },
  { label: "User Interface Designer", value: "User Interface Designer" },
  { label: "Vastu Consultant", value: "Vastu Consultant" },
  { label: "Veterinarian", value: "Veterinarian" },
  { label: "Volunteer", value: "Volunteer" },
  { label: "Web Designer", value: "Web Designer" },
  { label: "Web Developer", value: "Web Developer" },
  { label: "Writer", value: "Writer" },
  { label: "Not Working", value: "Not Working" },
];

export const employedInOption = [
  { label: "Government", value: "Government" },
  { label: "Private Sector", value: "Private Sector" },
  { label: "Self Employed", value: "Self Employed" },
  { label: "Public Sector Undertaking", value: "Public Sector Undertaking" },
  { label: "Business", value: "Business" },
  { label: "Defence", value: "Defence" },
  {
    label: "Non-Governmental Organization",
    value: "Non-Governmental Organization",
  },
  { label: "Agriculture", value: "Agriculture" },
  { label: "Not Working", value: "Not Working" },
];

export const educationOption = [
  { label: "Aeronautical Engineering", value: "Aeronautical Engineering" },
  { label: "Aviation Degree", value: "Aviation Degree" },
  { label: "B.A.", value: "B.A." },
  { label: "B.A.M.S.", value: "B.A.M.S." },
  { label: "B.Arch", value: "B.Arch" },
  { label: "BBA", value: "BBA" },
  { label: "BCA", value: "BCA" },
  { label: "B.Com.", value: "B.Com." },
  { label: "BDS", value: "BDS" },
  { label: "B.E", value: "B.E" },
  { label: "B.Ed.", value: "B.Ed." },
  { label: "BFA", value: "BFA" },
  { label: "BFM (Financial Management)", value: "BFM (Financial Management)" },
  { label: "BFT", value: "BFT" },
  { label: "BGL", value: "BGL" },
  {
    label: "BHA / BHM (Hospital Administration",
    value: "BHA / BHM (Hospital Administration",
  },
  { label: "Bhakti Shastri (ISKCON)", value: "Bhakti Shastri (ISKCON)" },
  {
    label: "Bhakti Shastri Teachers Training (ISKCON)",
    value: "Bhakti Shastri Teachers Training (ISKCON)",
  },
  { label: "Bhakti Vaibhav (ISKCON)", value: "Bhakti Vaibhav (ISKCON)" },
  { label: "Bhakti Vedanta (ISKCON)", value: "Bhakti Vedanta (ISKCON)" },
  { label: "BHM (Hotel Management)", value: "BHM (Hotel Management)" },
  { label: "BHMS", value: "BHMS" },
  { label: "B.L.", value: "B.L." },
  { label: "BLIS", vaalue: "BLIS" },
  { label: "B.M.M.", value: "B.M.M." },
  { label: "BPharm", value: "BPharm" },
  { label: "B.Phil.", value: "B.Phil." },
  { label: "B.Plan", value: "B.Plan" },
  { label: "BPT", value: "BPT" },
  { label: "B.Sc.", value: "B.Sc." },
  { label: "B.Sc IT/ Computer Science", value: "B.Sc IT/ Computer Science" },
  { label: "B.Sc. Nursing", value: "B.Sc. Nursing" },
  { label: "B.S.(Engineering)", value: "B.S.(Engineering" },
  { label: "BSMS", value: "BSMS" },
  { label: "B.S.W", value: "B.S.W" },
  { label: "B.Tech.", value: "B.Tech." },
  { label: "BUMS", value: "BUMS" },
  { label: "BVSc", value: "BVSc" },
  {
    label: "CFA (Chartered Financial Analyst)",
    value: "CFA (Chartered Financial Analyst)",
  },
  { label: "CS", value: "CS" },
  { label: "Diploma", value: "Diploma" },
  {
    label: "Graduate (Any Bachelors in Arts / Science / Commerce)",
    value: "Graduate (Any Bachelors in Arts / Science / Commerce)",
  },
  {
    label: "Higher Secondary School / High School",
    value: "Higher Secondary School / High School",
  },
  { label: "IAS", value: "IAS" },
  { label: "ICWA", value: "ICWA" },
  { label: "IES", value: "IES" },
  { label: "IPS", value: "IPS" },
  { label: "IRS", value: "IRS" },
  {
    label: "ISKCON Disciple Course (ISKCON)",
    value: "ISKCON Disciple Course (ISKCON)",
  },
  { label: "LL.B.", value: "LL.B." },
  { label: "LL.M.", value: "LL.M." },
  { label: "M.A.", value: "M.A." },
  { label: "M.Arch.", value: "M.Arch." },
  { label: "MBA", value: "MBA" },
  { label: "MBBS", value: "MBBS" },
  { label: "MCA", value: "MCA" },
  { label: "M.Com", value: "M.Com" },
  { label: "MD / MS (Medical)", value: "MD / MS (Medical)" },
  { label: "MDS", value: "MDS" },
  { label: "M.E", value: "M.E" },
  { label: "M.Ed.", value: "M.Ed." },
  { label: "MFA", value: "MFA" },
  { label: "MFM (Financial Management)", value: "MFM (Financial Management)" },
  {
    label: "MHA / MHM (Hospital Administration)",
    value: "MHA / MHM (Hospital Administration)",
  },
  { label: "MHM  (Hotel Management)", value: "MHM  (Hotel Management)" },
  {
    label: "MHRM (Human Resource Management)",
    value: "MHRM (Human Resource Management)",
  },
  { label: "M.L.", value: "M.L." },
  { label: "MLIS", value: "MLIS" },
  { label: "M.Pharm", value: "M.Pharm" },
  { label: "M.Phil.", value: "M.Phil." },
  { label: "MPT", value: "MPT" },
  { label: "M.Sc.", value: "M.Sc." },
  {
    label: "M.Sc. IT / Computer Science",
    value: "M.Sc. IT / Computer Science",
  },
  { label: "M.S.(Engg.)", value: "M.S.(Engg.)" },
  { label: "MSW", value: "MSW" },
  { label: "M.Tech.", value: "M.Tech." },
  { label: "MVSc", value: "MVSc" },
  {
    label: "Other Bachelor Degree in Arts / Science / Commerce",
    value: "Other Bachelor Degree in Arts / Science / Commerce",
  },
  {
    label: "Other Bachelor Degree in Engineering / Computers",
    value: "Other Bachelor Degree in Engineering / Computers",
  },
  {
    label: "Other Bachelor Degree in Legal",
    value: "Other Bachelor Degree in Legal",
  },
  {
    label: "Other Bachelor Degree in Management",
    value: "Other Bachelor Degree in Management",
  },
  {
    label: "Other Bachelor Degree in Medicine",
    value: "Other Bachelor Degree in Medicine",
  },
  { label: "Other Degree in Finance", value: "Other Degree in Finance" },
  { label: "Other Degree in Service", value: "Other Degree in Service" },
  {
    label: "Other Master Degree in Arts / Science / Commerce",
    value: "Other Master Degree in Arts / Science / Commerce",
  },
  {
    label: "Other Master Degree in  Legal",
    value: "Other Master Degree in  Legal",
  },
  {
    label: "Other Master Degree in Management",
    value: "Other Master Degree in Management",
  },
  {
    label: "Other Master Degree in Medicine",
    value: "Other Master Degree in Medicine",
  },
  {
    label: "Other Masters Degree in Engineering / Computers",
    value: "Other Masters Degree in Engineering / Computers",
  },
  { label: "Others in Diploma", value: "Others in Diploma" },
  { label: "PGDCA", value: "PGDCA" },
  { label: "PGDM", value: "PGDM" },
  { label: "Ph.D.", value: "Ph.D." },
  { label: "Polytechnic", value: "Polytechnic" },
  { label: "Trade School", value: "Trade School" },
];

export const spiritualeducation = [
  { label: "Bhakti Shastri (ISKCON)", value: "Bhakti Shastri (ISKCON)" },
  {
    label: "Bhakti Shastri Teachers Training (ISKCON)",
    value: "Bhakti Shastri Teachers Training (ISKCON)",
  },
  { label: "Bhakti Vaibhav (ISKCON)", value: "Bhakti Vaibhav (ISKCON)" },
  { label: "Bhakti Vedanta (ISKCON)", value: "Bhakti Vedanta (ISKCON)" },
  {
    label: "ISKCON Disciple Course (ISKCON)",
    value: "ISKCON Disciple Course (ISKCON)",
  },
];

export const religionOption = [
  { label: "Devotee By Birth", value: "Devotee By Birth" },
  { label: "Buddhist", value: "Buddhist" },
  { label: "Christian", value: "Christian" },
  { label: "Hindu", value: "Hindu" },
  { label: "Inter-Religion", value: "Inter-Religion" },
  { label: "Jain", value: "Jain" },
  { label: "Jewish", value: "Jewish" },
  { label: "Muslim", value: "Muslim" },
  { label: "Parsi", value: "Parsi" },
  { label: "Sikh", value: "Sikh" },
];

export const casteOption = [
  { label: "Brahmin", value: "Brahmin" },
  // { label: "Devotee", value: "Devotee" },
  { label: "Kshatriya", value: "Kshatriya" },
  { label: "Shudra", value: "Shudra" },
  { label: "Vaishya", value: "Vaishya" },
];

export const expectedCasteOption = [
  { label: "Brahmin", value: "Brahmin" },
  { label: "Devotee", value: "Devotee" },
  { label: "Kshatriya", value: "Kshatriya" },
  { label: "Shudra", value: "Shudra" },
  { label: "Vaishya", value: "Vaishya" },
];
export const filterSubCasteOption = [
  { label: "Iyer", value: "Iyer" },
  { label: "Iyengar", value: "Iyengar" },
  { label: "Brahmin Kanyakubj", value: "Brahmin Kanyakubj" },
  { label: "Gaudiya", value: "Gaudiya" },
  { label: "ISKCON", value: "ISKCON" },
  { label: "Vaishnav", value: "Vaishnav" },
  { label: "Rajput", value: "Rajput" },
  { label: "Kshatriya", value: "Kshatriya" },
  { label: "Bania", value: "Bania" },
  { label: "Modh", value: "Modh" },
  { label: "Mali", value: "Mali" },
  { label: "Kumbhar", value: "Kumbhar" },
];
export const subcasteOption = {
  Brahmin: [
    { label: "Iyer", value: "Iyer" },
    { label: "Iyengar", value: "Iyengar" },
    { label: "Brahmin Kanyakubj", value: "Brahmin Kanyakubj" },
  ],
  Devotee: [
    { label: "Gaudiya", value: "Gaudiya" },
    { label: "ISKCON", value: "ISKCON" },
    { label: "Vaishnav", value: "Vaishnav" },
  ],
  Kshatriya: [
    { label: "Rajput", value: "Rajput" },
    { label: "Kshatriya", value: "Kshatriya" },
  ],
  Shudra: [
    { label: "Mali", value: "Mali" },
    { label: "Kumbhar", value: "Kumbhar" },
  ],
  Vaishya: [
    { label: "Bania", value: "Bania" },
    { label: "Modh", value: "Modh" },
  ],
};

export const manglikOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "I Don't Know", value: "N/A" },
];

export const birthTimeUnknownOption = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "I Don't Know", value: "N/A" },
];

export const parentsDevoteesOption = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  { label: "Only Father", value: "Only Father" },
  { label: "Only Mother", value: "Only Mother" },
];

export const siblingsDevoteesOption = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const parentsStatusOption = [
  { label: "Favorable", value: "Favorable" },
  { label: "Unfavorable", value: "Unfavorable" },
  { label: "Initiated", value: "Initiated" },
  { label: "Practicing", value: "Practicing" },
];

export const familyValuesOption = [
  { label: "Orthodox", value: "Orthodox" },
  { label: "Traditonal", value: "Traditonal" },
  { label: "Moderate", value: "Moderate" },
  { label: "Liberal", value: "Liberal" },
];

export const familyTypeOption = [
  { label: "Joint Family", value: "Joint Family" },
  { label: "Nuclear Family", value: "Nuclear Family" },
  { label: "others", value: "others" },
];

export const familyStatusOption = [
  { label: "Lower Class", value: "Lower Class" },
  { label: "Upper Class", value: "Upper Class" },
  { label: "Middle Class", value: "Middle Class" },
  { label: "Upper Middle Class", value: "Upper Middle Class" },
  { label: "Afflunet", value: "Afflunet" },
];
export const fatherStatusOption = [
  { label: "Employed", value: "Employed" },
  { label: "Business", value: "Business" },
  { label: "Professional", value: "Professional" },
  { label: "Retired", value: "Retired" },
  { label: "Not Employed", value: "Not Employed" },
  { label: "Deceased", value: "Deceased" },
];

export const motherStatusOption = [
  { label: "Employed", value: "Employed" },
  { label: "Business", value: "Business" },
  { label: "Professional", value: "Professional" },
  { label: "Retired", value: "Retired" },
  { label: "Home Maker", value: "Home Maker" },
  { label: "Deceased", value: "Deceased" },
];

export const familyLocationOption = [
  { label: "Same as my Location", value: "Same as my Location" },
  { label: "Different Location", value: "Different Location" },
];

export const wantNRIOption = [
  { label: "Yes", value: "Yes" },
  { label: "May be", value: "May be" },
  { label: "No", value: "No" },
];

export const countOfMantraOptions = [
  // {label:"Not a Chanting",value:"Not a Chanting"},
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
];

export const ItemsPerPages = 10;
