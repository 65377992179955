import React from "react";
import Select from "react-select";
import { GoDotFill } from "react-icons/go";

const SelectField = ({ selectType, isEditing, item, ...rest }) => {
  if (selectType === "field1") {
    return <SelectField1 {...rest} />;
  } else if (selectType === "field2") {
    return <SelectField2 isEditing={isEditing} item={item} {...rest} />;
  } else {
    // Handle invalid selectType
    return null;
  }
};

const SelectField1 = ({
  label,
  name,
  options = [], // Default to an empty array if options are not provided
  value = "", // Default value to handle undefined
  onChange,
  onBlur,
  error,
  placeholder,
  isMulti = false,
  className = "", // Default to an empty string if className is not provided
}) => {
  const validOptions = Array.isArray(options) ? options : [];

  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <label htmlFor={name}>{label}:</label>
      <Select
        id={name}
        name={name}
        placeholder={placeholder}
        options={validOptions}
        isMulti={isMulti}
        onChange={(selectedOption) => {
          const newValue = isMulti
            ? selectedOption.map((option) => option.value) 
            : selectedOption
              ? selectedOption.value
              : ""; 
          onChange({ name, value: newValue });
        }}
        onBlur={onBlur}
        value={
          isMulti
            ? validOptions.filter((option) => value.includes(option.value))
            : validOptions.find((option) => option.value === value)
        }
      />
      {error && <span className="text-xs text-textRed">{error}</span>}
    </div>
  );
};


const SelectField2 = ({
  label,
  options,
  fieldName,
  value,
  isMulti = false,
  isEditing,
  className,
}) => {
  return (
    <div className={`${className} flex items-center gap-2 rounded-t-md`}>
      <GoDotFill className="text-textBlack" />
      <div className="flex-1">
        <span className="font-semibold">{label}:</span>
      </div>
      <div className="flex-1">
        {isEditing ? (
          <Select
            id={fieldName}
            name={fieldName}
            defaultValue={value}
            options={options}
            isMulti={isMulti}
          />
        ) : (
          <span className="font-extrabold">{value}</span>
        )}
      </div>
    </div>
  );
};

export default SelectField;
