// import React, { useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";

// const Step = ({ steps = [] }) => {
//   const [newStep, setNewStep] = useState([]);
//   const [currentStep, setCurrentStep] = useState(1);
//   const stepRef = useRef();
//   const navigate = useNavigate();

//   const percentageMapping = [0, 12.5, 25.0, 62.5, 75.0, 87.5, 100.0];

//   useEffect(() => {
//     if (steps.length > 0) {
//       stepRef.current = steps.map((step, index) => ({
//         description: step,
//         completed: false,
//         selected: index === 0,
//         highlighted: index === 0,
//       }));
//     }
//   }, [steps]);

//   const stepPaths = [
//     "basic",
//     "profession",
//     "religion&family",
//     "photo",
//     "expectation",
//     "questionnaire",
//     "complete",
//   ];

//   useEffect(() => {
//     const userData = JSON.parse(localStorage.getItem("userData"));
//     const profileCompletionPercentage = userData?.profileCompletionPercentage || 0;

//     const currentStepIndex = percentageMapping.findIndex(
//       (percentage) => profileCompletionPercentage < percentage
//     );

//     const validIndex =
//       currentStepIndex === -1 ? percentageMapping.length - 1 : currentStepIndex - 1;

//     if (stepRef.current) {
//       const current = updateStep(validIndex, stepRef.current);
//       setNewStep(current);
//       setCurrentStep(validIndex + 1);
//     }
//   }, [steps]);

//   const updateStep = (stepNumber, steps) => {
//     if (!Array.isArray(steps)) return [];

//     const newSteps = [...steps];
//     for (let i = 0; i < newSteps.length; i++) {
//       if (i === stepNumber) {
//         newSteps[i] = {
//           ...newSteps[i],
//           highlighted: true,
//           selected: true,
//           completed: false,
//         };
//       } else if (i < stepNumber) {
//         newSteps[i] = {
//           ...newSteps[i],
//           highlighted: false,
//           selected: true,
//           completed: true,
//         };
//       } else {
//         newSteps[i] = {
//           ...newSteps[i],
//           highlighted: false,
//           selected: false,
//           completed: false,
//         };
//       }
//     }
//     return newSteps;
//   };

//   const handleStepClick = (index) => {
//     const userData = JSON.parse(localStorage.getItem("userData"));
//     const profileCompletionPercentage = userData?.profileCompletionPercentage || 0;

//     if (profileCompletionPercentage === 100) {
//       const path = stepPaths[index];
//       if (path) {
//         navigate(`/editprofile/${path}`);
//         setNewStep(updateStep(index, stepRef.current));
//         setCurrentStep(index + 1);
//       }
//     } 
//     // else {
//     //   alert("Profile is not yet complete. Please complete your profile first.");
//     // }
//   };

//   const displaySteps = newStep.map((step, index) => (
//     <div
//       key={index}
//       className={
//         index !== newStep.length - 1
//           ? "flex w-full items-center"
//           : "flex items-center"
//       }
//       onClick={() => handleStepClick(index)} 
//       style={{ cursor: "pointer" }} 
//     >
//       <div className="relative flex flex-col items-center">
//         <div
//           className={`flex h-9 w-9 items-center justify-center rounded-full border-2 border-gray-400 py-3 transition duration-500 ease-in-out md:h-12 md:w-12 ${
//             step.selected
//               ? "border border-green-600 bg-green-600 font-bold text-white"
//               : ""
//           }`}
//         >
//           {step.completed ? (
//             <span className="text-xl font-bold text-white">&#10003;</span>
//           ) : (
//             index + 1
//           )}
//         </div>
//         <div
//           className={`absolute top-0 mt-16 hidden w-32 text-center text-sm uppercase md:block md:font-medium ${
//             step.highlighted ? "text-gray-900" : "text-gray-400"
//           }`}
//         >
//           {step.description}
//         </div>
//       </div>
//       <div
//         className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
//           step.completed ? " border-green-600" : "border-gray-300"
//         }`}
//       ></div>
//     </div>
//   ));

//   return <div className="mx-4 flex items-center justify-between p-4">{displaySteps}</div>;
// };

// export default Step;
import React, { useEffect, useState, useRef } from "react";

const Step = ({ steps = [] }) => {
  const [newStep, setNewStep] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const stepRef = useRef();

  const percentageMapping = [0,12.5, 25.00, 62.50, 75.00,87.50, 100.00];
  useEffect(() => {
    if (steps.length > 0) {
      stepRef.current = steps.map((step, index) => ({
        description: step,
        completed: false,
        selected: index === 0,
        highlighted: index === 0,
      }));
    }
  }, [steps]);

  // Update the steps based on profile completion percentage
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const profileCompletionPercentage = userData?.profileCompletionPercentage || 0;
    
    // Find the current step index
    const currentStepIndex = percentageMapping.findIndex(
      (percentage) => profileCompletionPercentage < percentage
    );

    const validIndex = currentStepIndex === -1 ? percentageMapping.length - 1 : currentStepIndex - 1;
    
    if (stepRef.current) {
      const current = updateStep(validIndex, stepRef.current);
      setNewStep(current);
      setCurrentStep(validIndex + 1);
    }
  }, [steps]);

  

  const updateStep = (stepNumber, steps) => {
    if (!Array.isArray(steps)) return []; // Check if steps is an array
  
    const newSteps = [...steps];
    for (let i = 0; i < newSteps.length; i++) {
      if (i === stepNumber) {
        newSteps[i] = {
          ...newSteps[i],
          highlighted: true,
          selected: true,
          completed: false,
        };
      } else if (i < stepNumber) {
        newSteps[i] = {
          ...newSteps[i],
          highlighted: false,
          selected: true,
          completed: true,
        };
      } else {
        newSteps[i] = {
          ...newSteps[i],
          highlighted: false,
          selected: false,
          completed: false,
        };
      }
    }
    return newSteps;
  };
  
  
  const displaySteps = newStep.map((step, index) => {
    return (
      <div
        key={index}
        className={
          index !== newStep.length - 1
            ? "flex w-full items-center"
            : "flex items-center"
        }
      >
        <div className="relative flex flex-col items-center">
          <div
            className={`flex h-9 w-9 items-center justify-center rounded-full border-2 border-gray-400 py-3 transition duration-500 ease-in-out md:h-12 md:w-12 ${step.selected ? "border border-green-600 bg-green-600 font-bold text-white" : ""}`}
          >
            {step.completed ? (
              <span className="text-xl font-bold text-white">&#10003;</span>
            ) : (
              index + 1
            )}
          </div>
          <div
            className={`absolute top-0 mt-16 hidden w-32 text-center text-sm uppercase md:block md:font-medium ${step.highlighted ? "text-gray-900" : "text-gray-400"}`}
          >
            {step.description}
          </div>
        </div>
        <div
          className={`flex-auto border-t-2 transition duration-500 ease-in-out ${step.completed ? " border-green-600" : "border-gray-300"}`}
        ></div>
      </div>
    );
  });

  return (
    <div className="mx-4 flex items-center justify-between p-4">
      {displaySteps}
    </div>
  );
};

export default Step;
