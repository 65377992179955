import React, { useEffect, useState } from 'react';
import MessageBar, { config, errorNotify, getValueFromLocalStorage, localStorageKey, successNotify } from '../../../utils/helper';
import Images from '../../../utils/images';
import UserRequestCard from '../../../Components/UserRequestCard/UserRequestCard'; 
import { authorizedGet, authorizedPost } from '../../../Config/networkWithToken';
import configuration from '../../../Config/configuration';
import Spinner from '../../../Components/Spinner/Spinner';
import Strings from '../../../utils/Strings';

const QuestionnaireRequests = () => {
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const [activeTab, setActiveTab] = useState(0);
  const [userRequests, setUserRequests] = useState([]);
  const [acceptedRequest,setAcceptedRequest ] = useState([]);
  const [rejectedRequest,setRejectedRequest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [LoadingId,setLoadingId] = useState(false);

  useEffect(() => {
    getUserQuestionnaireRequests();
  }, []);

  const tabList = [
    { name: 'User Requests' },
    { name: 'Accepted Requests' },
    { name: 'Denied Requests' }
  ];

  const getUserQuestionnaireRequests = async () => {
    setLoading(true); 
    try {
      const res = await authorizedGet(`${configuration.apis.getAllQuestionnaireRequests}?adminId=${adminId}`, config);
      const response = res?.data?.requests; 
      if (res?.data?.status) { 
        const users = [];
        const accpted = [];
        const rejected = [];

        response.forEach((value) => {
          if(value?.adminApproval === "pending"){
             users.push(value);
          }else if(value?.adminApproval === "approved"){
            accpted.push(value);
          }else if(value?.adminApproval === "rejected"){
             rejected.push(value);
          }
        });

        setUserRequests(users);
        setAcceptedRequest(accpted);
        setRejectedRequest(rejected);
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
    } finally {
      setLoading(false); 
    }
  };

  const handleApproval = async (reqId, decision) => {
     setLoadingId(reqId);
    try {
      const data = {
        reqId: reqId,
        action: decision,  
      };
      const res = await authorizedPost(configuration.apis.questionnaireApproval, data, config);
      const response = res?.data?.data;
      console.log(response);
      if (res?.data?.status) {
        successNotify(res?.data?.message);
        const users = [];
        const accpted = [];
        const rejected = [];

        response.forEach((value) => {
          if(value?.adminApproval === "pending"){
             users.push(value);
          }else if(value?.adminApproval === "approved"){
            accpted.push(value);
          }else if(value?.adminApproval === "rejected"){
             rejected.push(value);
          }
        });

        setUserRequests(users);
        setAcceptedRequest(accpted);
        setRejectedRequest(rejected);
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (error) {
      console.log("Error in approval:", error.response ? error.response.data : error);
      errorNotify("Error processing request.");
    }finally{
      setLoadingId(null);
    }
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="h-auto ml-4 font-jost rounded-lg p-2 relative">
      {/* Tabs */}
      <div className="flex space-x-4 border-b border-gray-300 mb-4">
        {tabList.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(index)}
            className={`cursor-pointer px-4 py-2 text-center transition-all text-textGreen sm:text-xs md:text-base 
              ${activeTab === index
                ? 'border-b-2 border-textYellow text-textYellow font-semibold'
                : 'hover:text-textYellow hover:bg-gray-100'
              }`}
          >
            {tab.name}
          </button>
        ))}
      </div>

      {loading ? (
        <div className="flex flex-col items-center justify-center min-h-screen">
        <Spinner />
    </div>
      ) : (
        <>
       { activeTab === 0 && (
          <div className="flex flex-wrap gap-4">
            {userRequests.length > 0 ? (
              userRequests.map((request) => (
                <UserRequestCard
                  key={request?._id} 
                  user={request?.user} 
                  requestedUser={request?.requestedUser} 
                  activeTab={activeTab}
                  reqId={request?._id} 
                  setUserRequests={setUserRequests}
                  setAcceptedRequest={setAcceptedRequest}
                  setRejectedRequest={setRejectedRequest}
                  />
              ))
            ) : (
              <p className='text-center w-full '>No Questionnaire requests available.</p>
            )}
          </div>
        )}

           { activeTab === 1 && (
               <div className="flex flex-wrap gap-4">
               {acceptedRequest.length > 0 ? (
                 acceptedRequest.map((request) => (
                   <UserRequestCard
                     key={request?._id} 
                     user={request?.user} 
                     requestedUser={request?.requestedUser} 
                     activeTab={activeTab}
                   />
                 ))
               ) : (
                 <p className='text-center w-full '>No Accepted requests available.</p>
               )}
             </div>
            )}

        { activeTab === 2 && (
          <div className="flex flex-wrap gap-4">
          {rejectedRequest.length > 0 ? (
            rejectedRequest.map((request) => (
              <UserRequestCard
                key={request?._id} 
                user={request?.user} 
                requestedUser={request?.requestedUser} 
                activeTab={activeTab}
              />
            ))
          ) : (
            <p className='text-center w-full '>No Rejected requests available.</p>
          )}
        </div>
       )}
       </>
      )}
      <MessageBar/>
    </div>
  );
};

export default QuestionnaireRequests;
