import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MessageBar = () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    data-testid="toast"
  />
);

// Success notification toast

export const successNotify = (msg) =>
  toast.success(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

// error notification toast

export const errorNotify = (err) =>
  toast.info(err, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export default MessageBar;

export const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const headers = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
//live Digital Ocean Buckets
export const DOBASEURL =
  "https://blr1.digitaloceanspaces.com/vmb-2.0-bucket/media/";
export const DOBASEURLDOCUMENT =
  "https://blr1.digitaloceanspaces.com/vmb-2.0-bucket/";

//Dev Digital Ocean Buckets (pre-production)
// export const DOBASEURL =
//   "https://blr1.digitaloceanspaces.com/vmb-pre-prod-bucket/media/";
// export const DOBASEURLDOCUMENT =
//   "https://blr1.digitaloceanspaces.com/vmb-pre-prod-bucket/";

// localStorage keys
export const localStorageKey = {
  USERDATA: "userData",
};

// get the localstorage data
export const getValueFromLocalStorage = (key, field) => {
  const storedObject = localStorage.getItem(key);

  if (storedObject) {
    try {
      const parsedObject = JSON.parse(storedObject);
      return parsedObject[field];
    } catch (error) {
      console.log("Error parsing JSON from localStorage:", error);
      return null;
    }
  } else {
    // console.log("No data found in localStorage for key:", key);
    return null;
  }
};

// update the localstorage data
export const updateValueInLocalStorage = (key, field, newValue) => {
  const storedObject = localStorage.getItem(key);

  if (storedObject) {
    try {
      const parsedObject = JSON.parse(storedObject);
      parsedObject[field] = newValue;
      localStorage.setItem(key, JSON.stringify(parsedObject));
    } catch (error) {
      console.log("Error updating JSON in localStorage:", error);
    }
  } else {
    console.log("No data found in localStorage for key:", key);
  }
};
