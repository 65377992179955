import React from "react";

const PhotoLouge = () => {
  const tableData = [
    {
      List: "Galleries",
      Add: "",
      Edit: "",
    },
    {
      List: "Photo Effects",
      Add: "",
      Edit: "",
    },
    {
      List: "Photo Sizes",
      Edit: "",
    },
    {
      List: "Photo",
      Add: "",
      Edit: "",
    },
    {
      List: "Watermarks",
      Add: "",
      Edit: "",
    },
  ];
  return (
    <div className="mx-auto w-[95%] ">
      <table className="w-full table-auto">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">List of Photo Louge</th>
            <th className="px-4 py-2">ADD</th>
            <th className="px-4 py-2">CHANGE</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{data.List}</td>
              <td className="border px-4 py-2">{data.Add}</td>
              <td className="border px-4 py-2">{data.Edit}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PhotoLouge;
