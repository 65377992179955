import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import { FaRegHandshake, FaUserPen, FaBars } from "react-icons/fa6";
import { FaTimes, FaUserCheck } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineMan, MdLogout } from "react-icons/md";
import Images from "../../utils/images";
import {
  DOBASEURL,
  getValueFromLocalStorage,
  localStorageKey,
} from "../../utils/helper";
import { FaHeart } from "react-icons/fa";
import { RiQuestionnaireFill } from "react-icons/ri";

const UserSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const contentRef = useRef(null);

  // Get user details from localStorage
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
  const userName = getValueFromLocalStorage(localStorageKey.USERDATA, "name");
  const maritalStatus = getValueFromLocalStorage(
    localStorageKey.USERDATA,
    "maritalStatus",
  );
  const primaryEmail = getValueFromLocalStorage(
    localStorageKey.USERDATA,
    "primaryEmail",
  );
  const profile = getValueFromLocalStorage(localStorageKey.USERDATA, "profile");
  const gender = getValueFromLocalStorage(localStorageKey.USERDATA, "gender");

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleEditProfile = () => {
    navigate("/editprofile");
  };

  const list = [
    { icon: <MdOutlineMan />, name: "Profile", path: `/profile/${userId}` },
    { icon: <FaRegHandshake />, name: "Suggestion", path: "suggestion" },
    { icon: <FaHeart />, name: "Interest", path: "interest" },
    {
      icon: <FaUserCheck />,
      name: "Mutually Accepted",
      path: "accepted-users",
    },
    { icon: <IoMdSettings />, name: "Settings", path: "settings" },
    { icon: <MdLogout />, name: "Log out", path: "/" },
  ];

  const handleClickItem = (index, path) => {
    setSelectedItem(index);
    navigate(path);
    if (path === "/") {
      localStorage.clear();
      window.location.reload();
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const index = list.findIndex((item) => currentPath.endsWith(item.path));
    if (index !== -1) {
      setSelectedItem(index);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Reset scroll position
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [location]);

  return (
    <div className="flex min-h-screen w-full bg-bgColor">
      <div className="fixed left-2 top-2 z-50 lg:hidden">
        <button
          onClick={handleToggleSidebar}
          className="text-md inline-flex h-10 w-10 items-center justify-center rounded-[100%] bg-textYellowLight p-2 text-textGreen focus:outline-none focus:ring-2 focus:ring-textYellow "
        >
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed left-0 z-40 h-full w-[255px] transform overflow-y-auto bg-bgWhite p-4 transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
      >
        <div className="relative overflow-hidden rounded-lg">
          <img
            src={
              // DOBASEURL + profile?.photos?.images[0] ||
              // Images.DEFAULT_USER
              profile?.photos?.images.length > 0 ?
              DOBASEURL + profile?.photos?.images[0] 
              : Images.DEFAULT_USER
            }
            onContextMenu={(e) => e.preventDefault()}
            onDragStart={(e) => e.preventDefault()}
            className="ml-2 h-[200px] w-[200px] rounded-full object-cover"
            alt=""
          />
          <div
            onClick={handleEditProfile}
            className="absolute right-2 top-2 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-white shadow-md hover:bg-textYellow"
          >
            <FaUserPen
              className="text-textBlack hover:text-bgWhite"
              size={20}
            />
          </div>
        </div>

        <div className="font-jost mt-5 text-center">
          <h2 className="text-xl text-textGreen">{userName}</h2>
          <p className="text-sm text-textGreen">{maritalStatus}</p>
          <p className="overflow-x-auto whitespace-nowrap text-sm text-textGreen">
            {primaryEmail}
          </p>
        </div>

        {/* Navigation List */}
        <div className="mt-5">
          <ul className="space-y-4">
            {list.map((item, index) => (
              <li key={index} className="cursor-pointer rounded-lg">
                <Link
                  to={item.path}
                  onClick={() => handleClickItem(index, item.path)}
                  className={`font-jost flex items-center gap-3 p-2 ${
                    selectedItem === index
                      ? "bg-textYellow text-bgWhite"
                      : "text-textGreen hover:bg-bglightYellow"
                  }`}
                >
                  <span>{item.icon}</span>
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto lg:ml-[250px]" ref={contentRef}>
        <div
          className="w-full flex-col gap-4"
          style={{
            height: "calc(60vh - 1rem)",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserSideBar;
