import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useFormik } from "formik";
import Strings from "../../utils/Strings";
import MessageBar, {
  config,
  errorNotify,
  successNotify,
} from "../../utils/helper";
import Spinner from "../Spinner/Spinner";
import { get, post } from "../../Config/network";

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "*Required";
  } else if (values.password.length > 20) {
    errors.password = "*Password must be less than 20 characters";
  } else if (values.password.length < 6) {
    errors.password = "*Password must be at least 6 characters";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "*Required";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "*Passwords must match";
  }
  return errors;
};

const ResetPassword = () => {
  const [validUrl, setValidUrl] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isButtonLoad, setIsButtonLoad] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  const url = `api/password-reset/${param.id}/${param.token}`;

  useEffect(() => {
    const verifyUrl = async () => {
      try {
        const res = await get(url, config);
        if (res.data.status) {
          successNotify(res?.data?.message);
          setValidUrl(true);
        } else {
          setValidUrl(false);
          errorNotify(res?.data?.message);
        }
      } catch (err) {
        setValidUrl(false);
        errorNotify(err.message);
      }
    };
    verifyUrl();
  }, [param, url]);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: async (values) => {
      try {
        const data = { password: values.password };
        setIsButtonLoad(true);
        const res = await post(url, data, config);
        if (res.data.status) {
          successNotify(res.data.message);
          navigate("/login");
        } else {
          errorNotify(res.data.message);
        }
      } catch (err) {
        errorNotify(err.message);
      }
      setIsButtonLoad(false);
    },
  });

  return (
    <Fragment>
      <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
        {validUrl ? (
          <div className="popup-content relative rounded-lg bg-white p-8 text-center">
            <h2 className="mb-4 text-2xl font-bold">
              Please Create Your Password to coutinue.
            </h2>
            <form
              onSubmit={formik.handleSubmit}
              className="flex w-full flex-col gap-3 px-2 lg:mt-4"
            >
              <div className="flex flex-col gap-3 md:flex-row md:gap-3 lg:h-16">
                <div className="flex flex-col gap-1 md:w-[50%]">
                  <div className="relative w-full rounded-lg border border-gray-200">
                    <input
                      type={showPassword ? "text" : "password"}
                      autoComplete="off"
                      placeholder="Enter your password"
                      name="password"
                      className="p-2.5 font-sans text-xs text-textBlack focus:outline-none"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                    />
                    <div
                      className="absolute inset-y-0 right-2 flex cursor-pointer items-center"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? (
                        <HiEyeOff className="text-textBlack" />
                      ) : (
                        <HiEye className="text-textBlack" />
                      )}
                    </div>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <span className="text-xs text-textRed">
                      {formik.errors.password}
                    </span>
                  ) : null}
                </div>
                <div className="flex flex-col gap-1 md:w-[50%]">
                  <div className="relative w-full rounded-lg border border-gray-200">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      autoComplete="off"
                      placeholder="Confirm password"
                      name="confirmPassword"
                      className="p-2.5 font-sans text-xs text-textBlack focus:outline-none"
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      onBlur={formik.handleBlur}
                    />
                    <div
                      className="absolute inset-y-0 right-2 flex cursor-pointer items-center"
                      onClick={handleShowConfirmPassword}
                    >
                      {showConfirmPassword ? (
                        <HiEyeOff className="text-textBlack" />
                      ) : (
                        <HiEye className="text-textBlack" />
                      )}
                    </div>
                  </div>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <span className="text-xs text-textRed">
                      {formik.errors.confirmPassword}
                    </span>
                  ) : null}
                </div>
              </div>

              <button
                type="submit"
                className="font-helvetica relative mx-auto mt-2 flex w-[50%] cursor-pointer items-center justify-center rounded-lg bg-textDarkGray p-2 text-sm text-bgWhite hover:bg-textPink disabled:opacity-50"
                disabled={isButtonLoad}
              >
                {isButtonLoad ? (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  "Confirm"
                )}
              </button>
            </form>
          </div>
        ) : (
          <h1>400 Not found. Please try again later.</h1>
        )}
      </div>
      <MessageBar />
    </Fragment>
  );
};

export default ResetPassword;
