import React, { useEffect, useState, useRef } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import "./Navbar.css";
import {
  DOBASEURL,
  getValueFromLocalStorage,
  localStorageKey,
} from "../../utils/helper";
import configuration from "../../Config/configuration";
import { io } from "socket.io-client";
import Notification from "../Notification/Notification";
import { IoIosNotifications } from "react-icons/io";
import Images from "../../utils/images";
import { IoHome } from "react-icons/io5";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [userNav, setuserNav] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [userName, setUserName] = useState("");
  const [showLogout, setShowLogout] = useState(false);

  // console.log(getValueFromLocalStorage(localStorageKey.USERDATA, "profile"));

  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = getValueFromLocalStorage(
    localStorageKey.USERDATA,
    "profile",
  );
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
  const profileCompletionPercentage = getValueFromLocalStorage(
    localStorageKey.USERDATA,
    "profileCompletionPercentage",
  );
  const [notificationCount, setNotificationCount] = useState(() => {
    return (
      parseInt(localStorage.getItem("notificationCount")) ||
      localStorage.getItem("count") ||
      0
    );
  });
  const user =
    userId || getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const profileRef = useRef(null);

  // List of paths where ml-14 shouldn't be applied
  const noMarginPaths = [
    "/",
    "/aboutus",
    "/testimonials",
    "/contactus",
    "/editprofile",
  ];

  // Determine whether to apply ml-14 based on the current path
  const shouldApplyMargin = !noMarginPaths.includes(location.pathname);

  // Setting up the socket connection
  useEffect(() => {
    const socket = io(`${configuration.baseUrl}`);

    if (user) {
      socket.emit("registerUser", user);
    }

    socket.on("updateNotificationCount", (data) => {
      if (data.unreadCount) {
        setNotificationCount(data.unreadCount);
        localStorage.setItem("notificationCount", data.unreadCount);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  useEffect(() => {
    setUserName(getValueFromLocalStorage(localStorageKey.USERDATA, "name"));
    setUserRole(getValueFromLocalStorage(localStorageKey.USERDATA, "role"));
    setCurrentPath(location.pathname);
    const userData = localStorage.getItem("userData");
    if (userData) {
      setIsLoggedIn(true);
    }
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [location.pathname]);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
    window.location.reload();
  };

  const handleNotification = () => {
    setShowNotification(true);
  };

  const handleProfileClick = () => {
    setShowLogout(!showLogout);
  };

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Profile", path: `/profile/${userId}` },
    { name: "About Us", path: "/aboutus" },
    { name: "Testimonials", path: "/testimonials" },
    { name: "Contact Us", path: "/contactus" },
    { name: "FAQS", path: "/faqs" },
  ];

  const adminNavItems = [
    { name: "Home", path: "/" },
    { name: "Matrimony", path: "/matrimony" },
    { name: "About Us", path: "/aboutus" },
    { name: "Testimonials", path: "/testimonials" },
    { name: "FAQS", path: "/faqs" },
  ];

  const unauthorizedNavItems = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/aboutus" },
    { name: "Testimonials", path: "/testimonials" },
    { name: "Contact Us", path: "/contactus" },
    { name: "FAQS", path: "/faqs" },
  ];

  const navItemsToRender = (() => {
    if (!isLoggedIn) {
      return unauthorizedNavItems;
    }
    if (userRole === "admin") {
      return adminNavItems;
    }
    if (profileCompletionPercentage == 100) {
      return navItems;
    }
    return unauthorizedNavItems;
  })();

  return (
    <>
      <nav className="fixed start-0 top-0 z-20 w-full border-b bg-bgWhite">
        <div className="mx-auto flex w-full items-center justify-around p-2 lg:justify-between">
          <Link to="/" className="flex  items-center lg:flex">
            <h1 className="font-jost text-[10px] font-semibold text-[#FBCC17] xxs:text-[14px] xs:text-[16px]  sm:text-[18px] lg:text-[23px]">
              {" "}
              VAISNAVA MARRIAGE
            </h1>
          </Link>

          <div className="flex space-x-3 md:order-2 md:space-x-3">
            {isLoggedIn ? (
              <>
                <div className="relative flex items-center justify-center">
                  <p className="font-jost absolute right-[-7px] top-[-5px] flex h-6 w-6 items-center justify-center rounded-full bg-red-700 p-2 text-[9px] text-white">
                    {notificationCount}
                  </p>
                  <IoIosNotifications
                    onClick={handleNotification}
                    className={`text-md flex h-9 w-9 cursor-pointer items-center justify-center rounded-[100%] bg-textYellowLight p-2 text-textGreen focus:outline-none focus:ring-2 focus:ring-textYellow ${shouldApplyMargin ? "" : ""}`}
                  />
                </div>

                <div
                  className="relative items-center justify-center"
                  ref={profileRef}
                >
                  <div
                    className="font-jost flex w-[130px] cursor-pointer items-center gap-2 overflow-hidden rounded-full bg-textYellowLight pb-[1px] pr-2 pt-[1px]"
                    onClick={handleProfileClick}
                  >
                    <img
                      src={
                        userDetails?.photos?.images?.length > 0
                          ? DOBASEURL + userDetails?.photos?.images[0]
                          : Images.DEFAULT_USER
                      }
                      onContextMenu={(e) => e.preventDefault()}
                      onDragStart={(e) => e.preventDefault()}
                      alt=""
                      className="h-10 w-10 rounded-full object-cover"
                    />

                    {/* Scrollable Name */}
                    <div className="scrollbar-hide relative w-[100px] overflow-x-auto whitespace-nowrap">
                      <span className="block w-max text-black">{userName}</span>
                    </div>
                  </div>

                  {showLogout && (
                    <div className="absolute left-0 top-full mt-4 w-full">
                      <span
                        className="dropdown-container block cursor-pointer whitespace-nowrap rounded-md border border-textYellowLight bg-textYellowLight p-3 text-left transition-all duration-300 active:scale-95"
                        onClick={handleLogout}
                      >
                        Log out
                      </span>
                    </div>
                  )}
                </div>
              </>
            ) : null}

            <div className="authBtn font-jost flex items-center gap-2">
              {isLoggedIn ? (
                <></>
              ) : (
                <>
                  <div className="flex items-center space-x-4 font-medium text-gray-700">
                    <Link
                      to="/login"
                      className="font-jost text-[12px] font-semibold transition duration-300 hover:text-[#FACC33]"
                    >
                      Log In
                    </Link>
                    <span className="text-gray-400">|</span>
                    <Link
                      to="/register"
                      className="font-jost text-[12px] font-semibold transition duration-300 hover:text-[#FACC33]"
                    >
                      Sign Up
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div onClick={handleNav} className="block lg:hidden">
              {nav ? (
                <AiOutlineClose size={30} color={"#66451C"} />
              ) : (
                <button
                  type="button"
                  className="text-md inline-flex h-10 w-10 items-center justify-center rounded-[100%] bg-textYellowLight p-2 text-textGreen focus:outline-none focus:ring-2 focus:ring-textYellow lg:hidden"
                >
                  <IoHome />
                </button>
              )}
            </div>
          </div>

          <ul className="hidden items-center justify-center lg:flex">
            {navItemsToRender.map((item, index) => (
              <li
                key={index}
                className="font-jost m-2 cursor-pointer p-2 font-medium text-black duration-300"
              >
                <Link
                  to={item.path}
                  className={`text-sm  ${
                    currentPath === item.path ? "activeNav" : "nav"
                  }`}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>

          <ul
            className={
              nav
                ? "fixed left-0 right-0  top-0 h-full w-[60%] border-r bg-yellow-400 pt-[15%] xs:w-[40%] sm:w-[25%] lg:hidden"
                : "fixed bottom-0 left-[-100%] top-0 w-[60%] duration-500 ease-in-out xs:w-[40%] sm:w-[25%]"
            }
          >
            {navItemsToRender.map((item, index) => (
              <li
                key={index}
                className="font-jost cursor-pointer  p-4 uppercase text-black duration-300 hover:bg-textYellowLight"
              >
                <Link
                  to={item.path}
                  className="text-md font-normal"
                  onClick={() => setNav(false)}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>

      <main>
        <Outlet />
        {showNotification && (
          <div className="popup-container fixed left-0 top-0 z-50 flex min-h-screen w-full justify-end bg-black/[0.5]">
            <Notification
              setShowNotification={setShowNotification}
              setNotificationCount={(count) => {
                setNotificationCount(count);
                localStorage.setItem("notificationCount", count);
              }}
            />
          </div>
        )}
      </main>
    </>
  );
};

export default Navbar;
