import React, { useEffect, useState } from "react";
import Images from "../../utils/images";
import { authorizedGet } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import {
  config,
  DOBASEURL,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
} from "../../utils/helper";
import { Link } from "react-router-dom";
import Spinner from "../Spinner/Spinner";

const AcceptedUsers = () => {
  const [acceptedUsersData, setAcceptedUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");

  useEffect(() => {
    const fetchApprovedUsers = async () => {
      setLoading(true);
      try {
        const res = await authorizedGet(
          `${configuration.apis.getAcceptedUsers}?&userId=${userId}`,
          config,
        );
        if (res?.data?.status) {
          setAcceptedUsersData(res.data.data);
        }
      } catch (error) {
        errorNotify("Error processing request.");
      } finally {
        setLoading(false);
      }
    };

    fetchApprovedUsers();
  }, [userId]);

  return (
    <div className="accepted-users font-jost flex flex-wrap justify-center gap-8 pl-4 pt-20 md:justify-start">
      {loading ? (
        <div className="flex h-screen w-full items-center justify-center">
          <Spinner />
        </div>
      ) : acceptedUsersData.length > 0 ? (
        acceptedUsersData.map((user, index) => (
          <div
            key={user._id || index}
            className="user-card w-64 rounded-lg border bg-white p-4 shadow-md"
          >
            <img
              src={
                //DOBASEURL + user?.acceptedUser?.images[0]
                user?.acceptedUser?.images.length > 0
                                ? DOBASEURL + user?.acceptedUser?.images[0]
                                :  user?.acceptedUser?.gender === "male"
                                  ? Images.MEN
                                  : Images.WOMEN
              }
              className="mx-auto mb-2 h-24 w-24 rounded-full"
              alt="Profile"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
            />
            <p className=" text-md text-center text-textGreen">
              {" "}
              {user?.acceptedUser?.name}
            </p>
            <p className="text-center"> {user?.acceptedUser?.birthCity}</p>
            <p className="text-center"> {user?.acceptedUser?.caste}</p>
            <p className="text-center"> {user?.acceptedUser?.motherTongue}</p>

            <Link to={`/accepted-users/viewProfile/${user?.acceptedUser?._id}`}>
              <button className="mt-2 w-full rounded bg-textYellow px-4 py-2 text-white">
                View Profile
              </button>
            </Link>
          </div>
        ))
      ) : (
        <h3 className="mt-10 w-full text-center ">
          No Accepted Users available.
        </h3>
      )}
    </div>
  );
};

export default AcceptedUsers;
