import React, { useEffect } from 'react'
import { useState } from 'react';
import { config, getValueFromLocalStorage, localStorageKey } from '../../utils/helper';
import { authorizedGet } from '../../Config/networkWithToken';
import configuration from '../../Config/configuration';
import Spinner from '../Spinner/Spinner';
import ViewQuestionnaire from '../ViewQuestionnarie/ViewQuestionnaire';

const QuestionnaireTab = () => {

      const [activeTab,setActiveTab] = useState(0);
      const [approvedQuestionaire,setApprovedQuestionaire] = useState([]);
      const [loading,setLoading] = useState(false);
    
      const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
  
      useEffect(() => {
          getApproveRequests();
          console.log(userId);
          
      },[]);
  
      const getApproveRequests = async () => {
           setLoading(true);
          try {
            const res = await authorizedGet(`${configuration.apis.getQuestions}?userId=${userId}`,config);
            const response = res?.data?.data;
            console.log(response);
            if(res?.data?.status){
              setApprovedQuestionaire(response);
            }
          } catch (error) {
             console.log(error);
          }finally{
              setLoading(false);
          }
      }

      const handleTabClick = (index) => {
        setActiveTab(index);
      };

  const tabList = [
    { name: "Questionnaire" },
    // { name: "Denied Requests" },
  ];

  return (
    <div className="flex min-h-screen w-full flex-col px-3 py-12 md:mt-10">
    <div className="p-3 h-auto w-full rounded-l ">
    <div className="flex border-b flex-col md:flex-row gap-5 text-textGreen justify-between items-center">
          <ul className="flex flex-col md:flex-row w-full md:w-auto">
            {tabList.map((list, index) => (
              <li
                key={index}
                className={`cursor-pointer px-4 py-2 text-center transition-all sm:text-xs md:text-base ${
                  activeTab === index
                    ? "border-b-2 border-textYellow font-semibold text-textYellow"
                    : "hover:bg-gray-100 hover:text-textYellow"
                }`}
                onClick={() => {
                  if (activeTab !== 1 || index !== 1) {
                    handleTabClick(index);
                  }
                }}
              >
                {list.name}
              </li>
            ))}
          </ul>
          </div>
          {loading ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
             {activeTab === 0 && (
                 <div>
                 <ViewQuestionnaire questionnaireData={approvedQuestionaire}
                 />
                 </div>
             )}
          </>
        )}
        
      </div>
      </div>
  )
}

export default QuestionnaireTab