import React, { useState } from "react";
import { useFormik } from "formik";
import { IoIosCloseCircle } from "react-icons/io";
import Strings from "../../utils/Strings";
import MessageBar, { config, errorNotify, successNotify } from "../../utils/helper";
import Spinner from "../Spinner/Spinner";
import configuration from "../../Config/configuration";
import { post } from "../../Config/network";

const validate = (values) => {
  const errors = {};
  if (!values.primaryEmail) {
    errors.primaryEmail = "*Required";
  } else if (
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(values.primaryEmail)
  ) {
    errors.primaryEmail = "*Invalid Email";
  }
  return errors;
};

const ForgetPassword = ({ setForgetPasswordPopup,isPasswordExpired,primaryEmail }) => {
  const [isButtonLoad, setIsButtonLoad] = useState(false);

  const formik = useFormik({
    initialValues: {
      primaryEmail: primaryEmail,
    },
    validate,
    onSubmit: async (values) => {
      if (isButtonLoad) return; 
      setIsButtonLoad(true);
      try {
        const data = {
          primaryEmail: values.primaryEmail,
        };
        const res = await post(configuration.apis.forgetPassword, data, config);
        const response = res?.data;

        if (response?.status) {
          successNotify(response.message);
          setTimeout(() => {
            setForgetPasswordPopup(false);
          }, 3000);
        } else {
          errorNotify(response.message);
        }
      } catch (err) {
        errorNotify(err.message||"given email address does not exist");
      } finally {
        setIsButtonLoad(false);
      }
    },
  });

  const handleClose = () => {
    setForgetPasswordPopup(false);
  };


  return (
    <div className="popup-container fixed left-0 top-0 z-20 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
      <div className="popup-content relative w-[90%] rounded-lg bg-white p-8 md:w-auto">
      <div className="text-center">
      <h2 className="mb-4 text-2xl font-bold">
        {isPasswordExpired
        ? "Your password has expired."
        :Strings.FORGETPASSWORD_HEADER}      
      </h2>
      <p className="text">
        {isPasswordExpired
          ? "Please change your password."
          : Strings.FORGETPASSWORD_TEXT}
      </p>
    </div>
        <form
          onSubmit={formik.handleSubmit}
          className="flex w-full flex-col gap-3 px-2 lg:mt-4"
        >
          <div className="flex flex-col gap-1">
            <label
              htmlFor="primaryEmail"
              className="font-helvetica text-sm font-extrabold text-textBlack"
            >
              Email:
            </label>
            <input
              type="email"
              placeholder="Enter your email"
              className="rounded-lg border border-gray-200 p-2.5 font-sans text-xs text-textBlack"
              name="primaryEmail"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.primaryEmail}
              onBlur={formik.handleBlur}
              disabled={isButtonLoad}
            />
            {formik.errors.primaryEmail ? (
              <span className="text-xs text-textRed">
                {formik.errors.primaryEmail}
              </span>
            ) : null}
          </div>

          <button
            type="submit"
            className="font-helvetica relative mx-auto mt-2 flex w-[50%] cursor-pointer items-center justify-center rounded-lg bg-textDarkGray p-2 text-sm text-bgWhite hover:bg-textPink disabled:opacity-50"
            disabled={isButtonLoad}
          >
            {isButtonLoad ? (
              <div className="flex items-center justify-center">
                <Spinner />
                <span className="ml-2">Sending...</span>
              </div>
            ) : (
              "Confirm"
            )}
          </button>
        </form>

        <div className="absolute right-0 top-0 p-2">
          <IoIosCloseCircle className="text-2xl" onClick={handleClose} />
        </div>
      </div>
      <MessageBar />
    </div>
  );
};

export default ForgetPassword;
