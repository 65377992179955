import React, { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import Spinner from "../Spinner/Spinner";
import { authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import { errorNotify } from "../../utils/helper";

const Questionnaire = ({ getLink, setUploadVisible }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadURL, setDownloadURL] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["application/pdf"]; // Only allow PDF files

      if (!validTypes.includes(file.type)) {
        alert("Please upload a valid PDF document.");
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        alert("File size should not exceed 2MB.");
        return;
      }

      setUploadedFile(file);
      setFileName(file.name);
    }
  };
  //upload Document in DIgitlal Ocean
  const uploadDocument = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    if (uploadedFile) {
      try {
        console.log("upload Pictures");
        const formData = new FormData();
        formData.append("file", uploadedFile);

        const res = await authorizedPost(
          `${configuration.apis.uploadFiles}?fileType=quentionaire`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (res?.data?.status) {
          setDownloadURL(res?.data?.fileName);
          setIsSubmitted(true);
          getLink(res?.data?.fileName);
          setUploadVisible(false);
        } else {
          errorNotify("Something went wrong Please try again");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsUploading(false);
      }
    } else {
      console.log("image file error");
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!uploadedFile) {
      alert("Please upload a document before submitting.");
      return;
    }

    setIsUploading(true);

    // Firebase Storage reference
    const storageRef = ref(storage, `uploads/${uploadedFile.name}`);

    // Upload file to Firebase Storage
    const uploadTask = uploadBytesResumable(storageRef, uploadedFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Track the upload progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        setIsUploading(false);
        console.error("Upload failed:", error);
      },
      () => {
        // Get download URL after upload is complete
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setDownloadURL(url);
          setIsUploading(false);
          setIsSubmitted(true);
          getLink(url);
          setUploadVisible(false);
        });
      },
    );
  };

  return (
    <div className="rounded bg-white p-8 shadow-md">
      <h2 className="mb-4 text-2xl font-bold">Upload Document</h2>
      <form onSubmit={uploadDocument}>
        <div className="mb-4">
          <label>
            <input
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
              required
              className="w-full rounded border border-gray-300 p-2"
            />
          </label>
          {fileName && (
            <p className="text-gray-600">Selected file: {fileName}</p>
          )}
        </div>
        {uploadProgress > 0 && (
          <div className="mb-4">
            <p>Upload Progress: {Math.round(uploadProgress)}%</p>
          </div>
        )}
        {downloadURL && (
          <div className="mb-4">
            <p>
              File available at:{" "}
              <a href={downloadURL} target="_blank" rel="noopener noreferrer">
                {downloadURL}
              </a>
            </p>
          </div>
        )}
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="flex items-center justify-center rounded bg-yellow-500 px-4 py-2 text-white transition duration-200"
            disabled={isUploading || isSubmitted}
          >
            {isUploading ? <Spinner /> : "Submit"}
          </button>
          <button
            type="button"
            onClick={() => setUploadVisible(false)}
            className="rounded bg-red-600 px-4 py-2 text-white transition duration-200"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Questionnaire;
