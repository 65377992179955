export const terms = [
  {
    Title: "A. YOUR INFORMATION AND PRIVACY",
    Content: `By accessing or using the Website, You confirm that You have read, understood, and agreed to conform to these terms and conditions; You also warrant the accuracy and truthfulness of all the information that You have submitted while accessing or using the Website. Suppose You provide any information which we have reasonable grounds to suspect that the information is untrue or incorrect. In that case, Vaishnava Marriage may reserve the right to suspend or terminate Your access to the Website. This allows us to provide and inform You of the services and features that most likely meet Your needs. Your personal information will be used in accordance with the privacy policy available on the Website.`,
    Content1: `Access to the Website shall be restricted. Only users signed up with a user-specific user ID and password can access the Website. The user identity shall be provided by Vaishnava Marriage while the user shall define the login password, which shall be encrypted and stored on the Website server. We strongly recommend that the password be retained confidential. Your password is an authentication tool and we recommend that each User periodically changes the password. You shall remain solely responsible for maintaining the confidentiality of Your username, password, or account details which You have created for availing services through the Website.  You agree to immediately notify Vaishnava Marriage of any unauthorized use of Your password or account or any other security breach.`,
  },
  {
    Title: "B. PRIVACY POLICY",
    Content:
      "Vaishnava Marriage shall maintain information collected by it in accordance with the terms and conditions of the Privacy Policy, which sets forth and describes the practices of Vaishnava Marriage with respect to the collection, use, and disclosure of information in connection with Your use of the Website. Vaishnava Marriage reserves the right to change the provisions of its Privacy Policy at any time and from time to time at its sole discretion.",
  },
  {
    Title: "C. NO WARRANTY",
    subTitle: `If applicable law prohibits the following limitations from applying to You, they will not apply to You, to the extent such prohibitions are applicable.`,
    Content: `Vaishnava marriage does not warrant against interference with your enjoyment of the website and service, that the functions contained in, or services performed or provided by, the website will meet your requirements, that the operation of the website and service will be uninterrupted or error-free, that any service will continue to be made available, that defects in the website or services will be corrected, or that the website will be compatible or work with any third party software, applications or third party services.`,
    Content1: `Any and all information exchanged on the website should not in any way be construed as any offer or recommendation by or from the website or vaishnava marriage. Further, vaishnava marriage shall not be responsible for any loss or damage arising out of, or subsequent to, matrimonial relations established pursuant to the use of the website. Even as vaishnava marriage takes due care to ensure the accuracy of all users’ profiles, all users are recommended to carry out their own due diligence when exchanging personal details. Vaishnava marriage, its team, and its affiliates shall not be responsible for any loss or damage arising out of inaccurate information on the website.`,
  },
  {
    Title: "D. LIMITATION OF LIABILITY ",
    Content: `In no event shall vaishnava marriage, its affiliates, agents, or principals be liable for personal injury, or any incidental, special, indirect, or consequential damages whatsoever, including, without limitation, corruption or loss of data, failure to transmit or receive any data, business interruption or any other commercial damages or losses, arising out of or related to your use or inability to use the website.`,
  },
  {
    Title: "E. INDEMNIFICATION",
    Content:
      " You shall indemnify, defend and hold harmless Vaishnava Marriage and its affiliates, partners, and each of their respective officers, directors, agents, and employees (the “Indemnified Parties”) from and against any claim, proceeding, loss, damage, fine, penalty, interest, and expense (including without limitation, fees for attorneys and other professional advisors) arising out of or in connection with the following: (i) Your access to or use of the Website and its services; (ii) Your breach of this Terms and Conditions; (iii) Your violation of law; (iv) Your negligence or wilful misconduct; or (v) Your infringement of any intellectual property or misappropriation of any proprietary right. These obligations will survive any termination of the Terms and Conditions.",
  },
  {
    Title: "F. RESTRICTIONS ON USE ",
    Content: `You shall use the Website strictly in accordance with the terms of this Terms and Conditions and shall not: (a) promote information that is false and misleading; (b) display pornographic or sexually explicit material/pictures; (c) use the Website for any revenue-generating endeavor, commercial enterprise, or any other commercial purpose; and (d) be involved in any money lending scams.`,
    Content1: `In the event You choose not to pursue a particular potential match, at any point after perusing the duly filled-up questionnaire received from any potential match, You shall delete the duly filled-up questionnaire of such individual, forthwith, from your system. You agree that you shall neither circulate the document nor communicate any details of the document, to any third party.`,
  },
  {
    Title: "G. SCOPE OF USING THE WEBSITE ",
    Content: `The Website is designed to enable the User on the Website to find a prospective match who is aligned on aspects important to each User. The final utility and resulting impact, of the Website’s features, are entirely dependent on the manner in which You use the Website, and on the accuracy of the details incorporated.`,
    Content1: `Neither nor any other party represents, or warrants, in any manner, any particular outcome from the use of the Website.  H. INTELLECTUAL PROPERTY RIGHTS`,
    connect2: `You acknowledge and agree that the Website and all copyrights, trade secrets and other intellectual property rights associated therewith are, and shall remain, the property of Vaishnava Marriage. You acknowledge and agree that the format, directories, queries, algorithms, structure, and organization of the Website are the intellectual property and proprietary and confidential information of the Website. You are not granted any intellectual property rights in or to the Website by implication, estoppel, or other legal theory, and all rights in and to the Website not expressly granted in this Terms and Conditions are hereby reserved and retained by Vaishnava Marriage.`,
  },
  {
    Title: "I. COMPLETE AGREEMENT, GOVERNING LANGUAGE",
    Content:
      "These Terms and Conditions constitute the entire agreement between You and Vaishnava Marriage relating to the Website and supersede all prior or contemporaneous understandings regarding such subject matter. No amendment to or modification of these Terms and Conditions will be binding unless accepted by Vaishnava Marriage in writing. Should any translation of these Terms and Conditions be done for local requirements, and in the event of an ambiguity arising between the English and any non-English versions, the English version of these Terms and Conditions shall govern.",
  },
  {
    Title: "H. CONTROLLING LAW AND SEVERABILITY",
    Content:
      "These Terms and Conditions will be governed by and construed in accordance with the laws of India, excluding any conflicts of law principles. Any disputes arising from these Terms and Conditions shall be adjudicated in the Courts of West Bengal. If for any reason a court of competent jurisdiction finds any provision, or portion thereof, to be unenforceable, the remainder of these Terms and Conditions shall continue in full force and eﬀect.",
  },
];

export const privacy = [
  {
    Title: "1.What personal information is collected by Vaishnava Marriage?",
    Content: `Vaishnava Marriage gathers various types of personal information from You, as explained more fully below. We may use this Personal Information to personalize and improve our services, to allow You to set up a user account and profile, to contact users, to fulfill your requests regarding any query, to analyze how our users utilize the Website, and as otherwise as set forth in this Privacy Policy. Vaishnava Marriage collects the following types of information`,
    Content1: `A.“Personal Information” Information that relates to a person, which, either directly or indirectly, in combination with other information is available or is likely to be available with Vaishnava Marriage and is capable of personally identifying such person, will come within the purview of Personal Information. Vaishnava Marriage receives and stores any information You knowingly provide to Vaishnava Marriage or on the Website. For example, during the registration process, Vaishnava Marriage collects Personal Information such as Your name, user name, phone number, your email address, photographs uploaded, and spiritual inclinations. You may also choose not to provide Vaishnava Marriage with certain information. In such a case, You may not be able to take advantage of many special features of the Website.`,
    Content2: `B.Third Party Account Information” - If You provide Your third party account credentials to Vaishnava Marriage, You are consenting to have the information in those accounts such as name, phone number, gender and profile photo transmitted into Your account with Vaishnava Marriage. This Third Party Account Information is covered by this Privacy Policy. If You choose not to sign up through any social media website / Google, and sign in manually, You will have to provide Your email ID and name in order to sign up as a registered user of the Website. Passwords to these accounts are encrypted and stored on the server, and are inaccessible even to the employees of Vaishnava Marriage. `,
    Content3: `C.Automatically Collected Information - When You access the Website, Your data relating to device ID, log files, Geographic Location, device Information/specification are also collected automatically.`,
    Content4: `You can always opt out from disclosing information to Vaishnava Marriage, but keep in mind that some information may be needed to utilize and optimally use some features of the Website.`,
  },
  {
    Title:
      "2.What is the purpose of collecting Personal Information from You? ",
    Content: `Vaishnava Marriage collects Personal Information in order to provide the services and features intended by the Website; which is to enable You to get appropriately matched with another user registered on the Website. In order to facilitate this primary function, which entails other ancillary functions, it becomes obligatory to personally identify You.`,
  },
  {
    Title:
      "3.Does Vaishnava Marriage share the Personal Information collected from You, with any third party?",
    Content: `Vaishnava Marriage may release Personal Information if we believe that such release is necessary to comply with the law; enforce or apply Vaishnava Marriage’s conditions of use and other agreements; or protect the rights, property, or safety of Vaishnava Marriage, its employees, its users, or others. This includes exchanging information with other companies and organizations for fraud protection etcetera. `,
    Content1: `Apart from this, Vaishnava Marriage may also share Personal Information with third party service providers who assist Vaishnava Marriage in carrying out its business operations, operating the Website and providing various services to You. You hereby, and by accepting this Privacy Policy and / or using the Website, grant consent to Vaishnava Marriage to share Your Personal Information with such third parties for such purposes at present or future.`,
    Content2: `Vaishnava Marriage may conduct or authorize third parties to conduct certain targeted advertising and data analytics operations. These operations ensure that You receive suitable matches so that the Vaishnava Marriage Website is more useful and relevant to You.`,
    Content3: `By accepting this Privacy Policy and /or by using the Website, You agree that Vaishnava Marriage may use, and hereby grant permission to use, by Vaishnava Marriage, the Personal Information shared by the You for purposes set out in this Policy and for targeted advertising and data analytics if Vaishnava Marriage chooses to so utilize the same for those purposes. You also hereby, by accepting this Policy and by using the Website, grant Your consent to sharing of such Personal Information with third parties for these purposes.`,
    Content4: `Except as set forth above, You will be notified when Your Personal Information may be shared with third parties, and will be able to prevent the sharing of this information in such events.`,
  },
  {
    Title: "4.Payments and Card Storage Policy",
    Content: `At this stage, Vaishnava Marriage does not collect any payment / donations towards provision of services. However, Vaishnava Marriage has the discretion to change this policy, with due notice to You. In the event of paid services, Vaishnava Marriage shall collect the credit / debit card information, its expiration date, billing address, that You input on the Website to make payments. In such cases, Vaishnava Marriage shall use a secure server for card transactions, to protect the card transactions and the cookies used to store the login information.`,
  },
  {
    Title: "5.Does the Website use and store cookies? ",
    Content:
      "We use cookies to store information to provide better services. You as the user may also encounter cookies related pop-ups or instructions requesting your acceptance on certain pages of the site and application. Any third-party cookies collection on our site is not controlled by Vaishnava Marriage and is purely at Your discretion.",
  },
  {
    Title: "6.How secure is the Personal Information furnished by You?",
    Content: `At the time of creating an account on the Website, You are sent a verification email to the email address specified during signup to complete the signup process. We may in future extend the signup procedure and send a One Time Password (OTP)  to your mobile phone as part of extended sign up process and security process. In that case, You are expected to have the mobile phone in your possession, and will need to prevent unauthorized access to Your account and Personal Information by limiting access to Your phone and the Website. `,
    Content1: `We at Vaishnava Marriage have also incorporated a slew of organizational and security measures. These measures have been implemented to control and protect the security of Your Personal Information. Vaishnava Marriage endeavors to protect the privacy of Your account and other Personal Information it holds in its records, but Vaishnava Marriage cannot guarantee complete security. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.`,
  },
  {
    Title: "7.Can You access Your Personal Information?",
    Content: `Through Your account settings, You may access and, in some cases, change or delete certain information You have provided to Vaishnava Marriage. The information You can view and update through Your account may change as the Website changes. `,
    Content1: `If you reside in the EU or the UK, You have the right to write to us and let us know if you believe Vaishnava Marriage is not entitled to hold certain Personal Information which Vaishnava Marriage holds about You. Additionally, you are entitled to opt out from sharing Personal Information that you believe is detrimental to sharing with Vaishnava Marriage. However, please note that if such Personal Information is pivotal to the services provided by Vaishnava Marriage, then Vaishnava Marriage will not be able to provide any services to you. `,
    Content2: `If you wish to review, delete or update or review any of your information, please contact us  through email at contact@vaisnavamarriage.com. We may also use your data to track any anti-fraud activities with such third-party providers.`,
    Content3: `Vaishnava Marriage shall be forthcoming to accede to any requests in compliance with the local laws. To enable such compliance, we request You to furnish a government ID Proof, in this respect.`,
  },
  {
    Title: "8.How long is Your Personal Information preserved with VMB?",
    Content: `Vaishnava Marriage preserves Your Personal Information till such time you are registered with the Website. For clarification, the Personal Information in a dormant profile will also be in the records of Vaishnava Marriage till such time such account is deactivated and permanently deleted, unless the same is required for any legal obligations. `,
  },
  {
    Title: "9.Changes to this Privacy Policy",
    Content: `Vaishnava Marriage may amend this Privacy Policy from time to time. Use of information Vaishnava Marriage collects now is subject to the Privacy Policy in effect at the time such information is used. If Vaishnava Marriage makes changes in the way it uses Personal Information, it will notify You by posting an announcement on the Website, or on Your email. You are bound by any changes to the Privacy Policy when You use the Website after such changes have been first posted.`,
  },
  {
    Title: "10.How secure are the duly filled up Questionnaires? ",
    Content: `During the process of updating Your profile on the Website, You will be given the option to answer a detailed set of questions related to the grihastha ashram and your views on allied subject matters. Such questions could be a mixture of both objective and descriptive. The questionnaire helps us (i) to provide better services in tune with Your personal requirements, (ii) to serve genuine seekers, and (iii) know the potential match better by getting upfront answers for such individual’s views.`,
    Content1: `The answers provided by You will be securely stored on our databases. The duly filled up questionnaire shall be disclosed to a potential match only after your consent. However, should You choose not to fill up and submit the questionnaire, You will be unable to view the duly filled up questionnaires of Your potential matches.`,
    Content2: `Please refrain from sharing sensitive information on the questionnaire. It is in our discretion to update or review the questionnaire periodically. `,
  },
  {
    Title: "11.Questions or concerns ",
    Content: `If You have any questions or concerns regarding our Privacy Policy, please send us a detailed message to contact@vaisnavamarriage.com. We will make every effort to resolve your concerns.`,
  },
];
