import React from "react";

const MentorDetails = ({ userData, Loading }) => {
  if (Loading) {
    return (
      <div className="flex h-screen  items-center justify-center text-center text-lg">
        No data available
      </div>
    );
  }
  if (!userData) {
    return <div className="text-center text-lg"> No data available</div>;
  }

  const mentorInfo = userData?.profile?.mentor || {};

  return (
    <div className="font-jost mx-auto p-6">
      <div className="mb-2 bg-white   px-4">
        <h3 className="mb-2  text-xl text-textYellow">Mentor Information</h3>
        <div className="flex flex-col md:flex-row md:flex-wrap">
          <p className="mb-2 w-full md:w-1/2">
            <span className="font-medium">Mentor Name:</span>{" "}
            {mentorInfo.mentorName || "NA"}
          </p>
          <p className="mb-2 w-full break-all md:w-1/2">
            <span className="text-wrap font-medium">Mentor Email:</span>{" "}
            {mentorInfo.mentorsEmail || "NA"}
          </p>
          <p className="mb-2 w-full md:w-1/2">
            <span className="font-medium">Mentor Phone Number:</span>{" "}
            {mentorInfo.mentorsPhoneNumber || "NA"}
          </p>
          <p className="mb-2 w-full md:w-1/2">
            <span className="font-medium">Mentor Position:</span>{" "}
            {mentorInfo.mentorPosition || "NA"}
          </p>
          <p className="mb-2 w-full md:w-1/2">
            <span className="font-medium">Mentor Connecting Temple:</span>{" "}
            {mentorInfo.connectedTemple || "NA"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MentorDetails;
