import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import Images from "../../utils/images";
import Strings from "../../utils/Strings";
import configuration from "../../Config/configuration";
import MessageBar, {
  config,
  DOBASEURL,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import { authorizedGet, authorizedPost } from "../../Config/networkWithToken";
import Spinner from "../Spinner/Spinner";

const ProfileContainer = ({
  userData,
  index,
  activeTab,
  setAcceptedRequest,
  setPendingRequests,
  setRejectedRequest,
}) => {
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [rejectDecision, setRejectDecision] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const [questionnaireButton, setQuestionnaireButton] = useState(null);
  const [loadingId, setLoadingId] = useState(null);

  // const filterUserDataByDecision = (userData, decisionType) => {
  //   return userData.filter((item) => item.decision === decisionType);
  // };
  const matchId = userData?._id;
  const admin = userData?.adminId;
  const gender = getValueFromLocalStorage(localStorageKey.USERDATA, "gender");
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");

  const handleMatchDecision = async (decision) => {
    try {
      // console.log("matchId".matchId);

      const data = {
        userId: userId,
        matchId: matchId,
        decision: decision === "accepted" ? decision : rejectDecision,
        comments: decision === "accepted" ? "" : comment,
      };

      console.log(data);
      if (decision === "accepted") {
        setAcceptLoading(true);
      } else {
        setAcceptLoading(false);
      }
      if (rejectDecision === "rejected") {
        setRejectLoading(true);
      } else {
        setRejectLoading(false);
      }

      const res = await authorizedPost(
        configuration.apis.changeStatus,
        data,
        config,
      );

      const response = res.data?.data;
      if (res?.data?.status) {
        successNotify(res?.data?.message);
        const pendingData = [];
        const acceptedData = [];
        const rejectedData = [];

        response.forEach((userData) => {
          if (userId === userData?.requestedUser?.userId) {
            if (userData?.matchedUserdecision === "pending") {
              pendingData.push(userData);
            }
            if (userData?.matchedUserdecision === "Accepted") {
              acceptedData.push(userData);
            }
            if (userData?.matchedUserdecision === "Rejected") {
              rejectedData.push(userData);
            }
          } else {
            if (userData?.Userdecision === "pending") {
              pendingData.push(userData);
            }
            if (userData?.Userdecision === "accepted") {
              acceptedData.push(userData);
            }
            if (userData?.Userdecision === "Rejected") {
              rejectedData.push(userData);
            }
          }
        });

        // if (response?.status) {
        //   const userData = response?.data;

        //   const pendingData = filterUserDataByDecision(userData, "pending");
        //   const acceptedData = filterUserDataByDecision(userData, "accepted");
        //   const rejectedData = filterUserDataByDecision(userData, "rejected");

        setPendingRequests(pendingData);
        setAcceptedRequest(acceptedData);
        setRejectedRequest(rejectedData);
      } else {
        errorNotify(res?.data?.message);
        console.log("Failed to change match decision.");
      }
    } catch (err) {
      errorNotify("Unexpected Error");
      console.log(err);
    } finally {
      if (decision === "accepted") setAcceptLoading(false);
      if (rejectDecision === "rejected") {
        setRejectDecision("");
        setRejectLoading(false);
      }
      setComment("");
      setIsCommentSectionOpen(false);
    }
  };

  const acceptMatch = async () => {
    await handleMatchDecision("accepted");
  };

  const rejectMatch = async () => {
    handleCommentModalOpen("rejected");
    // await handleMatchDecision("rejected");
  };

  const handleSaveComment = () => {
    if (comment.length < 15) {
      setError(true);
    } else {
      handleMatchDecision();
      setIsCommentSectionOpen(false);
      setError(false);
    }
  };

  const handleNoteChange = (event) => {
    setError(false);
    setComment(event.target.value);
  };

  const handleCommentModalOpen = (decision) => {
    setIsCommentSectionOpen(true);
    setRejectDecision(decision);
  };

  const closeModal = () => {
    setComment("");
    setError(false);
    setIsCommentSectionOpen(false);
  };

  return (
    <>
      {Object.keys(userData).length > 0 ? (
        <>
          <div
            key={index}
            className="newRequest font-jost relative mb-4 flex flex-col items-center justify-center gap-4 rounded-lg border border-gray-200 bg-bgWhite p-2 shadow-sm md:flex-row"
          >
            <div className="flex items-center justify-center p-2 blur md:h-[200px] md:w-[30%] lg:h-full lg:w-[20%] xl:w-[20%]">
              <img
                src={userId === userData?.requestedUser?.userId
                  ? (userData?.user?.images?.length > 0 ? DOBASEURL+ userData?.user?.images?.[0] : gender === "male" ? Images.WOMEN : Images.MEN)
                  : (userData?.requestedUser?.images?.length > 0 ? DOBASEURL+ userData?.requestedUser?.images?.[0] : gender === "male" ? Images.WOMEN : Images.MEN)
                }
                alt=""
                className="h-[100px] w-[130px] rounded-xl object-cover "
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
              />
            </div>

            <div className="font-helvetica flex h-full w-full flex-col gap-2 text-black ">
              {/* <div>
                <h2 className="text-center text-lg md:text-left">
                  {userId === userData?.requestedUser?.userId
                    ? userData?.user?.name
                    : userData?.requestedUser?.name}
                </h2>
              </div> */}
              <div className="flex">
                <ul className="flex flex-wrap gap-2">
                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    Date Of Birth:
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? new Date(userData?.user?.dob).toLocaleDateString(
                            "en-CA",
                          )
                        : new Date(
                            userData?.requestedUser?.dob,
                          ).toLocaleDateString("en-CA")}
                    </span>
                  </li>

                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    Education :
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? userData?.user?.education
                        : userData?.requestedUser?.education}
                    </span>
                  </li>
                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    Occupation:
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? userData?.user?.occupations
                        : userData?.requestedUser?.occupations}
                    </span>
                  </li>
                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    Height :
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? userData?.user?.height
                        : userData?.requestedUser?.height}
                    </span>
                  </li>
                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    BirthTime :
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? userData?.user?.birthTime
                        : userData?.requestedUser?.birthTime}
                    </span>
                  </li>
                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    Annual Income :
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? userData?.user?.annualIncome
                        : userData?.requestedUser?.annualIncome}
                    </span>
                  </li>
                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    Mother Tounge :
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? userData?.user?.motherTongue
                        : userData?.requestedUser?.motherTongue}
                    </span>
                  </li>
                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    Birth City :
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? userData?.user?.birthCity
                        : userData?.requestedUser?.birthCity}
                    </span>
                  </li>
                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    Spiritual Status :
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? userData?.user?.spiritualStatus
                        : userData?.requestedUser?.spiritualStatus}
                    </span>
                  </li>
                  <li className="flex items-center ">
                    <span>
                      <GoDotFill className="text-black" />
                    </span>
                    Caste :
                    <span className="text-textGreen">
                      {userId === userData?.requestedUser?.userId
                        ? userData?.user?.caste
                        : userData?.requestedUser?.caste}
                    </span>
                  </li>
                </ul>
              </div>
              {activeTab === 1 && (
                <>
                  {userData?.Userdecision === "accepted" &&
                  userData?.matchedUserdecision === "accepted" ? (
                    <Link
                      to={`/suggestion/viewProfile/${userId === userData?.requestedUser?.userId ? userData?.user?.userId : userData?.requestedUser?.userId}`}
                    >
                      <button className="w-32 rounded-lg bg-textYellow px-2 py-1 text-sm text-white md:px-4 md:py-2">
                        View Profile
                      </button>
                    </Link>
                  ) : (
                    <button
                      className="w-32 cursor-not-allowed rounded-lg bg-textYellow px-2 py-1 text-sm text-white opacity-50 md:px-4 md:py-2"
                      disabled
                    >
                      View Profile
                    </button>
                  )}
                </>
              )}
            </div>

            {activeTab === 0 && (
              <div className=" flex h-full w-full flex-row items-center justify-between gap-2 md:w-[18%] md:flex-col md:justify-center lg:px-2 xl:w-[20%] xl:px-4">
                <>
                  <button
                    className="flex items-center rounded-md bg-green-500 px-10 py-2 text-sm text-bgWhite hover:bg-green-400 md:px-4 md:py-2 "
                    onClick={() => acceptMatch()}
                    disabled={acceptLoading}
                  >
                    {acceptLoading ? <Spinner size="small" /> : "Accept"}
                  </button>
                  <button
                    className="flex items-center rounded-md bg-red-600 px-10 py-2 text-sm text-bgWhite hover:bg-red-700 md:px-4 md:py-2"
                    onClick={() => rejectMatch()}
                    disabled={rejectLoading}
                  >
                    {rejectLoading ? <Spinner size="small" /> : "Reject"}
                  </button>
                </>
              </div>
            )}

            {activeTab === 0 && isCommentSectionOpen && (
              <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
                <div className="relative w-[80%] md:w-[50%]  items-center justify-center rounded-lg bg-white p-8 shadow-lg">
                  <div className="flex flex-row gap-1">
                    <h2 className="mb-4 text-lg font-semibold">
                      Add a reason for reject
                    </h2>
                    <p className="text-sm font-semibold text-textRed">*</p>
                  </div>
                  <textarea
                    className="w-full rounded border border-gray-300 p-2 "
                    rows="4"
                    onChange={handleNoteChange}
                    placeholder="Enter your comments..."
                  />
                  {error && (
                    <span className="mt-0 text-xs text-textRed">
                      {comment.length < 15
                        ? "Comment must be at least 15 characters"
                        : "Required"}
                    </span>
                  )}

                  <div className="mt-4 flex justify-end gap-2">
                    <button
                      className="rounded bg-gray-300 px-1 py-0.5 text-black md:px-4 md:py-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                    <button
                      className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                      onClick={handleSaveComment}
                    >
                      Send response
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div>No Data Available</div>
      )}
    </>
  );
};

const Suggestion = () => {
  const [activeTab, setActiveTab] = useState(0);
  // const [userId, setUserId] = useState("");
  const [pendindRequests, setPendingRequests] = useState([]);
  const [acceptedRequest, setAcceptedRequest] = useState([]);
  const [rejectedRequest, setRejectedRequest] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);

  let userId = "";
  useEffect(() => {
    userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
    fetchMatchesDetails(userId);
  }, []);

  const fetchMatchesDetails = async (userId) => {
    // if (!userId) return;

    setInitialLoading(true);
    try {
      const res = await authorizedGet(
        configuration.apis.getMatches + `?userId=${userId}`,
        config,
      );
      setInitialLoading(false);
      const response = res.data?.data;

      if (res?.data?.status) {
        const pendingData = [];
        const acceptedData = [];
        const rejectedData = [];

        response.forEach((userData) => {
          if (userId === userData?.requestedUser?.userId) {
            if (userData?.matchedUserdecision === "pending") {
              pendingData.push(userData);
            }
            if (userData?.matchedUserdecision === "accepted") {
              acceptedData.push(userData);
            }
            if (userData?.matchedUserdecision === "rejected") {
              rejectedData.push(userData);
            }
          } else {
            if (userData?.Userdecision === "pending") {
              pendingData.push(userData);
            }

            if (userData?.Userdecision === "accepted") {
              acceptedData.push(userData);
            }

            if (userData?.Userdecision === "rejected") {
              rejectedData.push(userData);
            }
          }
        });

        setPendingRequests(pendingData);
        setAcceptedRequest(acceptedData);
        setRejectedRequest(rejectedData);
      }
    } catch (err) {
      setInitialLoading(false);
      console.error(err);
      errorNotify("Unexpected Error");
    }
  };

  const tabList = [
    {
      name: "Admin requests",
    },
    {
      name: "Accepted request",
    },
    {
      name: "Denied request",
    },
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className=" flex w-full px-3  py-4 md:mt-16">
      {/* <h3 className="playFair mb-2 text-lg text-textGreen md:text-xl">
      {Strings.PROFILE_INTERST}
    </h3> */}
      <div className="mb-3 mt-10 h-auto w-full rounded-lg bg-bgWhite p-2 shadow-md md:mt-0">
        <div className="font-helvetica mt-4 flex gap-5 text-textGreen">
          <ul className="flex w-full flex-col border-b border-gray-300 md:flex-row">
            {tabList.map((list, index) => (
              <li
                key={index}
                className={`font-jost cursor-pointer px-4 py-2 text-center transition-all sm:text-xs md:text-base ${
                  activeTab === index
                    ? "border-b-2 border-textYellow text-textYellow "
                    : "hover:bg-gray-100 hover:text-textYellow"
                }`}
                onClick={() => handleTabClick(index)}
              >
                {list.name}
              </li>
            ))}
          </ul>
        </div>
        {initialLoading ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="mt-2 flex flex-col">
            {activeTab === 0 &&
              pendindRequests.map((userData, index) => (
                <ProfileContainer
                  key={index}
                  userId={userId}
                  userData={userData}
                  index={index}
                  activeTab={activeTab}
                  setAcceptedRequest={setAcceptedRequest}
                  setPendingRequests={setPendingRequests}
                  setRejectedRequest={setRejectedRequest}
                />
              ))}

            {activeTab === 1 &&
              acceptedRequest.map((userData, index) => (
                <ProfileContainer
                  key={index}
                  userId={userId}
                  userData={userData}
                  index={index}
                  activeTab={activeTab}
                  setAcceptedRequest={setAcceptedRequest}
                  setPendingRequests={setPendingRequests}
                  setRejectedRequest={setRejectedRequest}
                />
              ))}

            {activeTab === 2 &&
              rejectedRequest.map((userData, index) => (
                <ProfileContainer
                  key={index}
                  userId={userId}
                  userData={userData}
                  index={index}
                  activeTab={activeTab}
                  setAcceptedRequest={setAcceptedRequest}
                  setPendingRequests={setPendingRequests}
                  setRejectedRequest={setRejectedRequest}
                />
              ))}
            {activeTab === 0 && pendindRequests.length === 0 && (
              <p className="font-jost text-center">No Suggestions found!</p>
            )}
            {activeTab === 1 && acceptedRequest.length === 0 && (
              <p className="font-jost text-center">
                No Accepted Suggestions found!
              </p>
            )}
            {activeTab === 2 && rejectedRequest.length === 0 && (
              <p className="font-jost text-center">
                No Rejected Suggestions found!
              </p>
            )}
          </div>
        )}
      </div>
      <MessageBar />
    </div>
  );
};

export default Suggestion;
