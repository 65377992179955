import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Strings from "../../utils/Strings";
import MessageBar, {
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
  updateValueInLocalStorage,
} from "../../utils/helper";
import Spinner from "../Spinner/Spinner";
import { post } from "../../Config/network";
import configuration from "../../Config/configuration";
import { authorizedPost } from "../../Config/networkWithToken";

const EmailPopup = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const pathName = location.pathname;
  const getPath = pathName.split("/");

  const handleVerify = async () => {
    setLoading(true);
    try {
      const data = `api/user/${param.user}/verify/${param.token}`;
      const res = await post(data, config);
      console.log("response", res);

      if (res?.data?.status) {
        localStorage.setItem("userData", JSON.stringify(res.data));
        successNotify(res?.data?.message);
        navigate("/login");
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (err) {
      errorNotify(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleVerifyEmail = async () => {
    try {
      setLoading(true);
      const res = await authorizedPost(
        `${configuration.apis.verifyEmail}/${param.user}/verify/${param.token}`,
        config,
      );

      if (res?.data?.status) {
        successNotify(res?.data?.message);

        const userId = getValueFromLocalStorage(
          localStorageKey.USERDATA,
          "_id",
        );

        if (userId) {
          if (res?.data?.data?.userId === userId) {
            updateValueInLocalStorage(
              "userData",
              "emails",
              res?.data?.data?.emailList,
            );
            navigate("/settings");
          } else {
            localStorage.removeItem("userData");
            localStorage.removeItem("count");
            localStorage.removeItem("notificationCount");
            navigate("/login");
          }
        } else {
          navigate("/login");
        }
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {getPath[1] === "addEmail" ? (
        <div className="relative w-[90%] max-w-md rounded-lg bg-white p-8 text-center shadow-lg">
          <h2 className="mb-4 text-2xl font-bold">
            Click to the verified Email
          </h2>
          <button
            className="font-helvetica ml-2 rounded-lg bg-textDarkGray px-4 py-2 text-sm text-white transition-all duration-200 hover:bg-textPink"
            onClick={handleVerifyEmail}
          >
            {loading ? "verifying..." : "Verify"}
          </button>
        </div>
      ) : (
        <div className="relative w-[90%] max-w-md rounded-lg bg-white p-8 text-center shadow-lg">
          <h2 className="mb-4 text-2xl font-bold">{Strings.OTP_HEADER}</h2>
          <p className="mb-4">
            Go back to
            <button
              className="font-helvetica ml-2 rounded-lg bg-textDarkGray px-4 py-2 text-sm text-white transition-all duration-200 hover:bg-textPink"
              onClick={handleVerify}
            >
              {loading ? <Spinner /> : "Login"}
            </button>
          </p>
        </div>
      )}
      <MessageBar />
    </div>
  );
};

export default EmailPopup;
