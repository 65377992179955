import React from "react";
import Strings from "../../utils/Strings";
import { terms } from "./Details";
import Footer from "../Footer/Footer";

const Terms = () => {
  return (
    <div className="bg-gray-50">
    <div className="font-jost mb-4 min-h-screen w-full  ">
      <div className="mx-auto flex w-[90%] flex-col gap-5 p-5 text-justify pt-24">
      <div className="p-5 bg-white shadow-lg rounded-xl">
      <div>
          <h1 className="p-2 text-4xl text-center">
            {Strings.TERMS_HEADER}
          </h1>
          <p className="p-3 text-md ">{Strings.TERMS_TEXT}</p>
        </div>
        {terms.map((terms, index) => {
          return (
            <div className="flex flex-col gap-2 p-2" key={index}>
              <div className="flex gap-2">
                <h1 className="font-semibold underline ">{terms.Title}</h1>
              </div>
              <h2 className=" text-md ">{terms.subTitle}</h2>
              <p className=" text-md ">{terms.Content}</p>
              <p className=" text-md ">{terms.Content1}</p>
              <p className=" text-md ">{terms.connect2}</p>
            </div>
          );
        })}
      </div>
       
      </div>
    </div>
    <Footer/>
    </div>
    
  );
};

export default Terms;
