import React, { useState } from "react";

const ExpectationDetails = ({ userData, Loading }) => {
  if (Loading) {
    return (
      <div className="flex h-screen  items-center justify-center text-center text-lg">
        Loading...
      </div>
    );
  }

  if (!userData) {
    return <div className="text-center text-lg"> No data available</div>;
  }

  const expetedInfo = userData?.profile?.expectations || {};

  return (
    <div className="font-jost mx-auto p-6">
      {/* Tab Content */}
      <div>
        <div className="mb-2 bg-white   px-4">
          <h3 className="mb-2  text-xl text-textYellow">Basic Expectations</h3>
          <div className="flex flex-col md:flex-row md:flex-wrap">
            <div className="w-full md:w-1/2">
              <p className="mb-2">
                <span className="font-medium">Age From:</span>{" "}
                {expetedInfo?.ageFrom || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Age To:</span>{" "}
                {expetedInfo?.ageTo || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Height From:</span>{" "}
                {expetedInfo?.heightFrom || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Height To:</span>{" "}
                {expetedInfo?.heightTo || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">No Of Hare Krishna Mantra:</span>{" "}
                {expetedInfo?.expetedCountOfMantra || "0"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Regulative Principle:</span>{" "}
                {expetedInfo?.expectedRegulativePrinciples?.join(", ") || "NA"}
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <p className="mb-2">
                <span className="font-medium">Caste:</span>{" "}
                {expetedInfo?.expetedCaste?.join(", ") || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Sub Caste:</span>{" "}
                {expetedInfo?.expetedSubcaste?.join(", ") || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Education:</span>{" "}
                {expetedInfo?.expetedEducation?.join(", ") || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Employed In:</span>{" "}
                {expetedInfo?.expetedEmployedIn?.join(", ") || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">City:</span>{" "}
                {expetedInfo?.expectedCity || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Country:</span>{" "}
                {expetedInfo?.expetedCountrysLiving?.join(", ") || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Manglik:</span>{" "}
                {expetedInfo?.expectedManglick?.join(", ") || "NA"}
              </p>
            </div>
          </div>
        </div>

        <div className="mb-2 bg-white   px-4">
          <h3 className="mb-2  text-xl text-textYellow">
            Detailed Expectations
          </h3>
          <div className="flex flex-col md:flex-row md:flex-wrap">
            <div className="w-full md:w-1/2">
              <p className="mb-2">
                <span className="font-medium">Religion:</span>{" "}
                {expetedInfo?.expetedReligion || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Ethnicities:</span>{" "}
                {expetedInfo?.expetedEthnicities || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Marital Status:</span>{" "}
                {expetedInfo?.expetedMaritalStatus || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Mother Tongue:</span>{" "}
                {expetedInfo?.expetedMotherTongue?.join(", ") || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Languages:</span>{" "}
                {expetedInfo?.expetedLanguagesCanSpeak?.join(", ") || "NA"}
              </p>
              {/* <p className="mb-2">
              <span className="font-medium">Languages (Write):</span>{" "}
              {expetedInfo?.expetedLanguagesCanWrite?.join(", ") || "NA"}
            </p> */}
              <p className="mb-2">
                <span className="font-medium">Partner Description:</span>{" "}
                {expetedInfo?.expetedPartnerDescription || "NA"}
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <p className="mb-2">
                <span className="font-medium">Occupations:</span>{" "}
                {expetedInfo?.expetedOccupations?.join(", ") || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Annual Income From:</span>{" "}
                {expetedInfo?.expetedIncomeFrom || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Annual Income To:</span>{" "}
                {expetedInfo?.expetedIncomeTo || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Wants NRI:</span>{" "}
                {Array.isArray(expetedInfo?.expetedWantNRI)
                  ? expetedInfo.expetedWantNRI.join(", ")
                  : expetedInfo?.expetedWantNRI || "NA"}
              </p>

              <p className="mb-2">
                <span className="font-medium">Spiritual Status:</span>{" "}
                {expetedInfo?.expetedSpiritualStatus?.join(", ") || "NA"}
              </p>
              <p className="mb-2">
                <span className="font-medium">Spiritual Master:</span>{" "}
                {expetedInfo?.expetedSpiritualMaster?.join(", ") || "NA"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpectationDetails;
