import React from "react";
import { CiEdit } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
const PostOffice = () => {
  const tableData = [
    {
      List: "Attachments",
      Add: "",
      Edit: "",
    },
    {
      List: "Email Templates",
      Add: "",
      Edit: "",
    },
    {
      List: "Emails",
      Edit: "",
    },
    {
      List: "Logs",
      Add: "",
      Edit: "",
    },
  ];
  return (
    <div className="mx-auto w-[95%] ">
      <table className="w-full table-auto">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">List of Matrimony</th>
            <th className="px-4 py-2">ADD</th>
            <th className="px-4 py-2">CHANGE</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{data.List}</td>
              <td className="border px-4 py-2">{data.Add}</td>
              <td className="border px-4 py-2">{data.Edit}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PostOffice;
