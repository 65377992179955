import React, { useState, useEffect } from "react";
import Questionnaire from "../../UploadQuestionnaire/UploadQuestionnaire";
import { authorizedPost } from "../../../Config/networkWithToken";
import configuration from "../../../Config/configuration";
import {
  config,
  DOBASEURL,
  DOBASEURLDOCUMENT,
  updateValueInLocalStorage,
} from "../../../utils/helper";
import Spinner from "../../Spinner/Spinner";

const UserQuestionnaire = ({
  currentStep,
  setCurrentStep,
  steps,
  userDetails,
  userRole,
  setUserDetails,
}) => {
  const [uploadVisible, setUploadVisible] = useState(false);
  const [questionnaire, setQuestionnaire] = useState({
    questionLink: "",
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [error, setError] = useState("");
  const [isNext, setIsNext] = useState(false);

  useEffect(() => {}, []);

  const handleSubmit = async () => {
    if (questionnaire.questionLink === "") {
      setError("Upload a file to the questionnaire.");
      return;
    } else {
      setError("");
      setIsNext(true);
    }

    setIsButtonLoading(true);

    const data = {
      questionnaire: {
        questionLink: questionnaire.questionLink,
      },
    };
    console.log("dattaaa", data);

    try {
      let res = [];
      if (userRole === "admin") {
        res = await authorizedPost(
          `${configuration.apis.saveProfile}?editUserId=${userDetails?._id}`,
          data,
          config,
        );
      } else {
        res = await authorizedPost(
          configuration.apis.saveProfile,
          data,
          config,
        );
      }
      console.log("responseeee", res);
      if (res.data.status) {
        handleNext("next");
        const updatedProfileData = res?.data?.data;
        const profileCompletionPercentage =
          res?.data?.profileCompletionPercentage;

        if (userRole === "admin") {
          setUserDetails({
            ...userDetails,

            profile: {
              ...userDetails.profile,

              questionnaire: { ...updatedProfileData?.profile?.questionnaire },
            },
          });
        } else {
          if (updatedProfileData) {
            Object.keys(updatedProfileData).forEach((field) => {
              updateValueInLocalStorage(
                "userData",
                field,
                updatedProfileData[field],
              );
            });
          }
          if (profileCompletionPercentage) {
            updateValueInLocalStorage(
              "userData",
              "profileCompletionPercentage",
              profileCompletionPercentage,
            );
          }
        }
      } else {
        console.log("API call failed:", res.data.message);
      }
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const getLink = (url) => {
    setQuestionnaire({ questionLink: url });
  };

  useEffect(() => {
    if (userRole === "admin") {
      if (userDetails) {
        const savedLink = userDetails?.profile?.questionnaire?.questionLink;
        console.log(savedLink);
        if (savedLink) {
          setQuestionnaire({ questionLink: savedLink });
        }
      }
    } else {
      const userData = localStorage.getItem("userData");
      const parsedData = JSON.parse(userData);
      const savedLink = parsedData?.profile?.questionnaire?.questionLink;
      if (savedLink) {
        setQuestionnaire({ questionLink: savedLink });
      }
    }
  }, [userDetails]);
  // useEffect(() => {
  //   const userData = localStorage.getItem("userData");
  //   const parsedData = JSON.parse(userData);
  //   const savedLink = parsedData?.profile?.questionnaire?.questionLink;
  //   if (savedLink) {
  //     setQuestionnaire({ questionLink: savedLink });
  //   }
  // }, []);

  const handleNext = (direction) => {
    if (direction === "next" && currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else if (direction === "prev" && currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-white py-8">
      <div className="container mx-auto">
        <div className="relative h-[98vh] w-[90%] rounded-lg bg-white p-8">
          {/* Conditionally render the embedded file or uploaded file */}
          {questionnaire.questionLink ? (
            <embed
              src={DOBASEURLDOCUMENT + questionnaire.questionLink}
              className="h-[80vh] w-full"
              type="application/pdf"
            />
          ) : (
            <embed
              src={require("../../../Assets/Marriage Questionnaire.pdf")}
              className="h-[80vh] w-full"
              type="application/pdf"
            />
          )}

          <div className="mt-3">
            <a
              href={require("../../../Assets/Marriage Questionnaire.docx")}
              download
              className="cursor-pointer text-blue-500 hover:underline"
            >
              Download Sample Questionnaire
            </a>
            {error && <p className="text-red-400">{error}</p>}
            <div className="mt-2 flex justify-between">
              <button
                onClick={() => setCurrentStep(currentStep - 1)}
                className="w-[100px] rounded bg-textYellow p-2 text-white"
              >
                Previous
              </button>
              <button
                onClick={handleSubmit}
                className={`w-[130px] rounded bg-textYellow p-2 text-white ${isButtonLoading ? "cursor-not-allowed opacity-50" : ""}`}
                disabled={isButtonLoading || isNext}
              >
                {isButtonLoading ? (
                  <div className="flex flex-col items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  "Next"
                )}
              </button>
            </div>
            <div className="mt-4">
              <button
                onClick={() => setUploadVisible(true)}
                className="rounded bg-green-500 px-4 py-2 text-white transition duration-200"
              >
                Upload Document
              </button>
            </div>
          </div>
        </div>
      </div>

      {uploadVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <Questionnaire
            onClose={() => setUploadVisible(false)}
            getLink={getLink}
            setUploadVisible={setUploadVisible}
          />
        </div>
      )}
    </div>
  );
};

export default UserQuestionnaire;
