import React, { useEffect, useState, useRef } from "react";
import SelectField from "../../SelectField/SelectField";
import InputField from "../../InputField/InputField";
import Strings from "../../../utils/Strings";
import Spinner from "../../Spinner/Spinner";
import {
  educationOption,
  employedInOption,
  occupationOption,
  currencyOptions,
  spiritualeducation,
} from "../../../utils/data";
import { authorizedPost } from "../../../Config/networkWithToken";
import {
  config,
  updateValueInLocalStorage,
  getValueFromLocalStorage,
  localStorageKey,
} from "../../../utils/helper";
import configuration from "../../../Config/configuration";

const Profession = ({
  currentStep,
  steps,
  setCurrentStep,
  userDetails,
  userRole,
  setUserDetails,
}) => {
  const prevFormData = useRef(null);

  const [errors, setErrors] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  useEffect(() => {
    if (userRole === "admin") {
      console.log(userDetails);
      if (userDetails) {
        fetchProfession(userDetails);
      }
    } else {
      const userData = JSON.parse(localStorage.getItem("userData"));
      fetchProfession(userData);
    }
  }, [userDetails]);

  const [formData, setFormData] = useState({
    occupations: "",
    employedIn: "",
    organization: "",
    annualIncome: "",
    countryCurrency: "",
    occupationDetail: "",
    education: "",
    college: "",
    educationDetail: "",
    spiritualEducation: [],
  });

  const fetchProfession = (data) => {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    const userData = data;

    if (userData) {
      const fetchedData = {
        occupations: userData?.profile?.profession?.occupations || "",
        employedIn: userData?.profile?.profession?.employedIn || "",
        organization: userData?.profile?.profession?.organization || "",
        annualIncome: userData?.profile?.profession?.annualIncome || "",
        countryCurrency: userData?.profile?.profession?.countryCurrency || "",
        occupationDetail: userData?.profile?.profession?.occupationDetail || "",
        education: userData?.profile?.profession?.education || "",
        college: userData?.profile?.profession?.college || "",
        educationDetail: userData?.profile?.profession?.educationDetail || "",
        spiritualEducation:
          userData?.profile?.profession?.spiritualEducation || "",
      };
      setFormData(fetchedData);
      prevFormData.current = fetchedData;
    }
  };

  const validate = (values) => {
    const errors = {};
    console.log(formData);
    let requiredFields = [];
    if (
      formData?.employedIn === "Not Working" ||
      formData?.occupations?.includes("Not Working")
    ) {
      requiredFields = ["employedIn", "occupations", "education", "college"];
    } else {
      requiredFields = [
        "employedIn",
        "occupations",
        "education",
        "annualIncome",
        "countryCurrency",
        "college",
      ];
    }

    requiredFields.forEach((field) => {
      if (
        !values[field] ||
        (Array.isArray(values[field]) && values[field].length === 0)
      ) {
        console.error(`Validation failed for: ${field}`);
        errors[field] = "Required";
      }
    });
    return errors;
  };
  const handleChange = (e) => {
    const { name, type, value, checked } = e.target ? e.target : e;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate(formData);
    if (Object.keys(newErrors).length === 0) {
      setIsButtonLoading(true);
      if (JSON.stringify(prevFormData.current) === JSON.stringify(formData)) {
        handleNext("next");
        return;
      }
      const data = { profession: { ...formData } };
      console.log(data);

      try {
        let res = [];
        if (userRole === "admin") {
          res = await authorizedPost(
            `${configuration.apis.saveProfile}?editUserId=${userDetails?._id}`,
            data,
            config,
          );
        } else {
          res = await authorizedPost(
            configuration.apis.saveProfile,
            data,
            config,
          );
        }

        if (res.data.status) {
          handleNext("next");

          const updatedProfileData = res?.data?.data;
          const profileCompletionPercentage =
            res?.data?.profileCompletionPercentage;

          if (userRole === "admin") {
            setUserDetails({
              ...userDetails,
              profile: {
                ...userDetails.profile,
                profession: { ...updatedProfileData?.profile?.profession },
              },
            });
          } else {
            if (updatedProfileData) {
              Object.keys(updatedProfileData).forEach((field) => {
                updateValueInLocalStorage(
                  "userData",
                  field,
                  updatedProfileData[field],
                );
              });
              if (profileCompletionPercentage) {
                updateValueInLocalStorage(
                  "userData",
                  "profileCompletionPercentage",
                  profileCompletionPercentage,
                );
              }
            }
          }
        }
      } catch (err) {
        console.log("error", err);
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const handleNext = (direction) => {
    if (direction === "next" && currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else if (direction === "prev" && currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const RequiredLabel = ({ label }) => (
    <span>
      {label}
      <span className="text-red-400">*</span>
    </span>
  );

  return (
    <div className="font-jost flex min-h-screen w-full items-center justify-center">
      <div className="mx-auto mb-14 mt-10 flex w-[95%] flex-col gap-1 rounded-lg bg-bgWhite pb-3 ">
        <form
          onSubmit={handleSubmit}
          className="mt-4 flex w-full flex-col gap-3 px-2 lg:gap-0 lg:p-1"
        >
          <div>
            <div className="mx-auto mt-5 flex w-[95%] flex-col gap-5  p-5">
              <div className="flex flex-col gap-3 ">
                <h3 className="font-bold text-textGreen">
                  {Strings.PROFESSION}{" "}
                </h3>
                <h1 className="pb-3 text-3xl">{Strings.PROFESSION_TEXT} </h1>
                <hr />
              </div>
              <div className="pb-2">
                <div className="flex flex-col gap-2 pb-3">
                  <SelectField
                    selectType="field1"
                    className="w-full"
                    label={<RequiredLabel label="Nature Of Occupation" />}
                    name="employedIn"
                    placeholder="Select Employed In"
                    options={employedInOption}
                    value={formData.employedIn}
                    onChange={handleChange}
                    error={errors.employedIn}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Occupation Details" />}
                    name="occupations"
                    placeholder="Select Occupation"
                    options={occupationOption}
                    value={formData.occupations}
                    onChange={handleChange}
                    isMulti={true}
                    error={errors.occupations}
                  />
                  <InputField
                    className="w-full md:w-[50%]"
                    fieldType="field1"
                    label="Organization"
                    name="organization"
                    type="text"
                    placeholder="Enter Organization"
                    value={formData.organization}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    className="w-full md:w-[50%]"
                    fieldType="field1"
                    label={
                      formData?.employedIn === "Not Working" ||
                      formData?.occupations?.includes("Not Working") ? (
                        "Annual Income "
                      ) : (
                        <RequiredLabel label="Annual Income " />
                      )
                    }
                    name="annualIncome"
                    type="text"
                    placeholder="Enter Annual Income"
                    value={formData.annualIncome}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9\s]*$/.test(value)) { 
                        handleChange(e);
                      }
                    }}
                    error={errors.annualIncome}
                  />
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={
                      formData?.employedIn === "Not Working" ||
                      formData?.occupations?.includes("Not Working") ? (
                        "Currency "
                      ) : (
                        <RequiredLabel label="Currency " />
                      )
                    }
                    name="countryCurrency"
                    placeholder="Select Currency"
                    options={currencyOptions}
                    value={formData.countryCurrency}
                    onChange={handleChange}
                    error={errors.countryCurrency}
                  />
                </div>
                <div className="flex flex-col gap-2 pb-3">
                  <label htmlFor="occupationDetail">
                    Occupation Description (optional) :
                  </label>
                  <textarea
                    placeholder="Enter occupation details"
                    autoComplete="off"
                    name="occupationDetail"
                    className="font-jost rounded-lg border border-gray-200 p-2.5  text-textBlack focus:outline-none"
                    onChange={handleChange}
                    value={formData.occupationDetail}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Education" />}
                    name="education"
                    placeholder="Select Education"
                    options={educationOption}
                    value={formData.education}
                    onChange={handleChange}
                    error={errors.education}
                    isMulti={true}
                  />
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label="Spiritual Education"
                    name="spiritualEducation"
                    placeholder="Select Spiritual Education"
                    options={spiritualeducation}
                    value={formData.spiritualEducation}
                    onChange={handleChange}
                    error={errors.spiritualEducation}
                    isMulti={true}
                  />
                </div>
                <div className="flex flex-col gap-2 pb-3">
                  <InputField
                    className="w-full"
                    fieldType="field1"
                    label={<RequiredLabel label="College/Institution" />}
                    name="college"
                    type="text"
                    placeholder="Enter College/Institution"
                    value={formData.college}
                    onChange={handleChange}
                    error={errors.college}
                  />
                </div>
                <div className="flex flex-col gap-2 pb-3">
                  <label htmlFor="educationDetail">Education in Detail:</label>
                  <textarea
                    placeholder="Enter Education details"
                    autoComplete="off"
                    name="educationDetail"
                    className="font-jost rounded-lg border border-gray-200 p-2.5 text-textBlack focus:outline-none"
                    onChange={handleChange}
                    value={formData.educationDetail}
                  />
                </div>
              </div>
              <hr className="border-2 border-textBlack" />
            </div>
            {errors && (
              <div className="flex w-full items-center justify-center p-2">
                <p className="text-xs text-red-500">
                  {Object.entries(errors)
                    .map(([key, value]) => `${key} is ${value}`)
                    .join(", ")}
                </p>
              </div>
            )}

            <div className="container mb-2 flex justify-around">
              <button
                onClick={() => handleNext("prev")}
                type="button"
                className="cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white "
              >
                Previous
              </button>
              <button
                type="submit"
                className="relative cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white "
              >
                {isButtonLoading ? <Spinner /> : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profession;
