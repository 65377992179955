import React, { useEffect, useState } from "react";
import { authorizedGet } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import { Link } from "react-router-dom";

import {
  config,
  DOBASEURL,
  getValueFromLocalStorage,
  localStorageKey,
} from "../../utils/helper";
import Spinner from "../../Components/Spinner/Spinner";
import Images from "../../utils/images";

const BlockedUsers = () => {
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const fetchBlockedUser = async () => {
    setLoading(true);
    try {
      const res = await authorizedGet(
        `${configuration.apis.getBlockedUsers}?adminId=${adminId}`,
        config,
      );
      const responseStatus = res?.data;
      if (responseStatus?.status) {
        const response = responseStatus?.data;
        // console.log("Response", response);
        setBlockedUsers(response);
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchBlockedUser();
  }, [adminId]);
  //  For seemore and seeless
  // const [expanded, setExpanded] = useState({});

  // const toggleExpand = (id) => {
  //   setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  // };

  return (
    <div>
      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="mt-7 w-full px-4 md:mt-0">
          {/* Title */}
          <div className="mb-5">
            <p className="text-center text-lg font-semibold">Blocked Users</p>
          </div>

          <div className="flex flex-wrap justify-center gap-9">
            {blockedUsers.length > 0 ? (
              blockedUsers?.map((user) => (
                <div
                  key={user.id}
                  className="flex w-full flex-col items-center gap-3 rounded-lg bg-white p-3 shadow-md xxs:w-[80%] sm:w-[65%] sm:flex-row md:h-[220px] md:w-[45%]"
                >
                  {/* Circular Profile Image */}
                  <div className="flex w-full flex-col items-center justify-center  gap-2 md:w-[25%]">
                    <img
                      src={
                        user?.photos?.length > 0
                          ? DOBASEURL + user?.photos?.[0]
                          : user?.gender === "male"
                            ? Images.MEN
                            : Images.WOMEN
                      }
                      alt={user.name}
                      className="h-20 w-20 rounded-full border border-gray-300 object-cover"
                    />
                    <div className="text-center">
                      <p className="text-base font-medium">
                        {" "}
                        {user.name.length > 10
                          ? `${user.name.slice(0, 10)}...`
                          : user.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center gap-2 md:w-[65%]">
                    {/* User Info */}

                    <div className="flex w-full flex-col items-start justify-center px-2 text-left">
                      <p className="whitespace-normal break-words text-sm font-semibold text-gray-800">
                        Email:{" "}
                        <span className="font-normal">{user.primaryEmail}</span>
                      </p>
                      <p className="text-sm font-semibold text-gray-800">
                        Phone No:{" "}
                        <span className="font-normal">{user.phone}</span>
                      </p>
                      <p className="text-sm font-semibold text-gray-800">
                        Blocked Date:{" "}
                        <span className="font-normal">
                          {user.blockedAt
                            ? new Date(user.blockedAt).toLocaleDateString("en-GB")
                            : "N/A"}
                        </span>
                      </p>

                      <p className="text-sm font-semibold text-gray-800">
                        Blocked By:{" "}
                        <span className="font-normal">{user.admin}</span>
                      </p>

                      {/* Truncated Reason with See More */}
                      {/* <p className="text-sm font-semibold text-gray-800">
                  Reason:
                  <span className="font-normal">
                    {expanded[user.id]
                      ? ` ${user.reason}`
                      : ` ${user.reason.slice(0, 60)}... `}
                  </span>
                  {!expanded[user.id] && user.reason.length > 60 && (
                    <button
                      onClick={() => toggleExpand(user.id)}
                      className="text-sm text-blue-500"
                    >
                      See More
                    </button>
                  )}
                  {expanded[user.id] && (
                    <button
                      onClick={() => toggleExpand(user.id)}
                      className="text-sm text-red-500"
                    >
                      See Less
                    </button>
                  )}
                </p> */}
                      <p className="text-sm font-semibold text-gray-800">
                        Reason:
                        <span
                          className="block max-h-[4.5rem] overflow-auto font-normal"
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflowY: "auto",
                          }}
                        >
                          {user.reason}
                        </span>
                      </p>
                    </div>
                    {/* Buttons */}
                    <div className="flex w-full items-center justify-center">
                      <Link
                        to={`/blocked-users/viewProfile/${user._id || user?.userId}`}
                        className="w-full"
                      >
                        <button className="w-full flex-1  rounded bg-textYellow px-4 py-1 text-white  hover:bg-amber-400">
                          View Profile
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="w-full text-center "> No blocked users found.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BlockedUsers;
