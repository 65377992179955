import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="font-jost bg-[#222429] px-5 pt-10 shadow-inner">
      <div className="flex flex-wrap justify-between gap-10 pb-8">
        <div className="lg:w-[400px]">
          <p className="pb-1 text-lg text-[#DE8D38] underline decoration-[#DE8D38] decoration-2 underline-offset-4">
            Vaisnava Marriage
          </p>
          <p className="text-justify text-[15px] leading-relaxed text-[#999999]">
            We believe that marriage is not just a union of two individuals but
            a sacred bond blessed by Lord Krishna. Following the principles of
            Vaishnavism, our platform is dedicated to helping devotees find
            their ideal life partner.
          </p>
        </div>

        <div>
          <p className="pb-1 text-lg text-[#DE8D38] underline decoration-[#DE8D38] decoration-2 underline-offset-4">
            Important Links
          </p>
          <ul className="space-y-1 text-[15px] text-[#999999]">
            <li className=" cursor-pointer hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className=" cursor-pointer hover:text-white">
              <Link to="/aboutus">About Us</Link>
            </li>
            <li className=" cursor-pointer hover:text-white">
              <Link to="/testimonials">Testimonials</Link>
            </li>
            <li className=" cursor-pointer hover:text-white">
              <Link to="/contactus">Contact Us</Link>
            </li>
          </ul>
        </div>
        {/* <div>
        <p className="font-jost pb-1 text-lg text-yellow-900">
          Social Links
        </p>
        <ul className="flex flex-col gap-2 text-[15px] text-yellow-900">
          <li className="flex items-center gap-1">
            <FaFacebook className="h-[13px]" />
            facebook
          </li>
          <li className="flex items-center gap-1">
            <AiFillInstagram className="h-[15px]" />
            instagram
          </li>
          <li className="flex items-center gap-1">
            <FaLinkedin className="h-[15px] " />
            linkedin
          </li>
          <li className="flex items-center gap-1">
            <AiFillTwitterCircle className="h-[15px] " />
            twitter
          </li>
        </ul>
      </div> */}
        <div>
          <p className="pb-1 text-lg text-[#DE8D38] underline decoration-[#DE8D38] decoration-2 underline-offset-4">
            Contact Details
          </p>
          <p className="text-[15px] leading-relaxed text-[#999999]">
            +91 9647089832
            <br />
            +91 7477782975
            <br />
            +91 8653000991
          </p>
          <p className="mt-1 text-[15px] text-[#999999]">
            vaisnavamarriage@gmail.com
          </p>
        </div>
      </div>

      <hr className="border-[#444444]" />

      <div className="flex items-center justify-center py-4">
        <p className="text-md text-[#999999]">
          &copy; Vaisnava Marriage - {new Date().getFullYear()} powered by{" "}
          <a
            href="https://www.kraysinfotech.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#999999] hover:underline hover:text-blue-400"
          >
            KraysInfotech
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
