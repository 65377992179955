import React, { useState, useEffect } from "react";
import Images from "../../utils/images";
import { authorizedGet, authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import MessageBar, {
  config,
  DOBASEURLDOCUMENT,
  errorNotify,
  successNotify,
} from "../../utils/helper";
import Spinner from "../Spinner/Spinner";
import { TbShieldLock } from "react-icons/tb";
import { VscGistSecret } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";

const ViewQuestionnaire = ({
  userId,
  requestedUserId,
  questionnaireData,
  userData,
}) => {
  const [isAcceptedLoading, setIsAcceptedLoading] = useState(false);
  const [isRejectedLoading, setIsRejectedLoading] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const [horoscopeChecked, setHoroscopeChecked] = useState(false);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const localStorageUserData = JSON.parse(localStorage.getItem("userData"));
  const userRole = localStorageUserData?.role;

  useEffect(() => {
    if (
      questionnaireData?.[0]?.adminApproval === "pending" ||
      questionnaireData?.[0]?.adminApproval === "approved"
    ) {
      setHoroscopeChecked(true);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setHoroscopeChecked(isChecked);
  };

  const handleSentRequest = async () => {
    if (!horoscopeChecked) {
      errorNotify("Please check the horoscope before sending the request.");
      return;
    }
    setRequestLoading(true);
    try {
      const data = {
        userId: userId,
        requestedUserId: requestedUserId,
        horoscope: true,
      };
      console.log(data);

      const res = await authorizedPost(
        configuration.apis.sendApproval,
        data,
        config,
      );
      const response = res?.data;
      if (response?.status) {
        successNotify(response?.message);
        setButtonStatus("Pending.....!");
      } else {
        errorNotify(response?.message);
      }
    } catch (error) {
      console.log(error);
      errorNotify("Unexpected Error");
    } finally {
      setRequestLoading(false);
    }
  };

  const handleAction = async (decision) => {
    if (decision === "approve") {
      setIsAcceptedLoading(true);
    } else {
      setIsRejectedLoading(true);
    }

    try {
      const data = {
        reqId: questionnaireData?.[0]?._id,
        action: decision,
        comments: comment,
      };
      const res = await authorizedPost(
        configuration.apis.overAllApproval,
        data,
        config,
      );

      if (res?.data?.status) {
        successNotify(res?.data?.message);
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (error) {
      errorNotify("Error processing request.");
    } finally {
      setIsAcceptedLoading(false);
      setIsRejectedLoading(false);
    }
  };

  const handleDeny = () => {
    setIsCommentSectionOpen(true);
  };

  const handleNoteChange = (event) => {
    setError(false);
    setComment(event.target.value);
  };

  const closeModal = () => {
    setComment("");
    setError(false);
    setIsCommentSectionOpen(false);
  };

  const handleSaveComment = () => {
    if (comment.length < 15) {
      setError(true);
    } else {
      handleAction("reject");
      setIsCommentSectionOpen(false);
      setError(false);
      setComment("");
    }
  };

  const location = useLocation();
  const pathName = location.pathname;
  const getPath = pathName.split("/");

  console.log(userData);
  const questionaireLink = userData?.profile?.questionnaire?.questionLink;
  console.log(questionaireLink);

  return (
    <div className="flex flex-wrap justify-center gap-4 p-4 md:justify-start">
      {userRole !== "admin" && getPath[1] !== "accepted-users" && (
        <label className="mb-2 flex items-center">
          <input
            type="checkbox"
            className="mr-2"
            checked={horoscopeChecked}
            onChange={handleCheckboxChange}
          />
          I have successfully completed my horoscope, thoroughly analyzing my
          birth chart and astrological details.
        </label>
      )}

      <div
        className={`relative flex w-full flex-col items-center justify-center gap-2`}
      >
        {questionnaireData?.[0]?.questionnaire || questionaireLink ? (
          <embed
            src={
              DOBASEURLDOCUMENT +
                (questionnaireData?.[0]?.questionnaire || questionaireLink) ||
              require("../../Assets/sampleQuestions.pdf#toolbar=0")
            }
            className={`h-[80vh] w-full ${
              questionnaireData?.[0]?.adminApproval === "approved" ||
              userRole === "admin"
                ? questionnaireData?.[0]?.otherUsersApproval?.status ===
                    "rejected" ||
                  questionnaireData?.[0]?.mutualApproval === "rejected"
                  ? "blur-md"
                  : ""
                : "blur-md"
            }`}
            type="application/pdf"
          />
        ) : (
          <p className="text-center text-lg text-gray-600">
            Questionnaire not available.
          </p>
        )}

        {/* Condition for non-admin*/}
        {questionnaireData?.[0]?.adminApproval !== "approved" &&
          userRole !== "admin" && (
            <div className="absolute z-10 flex h-[80vh] w-full flex-col items-center justify-center">
              {questionnaireData?.[0]?.adminApproval === "pending" ||
              questionnaireData?.[0]?.adminApproval === "rejected" ||
              buttonStatus ? (
                <button className="z-40 rounded-md bg-textYellow px-10 py-2 text-white">
                  {buttonStatus === null ? (
                    <p>{questionnaireData?.[0]?.adminApproval}.....!</p>
                  ) : (
                    <p>{buttonStatus}</p>
                  )}
                </button>
              ) : (
                <>
                  {userRole !== "admin" && (
                    <>
                      <button
                        onClick={handleSentRequest}
                        className={`z-40 rounded-md bg-textYellow px-10 py-2 text-white ${!horoscopeChecked ? "cursor-not-allowed opacity-50" : ""}`}
                        disabled={!horoscopeChecked}
                      >
                        {requestLoading ? <Spinner /> : "Questionnaire Request"}
                      </button>
                      <p className="text-xs text-red-600">
                        Note: Raise Request to see the Questionnaire
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        {questionnaireData?.[0]?.adminApproval === "approved" &&
          getPath[1] !== "accepted-users" && (
            <>
              {questionnaireData?.[0]?.otherUsersApproval?.status ===
                "rejected" ||
              questionnaireData?.[0]?.mutualApproval === "rejected" ? (
                <div>
                  <button className="flex  items-center justify-center rounded-sm bg-red-600 p-2 text-white">
                    Your Request Was Rejected
                  </button>
                </div>
              ) : (
                <div className="flex w-full items-center justify-around">
                  <button
                    onClick={() => handleAction("approve")}
                    className="flex w-[100px] items-center justify-center  rounded-sm bg-green-600 p-2 text-white"
                  >
                    {isAcceptedLoading ? <Spinner /> : "Accept"}
                  </button>
                  <button
                    onClick={() => handleDeny()}
                    className="flex w-[100px] items-center justify-center rounded-sm bg-red-600 p-2 text-white"
                  >
                    {isRejectedLoading ? <Spinner /> : "Deny"}
                  </button>
                </div>
              )}
            </>
          )}
      </div>

      {isCommentSectionOpen && (
        <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-[80%] md:w-[50%]  items-center justify-center rounded-lg bg-white p-8 shadow-lg">
            <div className="flex flex-row gap-1">
              <h2 className="mb-4 text-lg font-semibold">
                Add a reason for reject
              </h2>
              <p className="text-sm font-semibold text-textRed">*</p>
            </div>
            <textarea
              className="w-full rounded border border-gray-300 p-2 "
              rows="4"
              onChange={handleNoteChange}
              placeholder="Enter your comments..."
            />
            {error && (
              <span className="mt-0 text-xs text-textRed">
                {comment.length < 15
                  ? "Comment must be at least 15 characters"
                  : "Required"}
              </span>
            )}
            <div className="mt-4 flex justify-end gap-2">
              <button
                className="rounded bg-gray-300 px-1 py-0.5 text-black md:px-4 md:py-2"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                onClick={handleSaveComment}
              >
                Send response
              </button>
            </div>
          </div>
        </div>
      )}
      <MessageBar />
    </div>
  );
};

export default ViewQuestionnaire;
