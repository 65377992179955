import React, { useState, useEffect } from "react";
import { FaPhone } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import Loader from "../../Components/Loader/Loader";
import aboutImg from "../../Assets/about.jfif";
import Strings from "../../utils/Strings";
import Footer from "../../Components/Footer/Footer";
import "./About.css";

const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    window.scrollTo(0, 0);
    return () => clearTimeout(timer);
  }, []);

  return  (
    
    <div className="flex flex-col min-h-screen bg-white">
      {/* Main Content */}
      <div className="about_container px-4 py-32">
      <div className="text-center">
  {/* <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-primary py-4">{Strings.ABOUT}</h1>  */}
  <p className="w-full sm:w-[80%] md:w-[60%] lg:w-[50%] mx-auto text-base sm:text-lg md:text-xl text-white font-jost font-bold py-16 text-shadow">
    Vivāha-yajña, the marriage ceremony, is meant to regulate the human mind so that it may become peaceful for spiritual advancement. For most men, this vivāha-yajña should be encouraged even by persons in the renounced order of life.
  </p>
</div>

</div>
        <section className="mx-auto w-[95%] py-8">
          <div className="font-jost py-7 text-3xl">
            <p className="text-center text-2xl text-primary">About Us</p>
            <div className="mx-auto w-16 border-b-2 border-yellow-400 text-center mt-2" />
            <p className="pt-4 text-center text-2xl text-secondary">Connecting Hearts, Guided by Devotion</p>
            <div className="mx-auto w-[180px] border-b-2 border-yellow-400 pt-2 text-center" />
          </div>

          <div className="content flex flex-col font-jost gap-6 lg:flex-row">
            <div className="content-section lg:w-1/3">
              <p className="text-justify text-gray-700 mb-4 pt-8">
                At <b>Vaishnava Marriage Bureau</b>, We believe that marriage is not just a union of two individuals but the forging of a sacred bond in Krishna Consciousness. 
              </p>
              <p className="text-justify text-gray-700">
                <b>Our Mission:</b> Our platform is dedicated to helping you find your dream life partner—someone who inspires, strengthens, and shares your values in Krishna Consciousness for a meaningful, fulfilling, and active life together.
          We create a safe and supportive space for you to connect, converse, and ultimately find your life partner. We understand the significance of marrying a devotee and we are committed to facilitating unions that honor the teachings of Srila Prabhupada.
              </p>
            </div>

            <div className="image-section lg:w-1/3 flex justify-center items-center">
              <img
                src={aboutImg}
                alt="about"
                className="h-[350px] rounded-lg shadow-lg"
              />
            </div>
            <div className="content-section lg:w-1/3">
              <p className="text-justify text-gray-700 mb-4 pt-8">
                <b>Our Values:</b> We uphold the highest standards of honesty, transparency, and respect in all interactions.We strive to build a strong, supportive community in Krishna Consciousness. 
              </p>
              <p className="text-justify text-gray-700">
                <b>Our Story:</b> Vaishnava Marriage was created by a group of dedicated couples, inspired and guided by HH Jayapataka, who recognized the need for a specialized platform that caters to the unique needs of devotees.
With a deep understanding of the importance of shared values in marriage, they set out to create a service that goes beyond simple matchmaking, facilitating unions that honor the teachings of Srila Prabhupada.

          </p>
            </div>
          </div>
        </section>
      <Footer />
    </div>
  );
};

export default About;
