import React, { useState } from "react";

const ReligionDetails = ({ userData, Loading }) => {
  if (Loading) {
    return (
      <div className="flex h-screen  items-center justify-center text-center text-lg">
        Loading...
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="flex h-screen items-center justify-center text-center text-lg">
        No data available
      </div>
    );
  }

  const religionInfo = userData?.profile?.religionAndFamily || {};
  const familyInfo = userData?.profile?.family || {};

  return (
    <div className="font-jost  mx-auto p-6">
      {/* Religion Information */}
      <div className="mb-2 bg-white   px-4">
        <h3 className="mb-2  text-xl text-textYellow">
          Religion and Birth Information
        </h3>
        <div className="flex  flex-col md:flex-row md:flex-wrap">
          <div className=" w-full md:w-1/2">
            <p className="mb-2">
              <span className="font-medium">Religion:</span>{" "}
              {religionInfo.religion || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Religious Background:</span>{" "}
              {religionInfo.religiousBackground || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Caste:</span>{" "}
              {religionInfo.caste || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Sub Caste:</span>{" "}
              {religionInfo.subcaste || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Other Caste:</span>{" "}
              {religionInfo.otherCaste || "NA"}
            </p>
          </div>

          <div className="mb-2 w-full md:w-1/2">
            <p className="mb-2">
              <span className="font-medium">DOB:</span>
              {new Date(userData?.dob).toLocaleDateString("en-CA") || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Birth Time:</span>{" "}
              {religionInfo.birthTime || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Birth Place:</span>{" "}
              {religionInfo.birthPlace || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Birth City:</span>{" "}
              {religionInfo.birthCity || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Birth State:</span>{" "}
              {religionInfo.birthState || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Birth Country:</span>{" "}
              {religionInfo.birthCountry || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Manglik:</span>{" "}
              {religionInfo.manglik || "NA"}
            </p>
          </div>
        </div>
      </div>

      {/* Family Information */}
      <div className="mb-2 bg-white px-4">
        <h3 className="mb-4   text-xl text-textYellow">Family Information</h3>
        <div className="flex  flex-col md:flex-row md:flex-wrap">
          <div className="w-full md:w-1/2">
            <p className="mb-2 w-full md:w-1/2">
              <span className="font-medium">Family Origin:</span>{" "}
              {familyInfo.familyOrigin || "NA"}
            </p>

            <p className="mb-2 ">
              <span className="font-medium">Family Type:</span>{" "}
              {familyInfo.familyType || "NA"}
            </p>

            <p className="mb-2 ">
              <span className="font-medium">Family Status:</span>{" "}
              {familyInfo.familyStatus || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Family Values:</span>{" "}
              {familyInfo.familyValues || "NA"}
            </p>

            <p className="mb-2 ">
              <span className="font-medium">Father's Phone Number:</span>{" "}
              {familyInfo.fatherPhoneNumber || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Mother's Phone Number:</span>{" "}
              {familyInfo.motherPhoneNumber || "NA"}
            </p>

            <p className="mb-2 w-full md:w-1/2">
              <span className="font-medium">Family Location:</span>{" "}
              {familyInfo.familyLocation || "NA"}
            </p>

            <p className="mb-2 ">
              <span className="font-medium">Family Details:</span>{" "}
              {familyInfo.familyDetails || "NA"}
            </p>

            

          </div>
          <div className="w-full md:w-1/2">
            <p className="mb-2 ">
              <span className="font-medium">Father's Name:</span>{" "}
              {familyInfo.fatherName || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Father's Status:</span>{" "}
              {familyInfo.fatherStatus || "NA"}
            </p>

            <p className="mb-2 ">
              <span className="font-medium">Mother's Name:</span>{" "}
              {familyInfo.motherName || "NA"}
            </p>

            <p className="mb-2 ">
              <span className="font-medium">Mother's Status:</span>{" "}
              {familyInfo.motherStatus || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">No. of Brothers:</span>{" "}
              {familyInfo.noOfBrothers || "NA"}
            </p>

            <p className="mb-2 ">
              <span className="font-medium">Married Brothers:</span>{" "}
              {familyInfo.marriedBrothers || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">No. of Sisters:</span>{" "}
              {familyInfo.noOfSisters || "NA"}
            </p>

            <p className="mb-2">
              <span className="font-medium">Married Sisters:</span>{" "}
              {familyInfo.marriedSisters || "NA"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReligionDetails;
