import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import { HiEye, HiEyeOff } from "react-icons/hi";
import { IoMdArrowBack } from "react-icons/io";

import "./Register.css";
import Images from "../../utils/images";
import Strings from "../../utils/Strings";
import MessageBar, {
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import Spinner from "../../Components/Spinner/Spinner";
import { genderOption, maritalOption, profileOption } from "../../utils/data";
import configuration from "../../Config/configuration";
import { post } from "../../Config/network";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "*Required";
  }
  if (!values.primaryEmail) {
    errors.primaryEmail = "*Required";
  } else if (
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(values.primaryEmail)
  ) {
    errors.primaryEmail = "*Invalid Email";
  }

  if (!values.phone) {
    errors.phone = "*Required";
  }
  if (!values.dob) {
    errors.dob = "*Required";
  } else {
    // Custom validation to check age greater than 21
    const birthDate = new Date(values.dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    if (age < 21) {
      errors.dob = "You must be at least 21 years old";
    }
  }
  if (!values.password) {
    errors.password = "*Required";
  } else if (values.password.length > 20) {
    errors.password = "*Password must be less than 20 characters";
  } else if (values.password.length < 6) {
    errors.password = "*Password must be a minimum of 6 characters";
  } else if (
    !/[A-Za-z]/.test(values.password) ||
    !/\d/.test(values.password) ||
    !/[!@#$%^&*(),.?":{}|<>]/.test(values.password)
  ) {
    errors.password = "*Password needs a letter, number, and symbol.";
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "*Required";
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "*Password must match";
  }
  if (!values.countOfMantra) {
    errors.countOfMantra = "*Required";
  }
  if (!values.gender) {
    errors.gender = "*Required";
  }
  if (!values.maritalStatus) {
    errors.maritalStatus = "*Required";
  }
  if (!values.createdBy) {
    errors.createdBy = "*Required";
  }
  if (!values.contactPersonName) {
    errors.contactPersonName = "*Required";
  }
  if (!values.terms) {
    errors.terms = "*Required";
  }
  return errors;
};
const Register = () => {
  const [isButtonLoad, setIsButtonLoad] = useState(false);

  // handleShowPassword is used for hide and show the password
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const userRole = getValueFromLocalStorage(localStorageKey.USERDATA, "role");

  const navigate = useNavigate();
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 21,
    today.getMonth(),
    today.getDate(),
  )
    .toISOString()
    .split("T")[0];
  // for email verification
  const formik = useFormik({
    initialValues: {
      name: "",
      primaryEmail: "",
      phone: "",
      dob: "",
      password: "",
      confirmPassword: "",
      countOfMantra: "",
      gender: "",
      maritalStatus: "",
      createdBy: "",
      contactPersonName: "",
      terms: "",
    },
    validate,
    onSubmit: (values) => {
      handleSubmit();
      console.log(formik.values);
    },
  });
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async () => {
    const birthDate = new Date(formik.values.dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    const data = {
      name: formik.values.name,
      primaryEmail: formik.values.primaryEmail,
      phone: formik.values.phone,
      dob: formik.values.dob,
      age: age,
      password: formik.values.password,
      confirmPassword: formik.values.confirmPassword,
      countOfMantra: formik.values.countOfMantra,
      gender: formik.values.gender,
      maritalStatus: formik.values.maritalStatus,
      createdBy: formik.values.createdBy,
      contactPersonName: formik.values.contactPersonName,
      terms: formik.values.terms,
    };
    try {
      setIsButtonLoad(true);
      const res = await post(configuration.apis.register, data, config);
      console.log("response", res);
      if (res?.data?.status) {
        localStorage.setItem("userData", JSON.stringify(res.data));
        successNotify(res?.data?.message);
        console.log(res?.data);
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (err) {
      const errorMessage = "Unexpected error please try again";
      errorNotify(errorMessage);
    }
    setIsButtonLoad(false);
  };

  // function to go back to the last URL
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="font-jost register-right-container flex min-h-screen pt-5">
      {userRole === "admin" && (
        <div className="fixed left-4 top-4 z-50">
          <div
            onClick={handleGoBack}
            className="font-jost flex cursor-pointer items-center gap-2 overflow-hidden rounded-full bg-textYellowLight px-4 py-2 shadow-md transition-all hover:bg-yellow-300 active:scale-95 sm:w-[130px] md:w-[180px] sm:b"
          >
            {/* Icon */}
            <IoMdArrowBack className="h-6 w-6 text-gray-800" />

            {/* Text */}
            <p className="whitespace-nowrap text-center text-sm text-gray-900 md:text-base">
              Go Back
            </p>
          </div>
        </div>
         )}
        <div className="flex w-full items-center justify-center">
          <div className="mx-auto mb-14 flex w-[95%] flex-col items-center justify-center shadow  lg:h-[650px] lg:w-[70%] lg:flex-row">
            <div className="register-left-container relative h-[250px] w-full rounded-tl-lg rounded-tr-lg bg-loginbg lg:h-[650px] lg:w-[35%] lg:rounded-bl-lg lg:rounded-tl-lg lg:p-6">
              <div className="playFair flex flex-col space-y-2 p-4 lg:space-y-4 ">
                <h3 className="text-center text-2xl  text-textGreen lg:text-left xl:text-3xl ">
                  {Strings.NOW}
                </h3>
                <h2 className="text-center text-5xl  text-textGreen lg:text-left xl:text-6xl">
                  {Strings.LOGIN_TEXT}
                </h2>
                <h3 className="text-center text-2xl  text-textGreen lg:text-left xl:text-3xl">
                  {Strings.EASY_FAST}
                </h3>
              </div>
              <div className="hidden items-center justify-center lg:flex">
                <img
                  src={Images.MARRIAGEHEART}
                  alt=""
                  className="h-[230px] w-[230px] object-contain "
                />
              </div>
              <div className="imgBg absolute bottom-0 left-0 right-0"></div>
            </div>
            <div className="flex h-auto w-full flex-col rounded-bl-lg rounded-br-lg bg-bgWhite lg:h-[650px] lg:w-[65%] lg:rounded-br-lg lg:rounded-tr-lg lg:px-12">
              <div className="mt-6 flex flex-col gap-1 space-y-2 p-2 lg:mt-2 lg:p-0">
                <h3 className="playFair text-left text-2xl  text-textBlack">
                  {Strings.LOGIN_CONTEN}
                </h3>
                <p className=" pb-3 text-left text-xs font-thin tracking-wider text-textGreen">
                  {Strings.NOT_MEMBER}
                  <span className=" text-xs font-medium text-sky-500">
                    <Link to="/login"> {Strings.LOGIN}</Link>
                  </span>
                </p>
                <hr />
              </div>

              <form
                onSubmit={formik.handleSubmit}
                className="mt-4 flex w-full flex-col gap-3 px-2 lg:gap-0 lg:p-1"
              >
                <div className="flex flex-col gap-1 lg:h-16">
                  <input
                    type="text"
                    placeholder="Enter Legal Name"
                    name="name"
                    autoComplete="off"
                    className="rounded-lg border border-gray-200  p-2.5  text-xs text-textBlack focus:outline-none"
                    onChange={(e) => {
                      const regex = /^[a-zA-Z\s]*$/;
                      if (regex.test(e.target.value)) {
                        formik.setFieldValue("name", e.target.value);
                      }
                    }}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <span className="text-xs text-textRed">
                      {formik.errors.name}
                    </span>
                  ) : null}
                </div>

                <div className="flex flex-col gap-1 lg:h-16">
                  <input
                    type="email"
                    autoComplete="off"
                    placeholder="Enter Your Email"
                    name="primaryEmail"
                    className="rounded-lg border border-gray-200  p-2.5  text-xs text-textBlack focus:outline-none"
                    onChange={formik.handleChange}
                    value={formik.values.primaryEmail}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.primaryEmail && formik.errors.primaryEmail ? (
                    <span className="text-xs text-textRed">
                      {formik.errors.primaryEmail}
                    </span>
                  ) : null}
                </div>

                <div className="flex flex-col gap-3 md:flex-row md:gap-3 lg:h-16">
                  <div className="flex flex-col gap-1">
                    <PhoneInput
                      country={"in"}
                      value={formik.values.phone}
                      onChange={(phone) => formik.setFieldValue("phone", phone)}
                      onBlur={formik.handleBlur}
                      inputStyle={{
                        borderRadius: "4px",
                        fontSize: "14px",
                      }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <span className="text-xs text-textRed">
                        {formik.errors.phone}
                      </span>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-1 md:w-[50%]">
                    <input
                      placeholder="Date Of Birth"
                      className="rounded-lg border border-gray-200  p-2  text-xs text-textBlack focus:outline-none"
                      type="Date"
                      name="dob"
                      onChange={formik.handleChange}
                      value={formik.values.dob}
                      onBlur={formik.handleBlur}
                      max={maxDate}
                    />
                    {formik.touched.dob && formik.errors.dob ? (
                      <span className="text-xs text-textRed">
                        {formik.errors.dob}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="flex flex-col gap-3 md:flex-row md:gap-3 lg:h-16">
                  <div className="flex flex-col gap-1 md:w-[50%]">
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        autoComplete="off"
                        placeholder="Create VMB Password"
                        name="password"
                        className="w-full rounded-lg border border-gray-200 p-2.5 pr-10 text-xs text-textBlack focus:outline-none"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                      />
                      <button
                        type="button"
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-textBlack"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <HiEyeOff /> : <HiEye />}
                      </button>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <span className="text-xs text-textRed">
                        {formik.errors.password}
                      </span>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-1 md:w-[50%]">
                    <div className="relative ">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        autoComplete="off"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        className="w-full rounded-lg border border-gray-200  p-2.5  text-xs text-textBlack focus:outline-none"
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        onBlur={formik.handleBlur}
                      />
                      <div
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-textBlack"
                        onClick={handleShowConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <HiEyeOff className="text-textBlack" />
                        ) : (
                          <HiEye className="text-textBlack" />
                        )}
                      </div>
                    </div>
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <span className="text-xs text-textRed">
                        {formik.errors.confirmPassword}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col gap-1 lg:h-16">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Number of rounds of Hare Krishna Maha Mantra chanting"
                    name="countOfMantra"
                    className="rounded-lg border border-gray-200  p-2.5  text-xs text-textBlack focus:outline-none"
                    onChange={(e) => {
                      const regex = /^[0-9]*$/;
                      if (regex.test(e.target.value)) {
                        formik.setFieldValue("countOfMantra", e.target.value);
                      }
                    }}
                    value={formik.values.countOfMantra}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.countOfMantra &&
                  formik.errors.countOfMantra ? (
                    <span className="text-xs text-textRed">
                      {formik.errors.countOfMantra}
                    </span>
                  ) : null}
                </div>
                {/* <div className="flex flex-col gap-1 lg:h-16">
  <select
    name="countOfMantra"
    className="rounded-lg border border-gray-200 p-2.5 font-sans text-xs text-textBlack focus:outline-none"
    value={formik.values.countOfMantra}
    onBlur={formik.handleBlur}
    onChange={formik.handleChange}
  >
    <option value="" disabled>Number of rounds of Hare Krishna Maha Mantra chanting</option>
    {countOfMantraOptions.map((option) => (
      <option key={option.value} value={option.label}>
        {option.label}
      </option>
    ))}
  </select>
  {formik.touched.countOfMantra && formik.errors.countOfMantra ? (
    <span className="text-xs text-textRed">
      {formik.errors.countOfMantra}
    </span>
  ) : null}
</div> */}

                <div className="flex flex-col gap-3 md:flex-row md:gap-3 lg:h-16">
                  <div className="flex flex-col gap-1 md:w-[50%]">
                    <select
                      placeholder="Select Your Gender"
                      className="rounded-lg border border-gray-200  p-2.5  text-xs text-textBlack focus:outline-none"
                      list="genderOption"
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" disabled>
                        Select Your Gender
                      </option>
                      {genderOption.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    {formik.touched.gender && formik.errors.gender ? (
                      <span className="text-xs text-textRed">
                        {formik.errors.gender}
                      </span>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-1 md:w-[50%]">
                    <select
                      placeholder="Select Your Marital Status"
                      className="rounded-lg border border-gray-200  p-2.5  text-xs text-textBlack focus:outline-none"
                      list="maritalOptions"
                      name="maritalStatus"
                      value={formik.values.maritalStatus}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" disabled>
                        Select Your Marital Status
                      </option>
                      {maritalOption.map((option) => (
                        <option key={option.value} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    {formik.touched.maritalStatus &&
                    formik.errors.maritalStatus ? (
                      <span className="text-xs text-textRed">
                        {formik.errors.maritalStatus}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="flex flex-col gap-3 md:flex-row md:gap-3 lg:h-16">
                  <div className="flex flex-col gap-1 md:w-[50%]">
                    <select
                      className="rounded-lg border border-gray-200  p-2.5  text-xs text-textBlack focus:outline-none"
                      name="createdBy"
                      placeholder="Select Profile Created By"
                      value={formik.values.createdBy}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" disabled>
                        Select Profile Created By
                      </option>
                      {profileOption.map((option) => (
                        <option key={option.value} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </select>

                    {formik.touched.createdBy && formik.errors.createdBy ? (
                      <span className="text-xs text-textRed">
                        {formik.errors.createdBy}
                      </span>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-1 md:w-[50%]">
                    <input
                      type="name"
                      placeholder="Contact Person Name"
                      name="contactPersonName"
                      autoComplete="off"
                      className="rounded-lg border border-gray-200  p-2.5  text-xs text-textBlack focus:outline-none"
                      onChange={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;
                        if (regex.test(e.target.value)) {
                          formik.setFieldValue(
                            "contactPersonName",
                            e.target.value,
                          );
                        }
                      }}
                      value={formik.values.contactPersonName}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.contactPersonName &&
                    formik.errors.contactPersonName ? (
                      <span className="text-xs text-textRed">
                        {formik.errors.contactPersonName}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-row gap-2 p-2 lg:h-16">
                  <input
                    className="h-4 w-4 border border-gray-200 "
                    type="checkbox"
                    checked={formik.values.terms}
                    onChange={() =>
                      formik.setFieldValue("terms", !formik.values.terms)
                    }
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="name" className=" text-xs text-textBlack">
                    <span>I Accept </span>
                    <Link
                      className=" text-xs font-medium text-sky-500"
                      to="/terms"
                    >
                      Terms And Conditions
                    </Link>
                    <span> & </span>
                    <Link
                      className=" text-xs font-medium text-sky-500"
                      to="/privacy"
                    >
                      Privacy Policy
                    </Link>
                  </label>
                  {formik.touched.terms && formik.errors.terms ? (
                    <span className="text-xs text-textRed">
                      {formik.errors.terms}
                    </span>
                  ) : null}
                </div>
                <button
                  // onClick={handleSubmit()}
                  type="submit"
                  className=" mb-3 flex w-full cursor-pointer items-center justify-center rounded-lg bg-textPink p-2 text-sm text-bgWhite  lg:-mt-7"
                  disabled={isButtonLoad}
                >
                  {isButtonLoad ? (
                    <div className="flex items-center justify-center">
                      <Spinner />
                      <span className="ml-2">Processing...</span>
                    </div>
                  ) : (
                    "Create Account"
                  )}
                </button>
              </form>
            </div>
          </div>
          <MessageBar />
        </div>
      </div>
    </>
  );
};

export default Register;
